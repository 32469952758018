/** @module components.Agencies */

/** @module components */

import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import classnames from 'classnames';

import * as style from '@bryxinc/style/main.module.css';
import * as headerStyle from '@bryxinc/style/header.module.css';
import * as color from '@bryxinc/style/color';

import Card from '../Card';
import Page from '../Page';
import { withContext, WithTranslation, WithLocal } from '@bryxinc/lunch/context';

interface DashboardProps extends RouteComponentProps, WithTranslation, WithLocal { }

/**
 * Agencies dashboard react component.
 * To be displayed on agency landing page.
 */
export class Dashboard extends React.Component<DashboardProps, any> {

  /**
   * Handle manage click.
   */
  private onClickManage(e: React.SyntheticEvent): void {
    e.preventDefault();
    const { history } = this.props;
    history.push('/agencies/view');
  }

  /**
   * Handle join click.
   */
  private onClickJoin(e: React.SyntheticEvent): void {
    e.preventDefault();
    const { history } = this.props;
    history.push('/agencies/join');
  }

  /**
   * Handle sign up click.
   */
  private onClickSignUp(e: React.SyntheticEvent): void {
    e.preventDefault();
    const { history } = this.props;
    history.push('/agencies/join?signUp=true');
  }

  // ICONS
  // cog = manage
  // plus = join
  // edit = signup
  // building
  // building outline
  // <Icon.Group size='huge'>
  //     <Icon name='puzzle' />
  //     <Icon corner='bottom right' name='add' />
  //   </Icon.Group>

  /**
   * Manage agencies card.
   */
  private get manageCard(): React.ReactNode {
    return (
      <Card
        headerKey='agencies.dashboard.manage.header'
        descrKey='agencies.dashboard.manage.descr'
        icon={[
          { name: 'building', corner: 'top left', style: { width: '1.18em', height: '1em', position: 'absolute' } },
          { name: 'building', corner: 'top right' },
          { name: 'building', corner: 'bottom left' },
          { name: 'building', corner: 'bottom right' },
        ]}
        href='/agencies/manage'
        onClick={this.onClickManage.bind(this)}
      />
    );
  }

  /**
   * Join agencies card.
   */
  private get joinCard(): React.ReactNode {
    return (
      <Card
        headerKey='agencies.dashboard.join.header'
        descrKey='agencies.dashboard.join.descr'
        icon={[
          { name: 'building' },
          { name: 'arrow', size: 'tiny', style: { left: '0', right: 'auto' } },
        ]}
        href='/agencies/join'
        onClick={this.onClickJoin.bind(this)}
      />
    );
  }

  /**
   * Sign up agency card.
   */
  private get signUpCard(): React.ReactNode {
    return (
      <Card
        headerKey='agencies.dashboard.signUp.header'
        descrKey='agencies.dashboard.signUp.descr'
        icon={{ name: 'edit' }}
        href='/agencies/join?signUp=true'
        onClick={this.onClickSignUp.bind(this)}
      />
    );
  }

  /**
   * Render function.
   */
  render(): React.ReactNode {
    const { t } = this.props;
    return (
      <Page
        title={t('agencies.dashboard.header')}
      >
      <Card.Group centered>
        {this.manageCard}
        {this.joinCard}
        {this.signUpCard}
      </Card.Group>
      </Page>
    );
  }
}

export default withContext(Dashboard, 'i18n', 'local');
