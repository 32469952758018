/** @module components.Nav */

import React, { SyntheticEvent, useRef } from 'react';
import * as ReactDOM from 'react-dom';
import {
  RouteComponentProps,
  withRouter,
  useRouteMatch,
  useHistory,
} from 'react-router';
import { Link, NavLink, Redirect } from 'react-router-dom';
import {
  isBrowser,
  isMobile,
} from 'react-device-detect';
import classnames from 'classnames';
import * as style from '@bryxinc/style/nav.module.css';

import { withErrorBoundary } from '../ErrorBoundary';
import Branding from './Branding';

interface AbstractNavMenuItemConfig {
  name: string;
  icon?: any;
  toggleMenu?: (e: SyntheticEvent) => void;
}
interface PathNavMenuItemConfig extends AbstractNavMenuItemConfig {
  path: string;
}
interface FuncNavMenuItemConfig extends AbstractNavMenuItemConfig {
  function: (e: SyntheticEvent) => void;
}
interface ParentNavMenuItemConfig extends AbstractNavMenuItemConfig {
  navOptions: NavMenuItemConfig[];
  parentPath?: string;
}

export type NavMenuItemConfig = PathNavMenuItemConfig | FuncNavMenuItemConfig | ParentNavMenuItemConfig;

/**
 * PathNav type guard.
 * @param n NavMenuItemConfig to test
 * @return True if NavMenuItemConfig is for path
 */
function isPathNav(n: NavMenuItemConfig): boolean {
  return (n as PathNavMenuItemConfig).path != undefined;
}
/**
 * PathNav type guard.
 * @param n NavMenuItemConfig to test
 * @return True if NavMenuItemConfig is for function
 */
function isFuncNav(n: NavMenuItemConfig): boolean {
  return (n as FuncNavMenuItemConfig).function != undefined;
}
/**
 * PathNav type guard.
 * @param n NavMenuItemConfig to test
 * @return True if NavMenuItemConfig is for parent
 */
function isParentNav(n: NavMenuItemConfig): boolean {
  return (n as ParentNavMenuItemConfig).navOptions != undefined;
}
/**
 * Convert nav item name into key
 */
function nameToKey(name: string): string {
  return name.toLowerCase().replace(' ', '-');
}

/**
 * Custom react hook to use previous value of state.
 */
function usePrevious(value: any): any {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = React.useRef();

  // Store current value in ref
  React.useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

/**
 * NavMenuItem react component.
 * @param nProps
 */
export function NavMenuItem(nProps: NavMenuItemConfig): any {
  const key = nameToKey(nProps.name);
  const history = useHistory();
  if (isPathNav(nProps)) {
    nProps = nProps as PathNavMenuItemConfig;
    const path = nProps.path;
    const match = useRouteMatch(path);
    if (nProps.toggleMenu) {
      // const toggleMenu = nProps.toggleMenu;
      // const onClick = (e: SyntheticEvent) => {
      //   e.preventDefault();
      //   toggleMenu(e);
      //   history.push(path);
      // };
      // return (
      //   <li key={key}>
      //     <a
      //       href={path}
      //       onClick={onClick}
      //       className={classnames({ [style.active]: !!match })}
      //     >
      //       <span className={style.text}>{nProps.name}</span>
      //       {nProps.icon}
      //     </a>
      //   </li>
      // );
      // nProps = nProps as PathNavMenuItemConfig;
      return (
        <li key={key}>
          <NavLink to={nProps.path} onClick={nProps.toggleMenu}>
            <span className={style.text}>{nProps.name}</span>
            {nProps.icon}
          </NavLink>
        </li>
      );
    }
    return (
      <li key={key}>
        <NavLink to={nProps.path}>
          <span className={style.text}>{nProps.name}</span>
          {nProps.icon}
        </NavLink>
      </li>
    );
  }
  if (isFuncNav(nProps)) {
    nProps = nProps as FuncNavMenuItemConfig;
    if (nProps.toggleMenu) {
      const toggleMenu = nProps.toggleMenu;
      const func = nProps.function;
      const onClick = (e: SyntheticEvent) => {
        toggleMenu(e);
        func(e);
      };
      return (
        <li key={key}>
          <a onClick={onClick}>
            <span className={style.text}>{nProps.name}</span>
            {nProps.icon}
          </a>
        </li>
      );
    }
    return (
      <li key={key}>
        <a onClick={nProps.function}>
          <span className={style.text}>{nProps.name}</span>
          {nProps.icon}
        </a>
      </li>
    );
  }

  if (isParentNav(nProps)) {
    nProps = nProps as ParentNavMenuItemConfig;
    const match = nProps.parentPath ? useRouteMatch(nProps.parentPath) : null;
    const [open, setOpen] = React.useState(false);
    const [focus, setFocus] = React.useState(false);
    let childNavMenuItems;
    if (nProps.toggleMenu) {
      const tParentMenu = nProps.toggleMenu;
      const toggleMenu = (e: SyntheticEvent) => {
        setFocus(false);
        setOpen(false);
        tParentMenu(e);
      };
      childNavMenuItems = nProps.navOptions.map(
        (n: NavMenuItemConfig, i: number) => (
          <NavMenuItem
            key={i}
            toggleMenu={toggleMenu}
            {...n}
          />
        ),
      );
    } else {
      childNavMenuItems = nProps.navOptions.map(
        (n: NavMenuItemConfig, i: number) => <NavMenuItem key={i} {...n} />,
      );
    }
    // const childNavMenuItems = nProps.navOptions.map(
    //   // (n: NavMenuItemConfig, i: number) => <NavMenuItem key={i} {...n} />,
    //   (n: NavMenuItemConfig, i: number) => {
    //     if (nProps.toggleMenu) {
    //       return (
    //         <NavMenuItem
    //           key={i}
    //           toggleMenu={nProps.toggleMenu}
    //           {...n}
    //         />
    //       );
    //     }
    //     return <NavMenuItem key={i} {...n} />;
    //   },
    // );
    // const [open, setOpen] = React.useState(false);
    // const [focus, setFocus] = React.useState(false);
    const prevFocus = usePrevious(focus);
    // const toggleOpen = (e: SyntheticEvent) => setOpen(!open);
    // const myRef = React.useRef(null);
    const onFocus = (e: any) => {
      // console.log(`${nameToKey(nProps.name)} focused`);
      if (focus) {
        return;
      }
      setFocus(true);
    };
    const onBlur = (e: any) => {
      // console.log(`${nameToKey(nProps.name)} blurred`);
      // const currentTarget = _.get(e, 'currentTarget')
      // if (currentTarget && currentTarget.contains(document.activeElement)) return
      if (!focus) {
        return;
      }
      // if (this.isMouseDown) return
      setFocus(false);
    };
    const onClick = (e: any) => {
      // console.log(`${nameToKey(nProps.name)} focused`);
      if (focus) {
        setFocus(false);
        return;
      }
      setFocus(true);
    };

    React.useEffect(() => {
      // const hasFocus = document.activeElement === myRef.current;
      // const containsFocus = (myRef.current as any).contains(document.activeElement);
      // setOpen(hasFocus || containsFocus);

      if (!prevFocus && focus && !open) {
        // console.log(`${nameToKey(nProps.name)} opening`, { prevFocus, focus, open });
        setOpen(true);
      } else {
        if (prevFocus && !focus && open) {
          // console.log(`${nameToKey(nProps.name)} closing`, { prevFocus, focus, open });
          setOpen(false);
        }
      }
    });

    // ref={myRef}

    // If browser, return with hovering enabled
    if (isBrowser) {
      // If parent path, link to it...?
      if (nProps.parentPath) {
        return (
          <li
            key={key}
            tabIndex={-1}
            className={style.hasChildren}
            onMouseEnter={onFocus}
            onMouseLeave={onBlur}
          >
            <NavLink to={nProps.parentPath}>
              <span className={style.text}>{nProps.name}</span>
              {nProps.icon}
            </NavLink>
            <ul className={`${open ? style.open : style.closed}`}>
              {childNavMenuItems}
            </ul>
          </li>
        );
      }
      return (
        <li
          key={key}
          tabIndex={-1}
          className={style.hasChildren}
          onMouseEnter={onFocus}
          onMouseLeave={onBlur}
        >
          <a className={classnames({ [style.active]: !!match })}>
            <span className={style.text}>{nProps.name}</span>
            {nProps.icon}
          </a>
          <ul className={`${open ? style.open : style.closed}`}>
            {childNavMenuItems}
          </ul>
        </li>
      );
    }

    // <li onFocus={onFocus} onBlur={onBlur} >

    return (
      <li
        key={key}
        tabIndex={-1}
        className={style.hasChildren}
      >
        <a className={classnames({ [style.active]: !!match })} onClick={onClick}>
          <span className={style.text}>{nProps.name}</span>
          {nProps.icon}
        </a>
        <ul className={`${open ? style.open : style.closed}`}>
          {childNavMenuItems}
        </ul>
      </li>
    );
  }
  throw new Error('Something went wrong rendering nav item.');
}

export default withErrorBoundary(NavMenuItem);
