/** @module components.Agencies */

import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Container, Grid, Progress, Tab, Label } from 'semantic-ui-react';
import qs from 'qs';

import {
  AgencyGroup,
  AgencyGroupTypeEnum,
  ApiResult,
  LeadInfo,
  PageResult,
  SearchAgency,
  NewAgencyInfo,
  DataFeedKind,
  MinimalDispatchGroup,
  CountryInfo, RegionInfo,
} from '@bryxinc/lunch/models';
import classnames from 'classnames';

import * as style from '@bryxinc/style/main.module.css';
import * as headerStyle from '@bryxinc/style/header.module.css';
import * as color from '@bryxinc/style/color';

import Page from '../../Page';
import PaneWrapper from './PaneWrapper';
import Begin from './Begin';
import Select, { SelectedAgency } from './JoinRequest/Select';
import Reason from './JoinRequest/Reason';
import SignUpBasicInfo from './SignUp/BasicInfo';
import SignUp, { SignUpInfo } from './SignUp';
import SignUpOtherInfo from './SignUp/OtherInfo';
import Submit from './Submit';
import Success from './Success';
import { withContext, WithTranslation } from '@bryxinc/lunch/context';

enum TabType {
  BEGIN, SELECT, REASON, SUBMIT, SUCCESS, SIGNUP_INFO, SIGNUP_OTHER_INFO,
}

interface TabState {
  [tab: number]: boolean;
}

interface JoinProps extends
  RouteComponentProps,
  WithTranslation { }

interface JoinUrlParams {
  // selected?: Array<{ agency: { id: string }, groups: Array<{ id: string }> }>;
  selected?: { agency: { id: string }, groups: Array<{ id: string }> };
  signUp?: boolean;
  reason?: string;
}

interface JoinState {
  activeTab: number;
  signUp: boolean;
  selected: SelectedAgency | null;
  reason: string;
  newAgencyInfo: Partial<NewAgencyInfo>;
  leadInfo: Partial<SignUpInfo>;
  tabVerified: TabState;
  tabEnabled: TabState;
  error?: { message: string, data?: any } | { i18nKey: string, data?: any } | false;
}

/**
 * Join agencies react component.
 * To be displayed on account landing page.
 */
export class Join extends React.Component<JoinProps, JoinState> {
  readonly state: JoinState = {
    activeTab: 0,
    signUp: false,
    selected: null,
    reason: '',
    newAgencyInfo: {},
    leadInfo: {},
    tabVerified: {},
    tabEnabled: {
      [TabType.BEGIN]: true,
    },
    error: false,
  };

  /**
   * Actions to execute on component mount.
   */
  componentDidMount(): void {
    const urlParams = this.urlParams;

    const selected = urlParams.selected || null;
    const signUp = urlParams.signUp || false;
    const reason = urlParams.reason || '';

    if (selected) {
      this.setState({
        activeTab: reason ? 3 : 1,
        signUp: false,
        selected,
        reason,
        tabVerified: {
          [TabType.BEGIN]: true,
          [TabType.SELECT]: true,
          [TabType.REASON]: !!reason,
        },
      });
      return;
    }

    this.setState({
      activeTab: signUp ? 1 : 0,
      signUp: signUp,
      selected,
      reason,
      // tabVerified: {
      //   [TabType.BEGIN]: true,
      //   [TabType.SELECT]: !!selected.length,
      //   [TabType.REASON]: !!reason,
      // },
      tabEnabled: {
        [TabType.BEGIN]: true,
        [TabType.SELECT]: !signUp,
        [TabType.SIGNUP_INFO]: signUp,
      },
    });

    return;
  }

  /**
   * Parsed url params.
   * @TODO Actually parse query and validate selected.
   */
  private get urlParams(): JoinUrlParams {
    const {
      location: { search: urlQStr },
    } = this.props;
    const parsedQ = qs.parse(
      urlQStr,
      { ignoreQueryPrefix: true, allowDots: true },
    );

    const signUp = parsedQ.signUp || parsedQ.signup || false;
    const reason = parsedQ.reason || '';

    let selected = parsedQ.selected || [];

    if (!Array.isArray(selected)) { // Check that selected is array
      selected = [selected];
    }

    if (parsedQ.agency) { // Check for other params and parse accordingly
      if (Array.isArray(parsedQ.agency)) {
        selected.push(
          ...parsedQ.agency.map((a: any, i: number) => ({
            agency: a,
            groups: parsedQ.groups[a.id ? a.id : a] || parsedQ.groups[i] || [],
          })),
        );
      } else {
        selected.push({
          agency: parsedQ.agency,
          groups: parsedQ.groups || parsedQ.group || [],
        });
      }
    }

    selected = selected.map( // Verifiy and correct selected as needed
      (s: any) => {
        if (typeof s.agency === 'string') {
          s.agency = { id: s.agency };
        }
        if (typeof s.agency.id !== 'string') {
          return null;
        }
        if (!Array.isArray(s.groups)) {
          s.groups = [s.groups];
        }
        s.groups = s.groups.map((g: any) => {
          if (!g) {
            return null;
          }
          if (typeof g === 'string') {
            return { id: g };
          }
          if (typeof g.id === 'string') {
            return { id: g };
          }
          return null;
        }).filter((g: any) => !!g);
        return s;
      },
    ).filter((s: any) => !!s);

    selected = selected[0] || null; // KLUDGE

    return {
      selected,
      reason,
      signUp,
    };
  }

  /**
   * Get function to update if a tab is verified.
   */
  private updateTabVerified(t: TabType): (v: boolean) => void {
    return (v: boolean) => {
      const { signUp, tabVerified, tabEnabled, activeTab } = this.state;
      // const i = (activeTab + (signUp && activeTab > 0 ? 4 : 0)) % 7;
      const newTabVerified = tabVerified;
      newTabVerified[t] = v;
      const newTabEnabled = tabEnabled;
      newTabEnabled[TabType.BEGIN] = true;
      newTabEnabled[TabType.SELECT] = !signUp;
      newTabEnabled[TabType.SIGNUP_INFO] = signUp;
      newTabEnabled[TabType.REASON] = !!newTabEnabled[TabType.SELECT] && !!newTabVerified[TabType.SELECT];
      newTabEnabled[TabType.SIGNUP_OTHER_INFO] = !!newTabEnabled[TabType.SIGNUP_INFO] && !!newTabVerified[TabType.SIGNUP_INFO];
      newTabEnabled[TabType.SUBMIT] = signUp ?
        !!newTabEnabled[TabType.SIGNUP_INFO] && !!newTabVerified[TabType.SIGNUP_INFO] :
        !!newTabEnabled[TabType.REASON] && !!newTabVerified[TabType.REASON];

      // Kludge to fix active tab
      let newActiveTab = activeTab;
      switch (activeTab) {
        case 0:
          break;
        case 4:
          if (!newTabEnabled[TabType.SUCCESS]) {
            newActiveTab = 3;
          }
        case 3:
          if (!newTabEnabled[signUp ? TabType.SUCCESS : TabType.SUBMIT]) {
            newActiveTab = 2;
          }
        case 2:
          if (!newTabEnabled[signUp ? TabType.SUBMIT : TabType.REASON]) {
            newActiveTab = 1;
          }
        case 1:
          if (!newTabEnabled[signUp ? TabType.SIGNUP_INFO : TabType.SELECT]) {
            newActiveTab = 0;
          }
      }

      this.setState({
        tabVerified: newTabVerified,
        tabEnabled: newTabEnabled,
        activeTab: newActiveTab,
      });
    };
  }

  /**
   * Function to update if tab is enabled.
   * @TODO Can probably consolidate into other functions.
   */
  private updateTabEnabled(): void {
    const { signUp, tabVerified, tabEnabled } = this.state;
    const newTabEnabled = tabEnabled;
    newTabEnabled[TabType.BEGIN] = true;
    newTabEnabled[TabType.SELECT] = !signUp;
    newTabEnabled[TabType.SIGNUP_INFO] = signUp;
    newTabEnabled[TabType.REASON] = !!newTabEnabled[TabType.SELECT] && !!tabVerified[TabType.SELECT];
    newTabEnabled[TabType.SIGNUP_OTHER_INFO] = !!newTabEnabled[TabType.SIGNUP_INFO] && !!tabVerified[TabType.SIGNUP_INFO];
    newTabEnabled[TabType.SUBMIT] = signUp ?
      !!newTabEnabled[TabType.SIGNUP_OTHER_INFO] && !!tabVerified[TabType.SIGNUP_OTHER_INFO] :
      !!newTabEnabled[TabType.REASON] && !!tabVerified[TabType.REASON];
    this.setState({
      tabEnabled: newTabEnabled,
    });
  }

  /**
   * Getter for if agencies have been selected.
   * @WARN Unusesd?
   */
  private get hasSelectedAgencies(): boolean {
    const { signUp, selected } = this.state;
    return !!(!signUp && selected);
  }

  /**
   * Getter for if sign up basic info is complete.
   */
  private get signUpBasicInfoDone(): boolean {
    const { signUp, newAgencyInfo } = this.state;
    return !!(signUp && newAgencyInfo.name && newAgencyInfo.zipcode);
  }

  /**
   * Getter for if sign up other info is complete.
   */
  private get signUpOtherInfoDone(): boolean {
    const { signUp, newAgencyInfo } = this.state;
    if (signUp && newAgencyInfo.name && newAgencyInfo.zipcode) {
      return !!(newAgencyInfo.useCallAlert &&
        newAgencyInfo.dataFeed &&
        (newAgencyInfo.dataFeed !== DataFeedKind.Other || newAgencyInfo.dataFeedAdd));
    }
    return false;
  }

  /**
   * Getter for progress
   */
  private get progress(): number {
    const i = this.state.activeTab;
    return 100 * i / 3;
  }

  /**
   * Update selected agencies.
   */
  private updateSelected(agency: SelectedAgency | null, valid: boolean = false): void {
    // const haveGroups = nAgencies.some((a: SelectedAgency) => a.hasGroups);
    // Kludge to update verified.
    // Should honestly just have an "updateSelected" function.
    this.updateTabVerified(TabType.SELECT)(valid);
    this.setState({
      selected: agency,
    });
  }

  /**
   * Update agency selected groups.
   */
  private updateReason(reason: string, valid: boolean = false): void {
    this.setState({ reason });
    // Kludge to update verified.
    // Honestly should just pass in if verified.
    this.updateTabVerified(TabType.REASON)(valid);
  }

  /**
   * Update new agency info.
   */
  private updateNewAgencyInfo(newAgencyInfo: Partial<NewAgencyInfo>): void {
    this.setState({
      newAgencyInfo: {
        ...this.state.newAgencyInfo,
        ...newAgencyInfo,
      },
    });
    // Kludge to update verified.
    // Honestly should just pass in if verified.
    if (newAgencyInfo.name || newAgencyInfo.zipcode) {
      this.updateTabVerified(TabType.SIGNUP_INFO)(false);
    } else {
      this.updateTabVerified(TabType.SIGNUP_OTHER_INFO)(false);
    }
  }

  /**
   * Update lead info for agency signup.
   */
  private updateLeadInfo(leadInfo: Partial<SignUpInfo>, valid: boolean = false): void {
    this.setState({
      leadInfo: {
        ...this.state.leadInfo,
        ...leadInfo,
      },
    });
    this.updateTabVerified(TabType.SIGNUP_INFO)(valid);
  }

  /**
   * Handle tab change event.
   */
  private onTabChange(
    e: React.SyntheticEvent,
    { activeIndex, panes }: { activeIndex: number, panes: any[] },
    // data: any,
    // { activeIndex, panes }: { activeIndex: number, panes: object[] },
  ): void {
    // console.log(data);
    // const { activeIndex, panes } = data;
    if (this.state.tabEnabled[panes[activeIndex].pane.key]) {
      this.setState({ activeTab: activeIndex });
    }
    // if (this.state.activeTab > activeIndex) {
    //   this.setState({ activeTab: activeIndex });
    // }
  }

  /**
   * Handle click sign up agency event.
   */
  private onClickSignUp(): void {
    this.setState({
      signUp: true,
      activeTab: 1,
    });
  }

  /**
   * Handle click search agencies event.
   */
  private onClickSearch(): void {
    this.setState({
      signUp: false,
      activeTab: 1,
    });
  }

  /**
   * Go back to previous tab.
   */
  private goBack(): void {
    this.setState({
      activeTab: this.state.activeTab - 1,
    });
  }

  /**
   * Continue to next tab.
   */
  private continue(): void {
    this.setState({
      activeTab: this.state.activeTab + 1,
    });
  }

  /**
   * Cancel, clear state, and return to start tab.
   * Or previous history. I dunno.
   */
  private cancel(): void {
    this.setState({
      activeTab: 0,
      signUp: false,
      selected: null,
      reason: '',
      newAgencyInfo: {},
      tabVerified: {},
      tabEnabled: {
        [TabType.BEGIN]: true,
        [TabType.SELECT]: true,
        [TabType.SIGNUP_INFO]: false,
      },
    });
  }

  /**
   * Getter function for tab panes.
   */
  private get panes(): object[] {
    if (!this.props.tReady) {
      return [];
    }
    const panes = [
      {
        menuItem: this.props.t('agencies.join.begin'),
        pane: {
          attached: false,
          key: TabType.BEGIN,
          active: this.state.activeTab == 0,
          content: (
            <Begin
              onSearch={this.onClickSearch.bind(this)}
              onSignUp={this.onClickSignUp.bind(this)}
            />
          ),
        } as any,
      },
    ];
    if (this.state.signUp) {
      panes.push(
        {
          menuItem: this.props.t('agencies.join.signUp.basicInfo'),
          pane: {
            attached: false,
            key: TabType.SIGNUP_INFO,
            active: this.state.activeTab == 1,
            content: (
              <SignUp
                updateLead={this.updateLeadInfo.bind(this)}
                onBack={this.goBack.bind(this)}
                onCont={this.continue.bind(this)}
                updateVerified={this.updateTabVerified(TabType.SIGNUP_INFO).bind(this)}
              />
            ),
          } as any,
        },
      );
    } else {
      panes.push(
        {
          menuItem: this.props.t('agencies.join.select'),
          pane: {
            attached: false,
            key: TabType.SELECT,
            active: this.state.activeTab == 1,
            content: (
              <Select
                selected={this.state.selected}
                updateSelected={this.updateSelected.bind(this)}
                updateVerified={this.updateTabVerified(TabType.SELECT).bind(this)}
                onBack={this.goBack.bind(this)}
                onCont={this.continue.bind(this)}
              />
            ),
          } as any,
        },
      );
      panes.push(
        {
          menuItem: {
            content: this.props.t('agencies.join.reason'),
            disabled: !this.state.tabEnabled[TabType.REASON],
            key: TabType.REASON,
          } as any,
          pane: {
            attached: false,
            key: TabType.REASON,
            active: this.state.activeTab == 2,
            content: (
              <Reason
                reason={this.state.reason}
                updateReason={this.updateReason.bind(this)}
                onBack={this.goBack.bind(this)}
                onCont={this.continue.bind(this)}
                updateVerified={this.updateTabVerified(TabType.REASON).bind(this)}
              />
            ),
          } as any,
        },
      );
    }
    const submitData: any = this.state.signUp ? this.state.leadInfo : {
      agencies: this.state.selected ? [this.state.selected] : [], // KLUDGE
      agency: this.state.selected, // KLUDGE
      reason: this.state.reason,
    };
    panes.push(
      {
        menuItem: {
          content: this.props.t('agencies.join.submit'),
          disabled: !this.state.tabEnabled[TabType.SUBMIT],
          key: TabType.SUBMIT,
        } as any,
        pane: {
          attached: false,
          key: TabType.SUBMIT,
          active: this.state.signUp ? this.state.activeTab == 2 : this.state.activeTab == 3,
          content: (
            <Submit
              signUp={this.state.signUp}
              data={submitData}
              onCancel={this.cancel.bind(this)}
              onBack={this.goBack.bind(this)}
              onCont={this.continue.bind(this)}
              updateVerified={this.updateTabVerified(TabType.SUBMIT).bind(this)}
            />
          ),
        } as any,
      },
    );
    if (this.state.tabVerified[TabType.SUBMIT]) {
      panes.push(
        {
          menuItem: {
            content: this.props.t('agencies.join.success'),
            disabled: !this.state.tabEnabled[TabType.SUCCESS],
            key: TabType.SUCCESS,
          } as any,
          pane: {
            attached: false,
            key: TabType.SUCCESS,
            active: this.state.signUp ? this.state.activeTab == 3 : this.state.activeTab == 4,
            content: (
              <Success
                signUp={this.state.signUp}
              />
            ),
          } as any,
        },
      );
    }
    // Mapping to create redner function so that "renderActiveOnly" will work
    // b/c semantic-ui-react is buggy and "renderActiveOnly" does not work w/
    // tab pane shorthand
    return panes.map(
      (p: any) => ({ ...p, render: () => (Tab.Pane as any).create(p.pane) }),
    );
  }

  /**
   * Render function.
   */
  render(): React.ReactNode {
    // progress='ratio' autoSuccess
    const { t } = this.props;
    const { signUp } = this.state;
    return (
      <Page
        title={!signUp ? t('agencies.join.header') : t('agencies.join.signUpHeader')}
      >
        <Progress percent={this.progress} />
        <Tab
          menu={{
            pointing: true,
            style: {
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            },
          }}
          panes={this.panes}
          onTabChange={this.onTabChange.bind(this)}
          activeIndex={this.state.activeTab}
          renderActiveOnly
        />
      </Page>
    );
  }
}

export default withContext(Join, 'i18n');
