/** @module components.input */

import * as React from "react";
import { FormEvent, SyntheticEvent } from "react";
import { Form, Input } from "semantic-ui-react";

import { isValidEmail } from "@bryxinc/lunch/utils/functions/format";
// import * as style from '@bryxinc/style/main.module.css';

import { withContext, WithTranslation } from "@bryxinc/lunch/context";

interface EmailProps extends WithTranslation {
  value: string;
  onChange?: (n: string, v?: boolean | null) => void;
  labels?: boolean;
  placeholders?: boolean;
  required?: boolean;
  disabled?: boolean;
  inline?: boolean;
  style?: object;
  className?: string;
  username?: boolean;
}

interface EmailState {
  focus: boolean | null;
}

/**
 * Email input component.
 */
export class Email extends React.Component<EmailProps, EmailState> {
  static defaultProps: Partial<EmailProps> = {
    value: "",
    onChange: (n: string, v?: boolean | null) => {
      return;
    },
    labels: false,
    placeholders: false,
    required: false,
    disabled: false,
    inline: false,
    style: {},
    className: "",
    username: false,
  };
  readonly state: EmailState = { focus: null };

  /**
   * Handler for email change.
   */
  private onChange(
    e: SyntheticEvent<HTMLElement>,
    { value }: { value: string }
  ): void {
    const { onChange, required } = this.props as Required<EmailProps>;
    onChange(
      value,
      required ? isValidEmail(value) : value.length == 0 || isValidEmail(value)
    );
  }

  /**
   * Handle focus.
   */
  private onFocus(e: SyntheticEvent): void {
    this.setState({
      focus: !this.state.focus,
    });
  }

  /**
   * Get input props.
   */
  get inputProps(): any {
    const {
      value,
      t,
      tReady,
      onChange,
      username,
      required,
      disabled,
      labels,
      inline,
      placeholders,
      style: compStyle,
      className,
    } = this.props;
    const { focus } = this.state;
    const p: any = {
      key: "email-input",
      loading: !tReady,
      value: value || "",
      onChange: this.onChange.bind(this),
      onFocus: this.onFocus.bind(this),
      onBlur: this.onFocus.bind(this),
      inline,
      required,
      disabled,
      style: compStyle,
      className,
    };
    if (value && !focus) {
      p.error = !isValidEmail(value);
    }
    if (!value && focus === false && required) {
      p.error = { content: t("form.fieldRequired") };
    }
    if (username) {
      p.type = "username";
    }
    if (labels) {
      p.label = t("form.label.email");
    }
    if (placeholders) {
      p.placeholder = t("form.label.email");
    }
    return p;
  }

  /**
   * Render function.
   */
  render(): React.ReactNode {
    return <Form.Input {...this.inputProps} />;
  }
}

export default withContext(Email, "i18n");
