/** @module components.Account */

/** @module components */

import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import Card from '../Card';
import Page from '../Page';
import { withContext, WithTranslation, WithLocal } from '@bryxinc/lunch/context';

interface DashboardProps extends RouteComponentProps, WithTranslation, WithLocal { }

/**
 * Account dashboard react component.
 * To be displayed on account landing page.
 */
export class Dashboard extends React.Component<DashboardProps, any> {

  /**
   * Handle disable click.
   */
  private onClickDisable(e: React.SyntheticEvent): void {
    e.preventDefault();
    const { history } = this.props;
    history.push('/account/disable');
  }

  /**
   * Handle password click.
   */
  private onClickPassword(e: React.SyntheticEvent): void {
    e.preventDefault();
    const { history } = this.props;
    history.push('/account/password');
  }

  /**
   * Handle email alerts click.
   */
  private onClickEmailAlerts(e: React.SyntheticEvent): void {
    e.preventDefault();
    const { history } = this.props;
    history.push('/account/emailalerts');
  }

  /**
   * Handle sign up click.
   */
  private onClickSettings(e: React.SyntheticEvent): void {
    e.preventDefault();
    const { history } = this.props;
    history.push('/account/settings');
  }

  /**
   * Disable card.
   */
  private get disableCard(): React.ReactNode {
    return (
      <Card
        headerKey='account.dashboard.disable.header'
        descrKey='account.dashboard.disable.descr'
        icon={[{ name: 'user' }, { name: 'close' }]}
        href='/account/disable'
        onClick={this.onClickDisable.bind(this)}
      />
    );
  }

  /**
   * Password card.
   */
  private get passwordCard(): React.ReactNode {
    return (
      <Card
        headerKey='account.dashboard.password.header'
        descrKey='account.dashboard.password.descr'
        icon={{ name: 'lock' }}
        href='/account/password'
        onClick={this.onClickPassword.bind(this)}
      />
    );
  }

  /**
   * Email alerts card.
   */
  private get emailAlertsCard(): React.ReactNode {
    return (
      <Card
        headerKey='account.dashboard.emailAlerts.header'
        descrKey='account.dashboard.emailAlerts.descr'
        icon={[
          { name: 'mail' },
          { name: 'bell', corner: 'bottom right' },
        ]}
        href='/account/emailalerts'
        onClick={this.onClickEmailAlerts.bind(this)}
      />
    );
  }

  /**
   * Settings card.
   */
  private get settingsCard(): React.ReactNode {
    return (
      <Card
        headerKey='account.dashboard.settings.header'
        descrKey='account.dashboard.settings.descr'
        icon={{ name: 'settings' }}
        href='/account/settings'
        onClick={this.onClickSettings.bind(this)}
      />
    );
  }

  /**
   * Render function.
   */
  render(): React.ReactNode {
    const { t } = this.props;
    return (
      <Page
        title={t('account.dashboard.header')}
      >
      <Card.Group centered>
        {this.disableCard}
        {this.passwordCard}
        {this.emailAlertsCard}
      </Card.Group>
      </Page>
    );
  }
}

export default withContext(Dashboard, 'i18n', 'local');
