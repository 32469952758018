/** @module components.Agencies.Join */

import * as React from 'react';
import { SyntheticEvent } from 'react';
import { Grid, Button, Popup } from 'semantic-ui-react';

import * as style from '@bryxinc/style/main.module.css';
import * as color from '@bryxinc/style/color';

import PaneWrapper from './PaneWrapper';
import { withContext, WithTranslation } from '@bryxinc/lunch/context';

interface SuccessProps extends WithTranslation {
  signUp?: boolean;
}

/**
 * Submit tab react component for Join Agency page.
 */
export class Success extends React.Component<SuccessProps> {
  static defaultProps: object = {
    signUp: false,
  };

  /**
   * Render function.
   */
  render(): React.ReactNode {
    const { t, signUp } = this.props;
    return (
      <PaneWrapper>
        <Grid>
          <Grid.Row columns={2} centered>
            <Grid.Column textAlign='center' width='14'>
              <h3>
                {t('agencies.join.successMsg', { context: signUp ? 'signUp' : 'joinRequest' })}
              </h3>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </PaneWrapper>
    );
  }
}

export default withContext(Success, 'i18n');
