/** @module components.Agencies.Manage */

import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import {
  Accordion,
  Button,
  Grid,
  Icon,
  Modal,
  Pagination,
  Popup,
  Tab,
  Table,
  Segment,
} from 'semantic-ui-react';

import {
  ApiResult,
  PageResult,
  UserJoinRequest,
  JoinRequestStatusEnum,
  // JoinRequestSortingOptions,
  // SortingConfig,
  RequestUnit,
} from '@bryxinc/lunch/models';
import * as style from '@bryxinc/style/main.module.css';
import * as color from '@bryxinc/style/color';

import Alert from '../../Alert';
import { withContext, WithTranslation, WithApi } from '@bryxinc/lunch/context';
import BryxApi from '@bryxinc/lunch/utils/AccountApi';

interface JoinRequestsProps extends WithTranslation, WithApi<BryxApi> { }

interface JoinRequestsState {
  activePage: number;
  count: number;
  requests: UserJoinRequest[];
  open: boolean[];
  loading: boolean;
  error: { message: string, data?: any } | { i18nKey: string, data?: any } | false;
  modalOpen: boolean;
  activeRequest: number;
}

/**
 * Manage agency join requests react component.
 */
export class JoinRequests extends React.Component<JoinRequestsProps, JoinRequestsState> {
  static readonly PER_PAGE: number = 10;

  readonly state: JoinRequestsState = {
    activePage: 1,
    count: 0,
    requests: [],
    open: [],
    loading: true,
    error: false,
    modalOpen: false,
    activeRequest: -1,
  };

  /**
   * Executes on component mounting.
   */
  componentDidMount(): void {
    const { api } = this.props;
    // status: JoinRequestStatusEnum[],
    // query: string | null,
    // sortConfig: SortingConfig<JoinRequestSortingOptions>,
    // callback: (result: ApiResult<PageResult<JoinRequest>>) => void,
    // page?: number,
    // limit?: number,
    api.getUserJoinRequests(
      [JoinRequestStatusEnum.pending, JoinRequestStatusEnum.denied],
      null,
      { column: 'ts', direction: 'desc' },
      this.userJoinRequestsCB.bind(this),
      0,
      JoinRequests.PER_PAGE,
    );
  }

  /**
   * Callback for getting user agencies.
   */
  private userJoinRequestsCB(r: ApiResult<PageResult<UserJoinRequest>>): void {
    // If success, else, display error.
    if (r.success) {
      this.setState({
        requests: r.value.items,
        count: r.value.count,
        open: r.value.items.map(() => false),
        loading: false,
      });
    } else {
      this.setState({
        error: {
          i18nKey: 'agencies.manage.error',
          data: {
            message: r.message,
            debugMessage: r.debugMessage,
          },
        },
        loading: false,
      });
    }
  }

  /**
   * Callback for cancelling join request.
   */
  private cancelJoinRequestCB(r: ApiResult<null>): void {
    // If success, else, display error.
    if (r.success) {
      this.props.api.getUserJoinRequests(
        [JoinRequestStatusEnum.pending, JoinRequestStatusEnum.denied],
        null,
        { column: 'ts', direction: 'desc' },
        this.userJoinRequestsCB.bind(this),
        this.state.activePage - 1,
        JoinRequests.PER_PAGE,
      );
    } else {
      this.setState({
        error: {
          i18nKey: 'agencies.manage.error',
          data: {
            message: r.message,
            debugMessage: r.debugMessage,
          },
        },
      });
    }
  }

  /**
   * Open modal.
   * @param i Index of request to cancel.
   */
  private openModal(i: number): () => void {
    return () => {
      this.setState({
        modalOpen: true,
        activeRequest: i,
      });
    };
  }

  /**
   * Close modal
   */
  private closeModal(): void {
    this.setState({
      modalOpen: false,
      activeRequest: -1,
    });
  }

  /**
   * Handle page change.
   */
  private onPageChange(
    e: React.SyntheticEvent,
    { activePage }: { activePage: number },
  ): void {
    const { api } = this.props;
    this.setState({ activePage });
    api.getUserJoinRequests(
      [JoinRequestStatusEnum.pending, JoinRequestStatusEnum.denied],
      null,
      { column: 'ts', direction: 'desc' },
      this.userJoinRequestsCB.bind(this),
      activePage - 1,
      JoinRequests.PER_PAGE,
    );
  }

  /**
   * Handle request click.
   */
  private onRequestClick(i: number): () => void {
    return () => {
      const { open } = this.state;
      open[i] = !open[i];
      this.setState({ open });
    };
  }

  /**
   * Handle leave agency click.
   */
  private onRequestCancel(i: number): () => void {
    return () => {
      const request = this.state.requests[i];
      this.props.api.cancelJoinRequest(
        request.id,
        this.cancelJoinRequestCB.bind(this),
      );
      this.closeModal();
    };
  }

  /**
   * Error row, if error.
   */
  private get error(): React.ReactNode | null {
    if (!this.state.error) {
      return null;
    }
    return (
      <Grid.Column>
        <Alert
          type='error'
          {...this.state.error}
        />
      </Grid.Column>
    );
  }

  /**
   * Request cancel confirmation modal.
   */
  private get cancelModal(): React.ReactNode {
    const { t } = this.props;
    const { modalOpen, activeRequest, requests } = this.state;
    if (activeRequest < 0) { return null; }
    // const content = activeGroup !== null ? t(
    //   'agencies.manage.leaveAgencyGroup',
    //   {
    //     agency: agencies[activeAgency].name,
    //     group: ((agencies[activeAgency].dispatchGroups || [])[activeGroup] || {}).name,
    //   },
    // ) : t(
    //   'agencies.manage.leaveAgency',
    //   {
    //     agency: agencies[activeAgency].name,
    //   },
    // );
    return (
      <Modal size='mini' open={modalOpen} onClose={this.closeModal.bind(this)}>
        <Modal.Header>{t('agencies.manage.joinRequests.confirmCancel')}</Modal.Header>
        <Modal.Content><p>{t('agencies.manage.joinRequests.confirmCancelDescr')}</p></Modal.Content>
        <Modal.Actions>
          <Button
            negative
            content={t('general.no')}
            onClick={this.closeModal.bind(this)}
          />
          <Button
            positive
            icon='check'
            content={t('general.yes')}
            onClick={this.onRequestCancel(activeRequest).bind(this)}
          />
        </Modal.Actions>
      </Modal >
    );
  }

  /**
   * Join request table row react nodes.
   */
  private getStatusMsg(status: JoinRequestStatusEnum): string {
    const { t } = this.props;
    switch (status) {
      case JoinRequestStatusEnum.pending:
        return t('joinRequest.status.pending');
      case JoinRequestStatusEnum.approved:
        return t('joinRequest.status.approved');
      case JoinRequestStatusEnum.denied:
        return t('joinRequest.status.denied');
      case JoinRequestStatusEnum.expired:
        return t('joinRequest.status.expired');
      default:
        return t('joinRequest.status.unknown');
    }
  }

  /**
   * Join request table row react nodes.
   */
  private get requestRows(): React.ReactNode[] {
    const { requests, open } = this.state;
    console.log(requests);
    const { t } = this.props;
    // public id: string,
    // public creationTs: Date,
    // public expirationTs: Date,
    // public status: JoinRequestStatusEnum,
    // public unit: RequestUnit,
    // public requestingClient: RequestClient,
    // public message: string,
    // public manager: RequestClient | null,
    // public replyMessage: string | null,
    // public agency: MinimalAgency | null,
    const requestsMod = requests.map((r: UserJoinRequest, i: number) => {
      // const button = r.status == JoinRequestStatusEnum.pending ? (
      //   <Popup content={t('agencies.manage.joinRequests.cancel')} trigger={(
      //     <Button
      //       negative
      //       icon='erase'
      //       onClick={this.openModal(i).bind(this)}
      //     />
      //   )}/>
      // ) : null;
      const button = r.status == JoinRequestStatusEnum.pending ? (
        <Button
          negative
          icon='erase'
          onClick={this.openModal(i).bind(this)}
        />
      ) : null;
      return [(
        <Table.Row key={i}>
          <Table.Cell collapsing width='1'>
            {this.getStatusMsg(r.status)}
          </Table.Cell>
          <Table.Cell width='2'>
            {r.agency ? r.agency.name : ''}
          </Table.Cell>
          <Table.Cell width='3'>
            {r.message}
          </Table.Cell>
          <Table.Cell width='3'>
            {r.replyMessage}
          </Table.Cell>
          <Table.Cell width='1'>
            {t('general.dateTime', { date: r.creationTs })}
          </Table.Cell>
          <Table.Cell width='1'>
            {t('general.dateTime', { date: r.expirationTs })}
          </Table.Cell>
          <Table.Cell textAlign='center' collapsing width='1'>
            {button}
          </Table.Cell>
        </Table.Row>
      )];
    });
    return requestsMod.reduce((a: React.ReactNode[], e: React.ReactNode[]) => {
      return [...a, ...e];
    }, []);
  }

  /**
   * Agency table react node.
   */
  private get requestTable(): React.ReactNode {
    // return null;
    const { t } = this.props;
    return (
      <Table selectable key='requests'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell collapsing>{t('general.status')}</Table.HeaderCell>
            <Table.HeaderCell collapsing>{t('general.agencyName')}</Table.HeaderCell>
            <Table.HeaderCell collapsing>{t('general.message')}</Table.HeaderCell>
            <Table.HeaderCell collapsing>{t('general.reply')}</Table.HeaderCell>
            <Table.HeaderCell collapsing>{t('general.created')}</Table.HeaderCell>
            <Table.HeaderCell colSpan='1' collapsing>{t('general.expires')}</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Accordion as={Table.Body}>
          {...this.requestRows}
        </Accordion>
      </Table>
    );
  }

  /**
   * Pagination react node.
   */
  private get pagination(): React.ReactNode {
    const { activePage, count } = this.state;
    const totalPages = Math.max(Math.ceil(count / JoinRequests.PER_PAGE), 1);
    return (
      <Pagination
        key='pagination'
        activePage={activePage}
        onPageChange={this.onPageChange.bind(this)}
        totalPages={totalPages}
        ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
        firstItem={{ content: <Icon name='angle double left' />, icon: true }}
        lastItem={{ content: <Icon name='angle double right' />, icon: true }}
        prevItem={{ content: <Icon name='angle left' />, icon: true }}
        nextItem={{ content: <Icon name='angle right' />, icon: true }}
      />
    );
  }

  /**
   * Render function.
   */
  render(): React.ReactNode {
    return (
      <Tab.Pane loading={this.state.loading}>
        <Grid centered container columns='1'>
          {this.error}
          <Grid.Row centered>
            <Grid.Column textAlign='center' width='14'>{this.pagination}</Grid.Column>
          </Grid.Row>
          <Grid.Row centered>
            <Grid.Column textAlign='center' width='14'>{this.requestTable}</Grid.Column>
          </Grid.Row>
          <Grid.Row centered>
            <Grid.Column textAlign='center' width='14'>{this.pagination}</Grid.Column>
          </Grid.Row>
        </Grid>
        {this.cancelModal}
      </Tab.Pane>
    );
  }
}

export default withContext(JoinRequests, 'i18n', 'api');
