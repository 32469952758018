/** @module components.Account.SMS */

/** @module components */

import * as React from 'react';
import { SyntheticEvent, KeyboardEvent } from 'react';
import { RouteComponentProps } from 'react-router';
import { Form, Input } from 'semantic-ui-react';
import classnames from 'classnames';

import { isValidEmail } from '@bryxinc/lunch/utils/functions/format';
import * as style from '@bryxinc/style/main.module.css';
import * as headerStyle from '@bryxinc/style/header.module.css';
import * as color from '@bryxinc/style/color';

import { withContext, WithTranslation } from '@bryxinc/lunch/context';

interface NewDeviceFormProps extends WithTranslation {
  value: string; // { areaCode: string, prefix: string, lineNumber: string };
  valid: boolean | null; // { areaCode: boolean | null, prefix: boolean | null, lineNumber: boolean | null };
  onChange: (
    e: React.SyntheticEvent,
    d: { value: string, valid: boolean | null },
  ) => void;
}

interface NewDeviceFormState {
  areaCodeFocus: boolean;
  prefixFocus: boolean;
  lineNumberFocus: boolean;
}

/**
 * Add SMS device form react component for Account SMS decive management.
 * State is ultimately handled by parent component.
 */
export class NewDeviceForm extends React.Component<NewDeviceFormProps> {
  areaCodeRef: any = React.createRef();
  prefixRef: any = React.createRef();
  lineNumberRef: any = React.createRef();
  readonly state: NewDeviceFormState = {
    areaCodeFocus: false,
    prefixFocus: false,
    lineNumberFocus: false,
  };

  /**
   * Handle value change.
   */
  private onValueChange(e: SyntheticEvent, { value }: { value: string }): void {
    const { onChange } = this.props;
    onChange(
      e,
      {
        value: value,
        valid: value === '' ? null : isValidEmail(value),
      },
    );
  }

  /**
   * Value input.
   */
  private get valueInput(): React.ReactNode {
    const { t, value, valid } = this.props;
    return (
      <Input
        transparent
        ref={this.lineNumberRef}
        placeholder={t('placeholder.email')}
        value={value}
        error={valid == false}
        onChange={this.onValueChange.bind(this)}
      />
    );
  }

  /**
   * Render function.
   */
  render(): React.ReactNode {
    // width='5'
    return this.valueInput;
    return (
      <Form>
        <Form.Field>
          {this.valueInput}
        </Form.Field>
      </Form>
    );
  }
}

export default withContext(NewDeviceForm, 'i18n');
