/** @module components.Agencies.Join.JoinRequest */

import * as React from 'react';
import { FormEvent, SyntheticEvent } from 'react';
import { Form, Button, Popup } from 'semantic-ui-react';

import * as style from '@bryxinc/style/main.module.css';
import * as color from '@bryxinc/style/color';

import PaneWrapper from '../PaneWrapper';
import { withContext, WithTranslation } from '@bryxinc/lunch/context';

interface ReasonProps extends WithTranslation {
  reason: string;
  updateReason: (r: string, v?: boolean) => void;
  onBack: () => void;
  onCont: () => void;
  updateVerified: (v: boolean) => void;
}

interface ReasonState {
  error: boolean;
}

/**
 * Reason why join agency react component for Join Agency page.
 */
export class Reason extends React.Component<ReasonProps, ReasonState> {

  constructor(props: ReasonProps) {
    super(props);
    this.state = {
      error: false,
    };
  }

  /**
   * If tab is verified and able to continue.
   */
  private get verified(): boolean {
    return !!this.props.reason;
  }

  /**
   * Handles on continue event.
   */
  private onCont(): void {
    // Validate, display error if invalid.
    // Update parent state
    const valid = this.verified;
    this.props.updateVerified(valid);

    if (valid) {
      // Call continue
      this.props.onCont();
      this.setState({
        error: false,
      });
    } else {
      this.setState({
        error: true,
      });
    }
  }

  /**
   * Handler for reason change.
   */
  private onReasonChange(e: SyntheticEvent<HTMLInputElement>): void {
    this.props.updateReason(e.currentTarget.value);
  }

  /**
   * Error to display, if any.
   */
  private get error(): any {
    if (this.state.error) {
      return {
        i18nKey: 'agencies.join.reasonError',
      };
    }
    return false;
  }

  /**
   * Render function.
   */
  render(): React.ReactNode {
    return (
      <PaneWrapper
        onBack={this.props.onBack}
        onCont={this.onCont.bind(this)}
        canCont={this.verified}
        error={this.error}
      >
        <Form style={{ textAlign: 'right' }}>
          <Form.TextArea
            inline
            label={this.props.t('agencies.join.reasonQuestion')}
            value={this.props.reason}
            onChange={this.onReasonChange.bind(this)}
            style={{ /*resize: 'both',*/ width: '75%' }}
          />
        </Form>
      </PaneWrapper>
    );
  }
}

export default withContext(Reason, 'i18n');
