/** @module components.Agencies.Join.JoinRequest */

import * as React from 'react';
import {
  Accordion,
  Grid,
  Header,
  Icon,
  Input,
  Menu,
  Pagination,
  Segment,
} from 'semantic-ui-react';

import {
  ApiResult,
  PageResult,
  SearchAgency,
  AgencyGroup,
  AgencyGroupTypeEnum,
  MinimalDispatchGroup,
} from '@bryxinc/lunch/models';
import * as style from '@bryxinc/style/main.module.css';
import * as color from '@bryxinc/style/color';

import PaneWrapper from '../../PaneWrapper';
import Agency from './Agency';
import { withContext, WithTranslation, WithApi } from '@bryxinc/lunch/context';
import BryxApi from '@bryxinc/lunch/utils/AccountApi';

export interface SelectedAgency {
  agency: Partial<SearchAgency>;
  groups: Array<Partial<MinimalDispatchGroup>>;
}

interface SelectProps extends WithTranslation, WithApi<BryxApi> {
  selected: SelectedAgency | null;
  updateSelected: (a: SelectedAgency | null, v?: boolean) => void;
  updateVerified: (v: boolean) => void;
  onBack: () => void;
  onCont: () => void;
}

interface Selected {
  index: number;
  groups?: number[];
}

interface SelectState {
  ready: boolean;
  page: number;
  search: string;
  searchLoading: boolean;
  aCount: number;
  agencies: SearchAgency[];
  error?: { message: string, data?: any } | { i18nKey: string, data?: any } | false;
}

/**
 * Select agencies react component for Join Agency page.
 */
export class Select extends React.Component<SelectProps, SelectState> {
  static readonly PER_PAGE: number = 10;
  static readonly DEBOUNCE_TIME: number = 600;
  static readonly DEL_DEBOUNCE_TIME: number = 1000;
  private debounceTimer: number | null | any = null;
  readonly state: SelectState = {
    ready: true,
    page: 1,
    search: '',
    searchLoading: false,
    aCount: 0,
    agencies: [],
    error: false,
  };

  /**
   * If tab is verified and able to continue.
   */
  private get verified(): boolean {
    const { selected } = this.props;
    const { error } = this.state;
    // return selected.length > 0 && selected.every((s: any) => s.groups.length > 0);

    if (selected && selected.agency.name && !error) {
      return selected.groups.filter((g: Partial<MinimalDispatchGroup>) => !g.isMember && g.name ).length !== 0;
    }
    return false;
  }

  /**
   * Array of current agencies displayed.
   */
  private get agencies(): SearchAgency[] {
    const { search, agencies, page, searchLoading, error } = this.state;
    if (searchLoading) {
      console.log('Search loading.');
      return [];
    }
    if (error && (error as any).i18nKey !== 'agencies.join.alreadyMemberErr') {
      console.log('Error');
      return agencies;
    }
    if (!search && agencies.length == 0) {
      const { selected } = this.props;
      if (selected) {
        if (selected.agency.id && !selected.agency.name) {
          console.log('Requesting selected agency.');
          const { api } = this.props;
          this.setState({ searchLoading: true });
          this.props.api.getAgencies(
            Select.PER_PAGE,
            0,
            null,
            this.getAgenciesCB.bind(this),
            selected.agency.id,
          );
          return [];
        }
      }
    }
    return agencies;
  }

  /**
   * Count of current agencies displayed.
   */
  private get aCount(): number {
    const { search, aCount } = this.state;
    if (!search) {
      const { selected } = this.props;
      return selected ? 0 : 1;
    }
    return aCount;
  }

  /**
   * Get agencies callback.
   */
  private getAgenciesCB(r: ApiResult<PageResult<SearchAgency>>): void {
    if (!r.success) {
      this.setState({
        searchLoading: false,
        error: {
          i18nKey: 'agencies.manage.error', // Should be join, not manage, manage is for testing
          data: { message: r.message, debugMessage: r.debugMessage },
        },
      });
      return;
    }
    const agencies = (r as any).value.items;
    this.setState({
      aCount: ((r as any).value || {}).count || 0,
      agencies: agencies,
      searchLoading: false,
    });

    // Check if pre-selected agency
    const { selected, updateSelected } = this.props;
    if (selected && selected.agency.id && !selected.agency.name) {
      const a = agencies.find((o: SearchAgency) => o.id == selected.agency.id);
      if (a) {
        this.selectAgency(a);
      } else { // Error if pre-selected agency not found
        this.setState({
          error: {
            i18nKey: 'agencies.join.preselectedNotFound',
            data: { },
          },
        });
        updateSelected(null, false);
      }
    }
  }

  /**
   * Handle page change.
   */
  private onPageChange(
    e: React.SyntheticEvent,
    { activePage }: { activePage: number },
  ): void {
    const { search } = this.state;
    const { api } = this.props;
    this.setState({ page: activePage });
    this.updateAgencies(activePage - 1, search);
  }

  /**
   * Search change handler.
   */
  private updateSearch(): void {
    const { search, page } = this.state;
    this.updateAgencies(page - 1, search);
  }

  /**
   * Search change handler.
   * @param e Event
   * @param d Data
   */
  private onSearchChange(e: React.SyntheticEvent<HTMLInputElement>, d: any): void {
    const { search: lastSearch } = this.state;
    const search = e.currentTarget.value;
    const del = !search.includes(lastSearch);
    const debounceTime = del ? Select.DEL_DEBOUNCE_TIME : Select.DEBOUNCE_TIME;
    this.setState(
      { search, page: 1, searchLoading: true, error: false },
      () => {
        if (this.debounceTimer != null) {
          clearTimeout(this.debounceTimer);
        }
        this.debounceTimer = setTimeout(() => {
          this.updateSearch();
        }, debounceTime);
      },
    );
  }

  /**
   * Update agencies based on page and search.
   * Initiates call to API.
   * @TODO Setting agencies as select on no search is now unneeded.
   * @param page
   * @param search
   */
  private updateAgencies(page: number = 0, search: string = ''): void {
    if (!search) {
      const { selected } = this.props;
      this.setState({
        agencies: selected ? [selected.agency as SearchAgency] : [],
        aCount: selected ? 1 : 0,
        page: 1,
        searchLoading: false,
      });
      return;
    }
    const { api } = this.props;
    api.getAgencies(
      Select.PER_PAGE,
      page,
      search,
      this.getAgenciesCB.bind(this),
    );
  }

  /**
   * Handles on continue event.
   */
  private onCont(): void {
    // Validate, display error if invalid.
    // Update parent state
    const valid = this.verified;
    this.props.updateVerified(valid);

    if (valid) {
      // Call continue
      this.props.onCont();
    }
  }

  /**
   * Update selected and check if valid selection.
   * @param selected
   */
  private updateSelected(selected: SelectedAgency | null): void {
    const { updateSelected } = this.props;
    updateSelected(
      selected,
      selected ? selected.groups.length > 0 : false,
    );
  }

  /**
   * Select agency.
   * @param a Agency being selected
   */
  private selectAgency(a: SearchAgency): void {
    const {
      selected = { groups: [] },
      updateSelected,
    } = this.props;
    const { groups: selectedGroups = [] } = selected as any;
    if (a.memberOfAllGroups) { // KLUDGE
      this.setState({
        error: {
          i18nKey: 'agencies.join.alreadyMemberErr',
          data: { agency: a.name },
        },
      });
      return;
    }
    // If only one group, select it
    if (a.dispatchGroups && a.dispatchGroups.length == 1) {
      updateSelected(
        { agency: a, groups: [ ...a.dispatchGroups ] },
        true,
      );
      return;
    }
    updateSelected(
      {
        agency: a,
        groups: selectedGroups.map(
          (h: Partial<MinimalDispatchGroup>) => (a.dispatchGroups || []).find(
            (g: MinimalDispatchGroup) => h.id === g.id,
          ),
        ),
      },
      false,
    );
  }

  // /**
  //  * Select agency group.
  //  * @WARN Unused
  //  * @param g Group being selected
  //  */
  // private selectGroup(g: MinimalDispatchGroup): void {
  //   const { selected, updateSelected } = this.props;
  //   if (g.isMember) { // KLUDGE
  //     // this.setState({
  //     //   error: {
  //     //     i18nKey: 'agencies.join.alreadyGroupMemberErr',
  //     //     data: { agency: selected.agency.name, group: g.name },
  //     //   },
  //     // });
  //     return;
  //   }
  //
  //   const selectedI = selected.groups.findIndex(
  //     (h: MinimalDispatchGroup) => g.id === h.id,
  //   );
  //
  //   if (selectedI <= 0) {
  //     selected.groups.splice(
  //       selected.groups.length,
  //       0,
  //       g,
  //     );
  //   }
  //   updateSelected(
  //     selected,
  //     selected.groups.length > 0,
  //   );
  // }

  /**
   * Agency menu item click handler creator.
   * @param i Index of agency being clicked.
   * @return Agency menu item click handler.
   */
  private onAgencyClick(i: number): (e: React.SyntheticEvent, d: any) => void {
    return ((e: React.SyntheticEvent, d: any) => {
      const { selected, updateSelected } = this.props;
      const a = this.agencies[i];
      if (selected && selected.agency.id === a.id) {
        updateSelected(null, false);
        this.setState({ error: false });
        return;
      }
      if (a.memberOfAllGroups) { // KLUDGE
        return;
      }
      // If only one group, select it
      if (a.dispatchGroups && a.dispatchGroups.length == 1) {
        updateSelected(
          { agency: a, groups: [ ...a.dispatchGroups ] },
          true,
        );
        return;
      }
      updateSelected(
        { agency: a, groups: [] },
        false,
      );
    }).bind(this);
  }

  /**
   * Agency menu item click handler creator.
   * @param i Index of  agency of group being clicked.
   * @param j Index of group being clicked.
   * @return Agency group menu item click handler.
   */
  private onGroupClick(i: number, j: number): (e: React.SyntheticEvent, d: any) => void {
    return ((e: React.SyntheticEvent, d: any) => {
      const { selected, updateSelected } = this.props;
      const a = this.agencies[i];

      if (selected && selected.agency.id === a.id) {
        const g = (a.dispatchGroups || [])[j] || {};
        const selectedI = selected.groups.findIndex(
          (h: MinimalDispatchGroup) => g.id === h.id,
        );

        if (selectedI > -1) {
          selected.groups.splice(selectedI, 1); // Removes group
        } else {
          selected.groups.splice(
            selected.groups.length,
            0,
            g,
          );
        }
        updateSelected(
          selected,
          selected.groups.length > 0,
        );
      }
    }).bind(this);
  }

  /**
   * Get agency menu item nodes.
   * @return Array of agency menu item nodes.
   */
  private get agencyMenuItems(): React.ReactNode[] {
    const { selected } = this.props;
    return this.agencies.map((a: SearchAgency, i: number) => {
      const aSelected = selected ? a.id === selected.agency.id : false;
      return (
        <Agency
          key={i}
          agency={a}
          selected={aSelected}
          selectedGroups={aSelected ? [ ...(selected as SelectedAgency).groups ] : []}
          onAgencyClick={this.onAgencyClick(i)}
          onGroupClick={(a.dispatchGroups || []).map(
            (g: MinimalDispatchGroup, j: number) => this.onGroupClick(i, j),
          )}
        />
      );
    });
  }

  /**
   * Pagination react node.
   */
  private get pagination(): React.ReactNode {
    const { page } = this.state;
    const totalPages = Math.max(Math.ceil(this.aCount / Select.PER_PAGE), 1);
    return (
      <Pagination
        key='pagination'
        activePage={page}
        onPageChange={this.onPageChange.bind(this)}
        totalPages={totalPages}
        ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
        firstItem={{ content: <Icon name='angle double left' />, icon: true }}
        lastItem={{ content: <Icon name='angle double right' />, icon: true }}
        prevItem={{ content: <Icon name='angle left' />, icon: true }}
        nextItem={{ content: <Icon name='angle right' />}}
      />
    );
  }

  private get search(): React.ReactNode {
    const { t } = this.props;
    const { search, searchLoading, error } = this.state;
    return (
      <Input
        error={!!error && !searchLoading && (error as any).i18nKey !== 'agencies.join.alreadyMemberErr'}
        loading={searchLoading}
        onChange={this.onSearchChange.bind(this)}
        placeholder={t('agencies.join.searchAgencies.placeholder')}
      />
    );
  }

  /**
   * Agency list or alert giving instructions.
   */
  private get agencyList(): React.ReactNode {
    const { search, searchLoading, error, aCount } = this.state;
    const { selected, t } = this.props;
    if (!search && !searchLoading && !selected && aCount == 0) {
      return (
        <Grid.Column textAlign='center'>
          <Segment
            loading={searchLoading}
            placeholder
            style={{
              margin: '0 auto',
              minWidth: '33rem',
              width: '80%',
            }}
          >
            {t('agencies.join.searchAgencies.help')}
          </Segment>
        </Grid.Column>
      );
    }
    if (!!error && !selected) {
      return (
        <Grid.Column textAlign='center'>
          <Segment
            loading={searchLoading}
            placeholder
            style={{
              margin: '0 auto',
              minWidth: '33rem',
              width: '80%',
            }}
          >
            <Header>
              <Icon name='exclamation'/>
              {t('general.oops')}
            </Header>
            {t('general.wentWrong')}
          </Segment>
        </Grid.Column>
      );
    }
    return (
      <Grid.Column><Segment
        loading={searchLoading}
        style={{
          margin: '0 auto',
          minWidth: '33rem',
          width: '80%',
        }}
      ><Grid stackable verticalAlign='top' columns='1'>
        <Grid.Column textAlign='center'>{this.pagination}</Grid.Column>
        <Grid.Column verticalAlign='top'>
          <Accordion
            as={Menu}
            vertical
            style={{
              margin: '0 auto',
              minWidth: '15rem',
              width: '50%',
            }}
          >
            {this.agencyMenuItems}
          </Accordion>
        </Grid.Column>
        <Grid.Column textAlign='center'>{this.pagination}</Grid.Column>
      </Grid></Segment></Grid.Column>
    );
  }

  /**
   * Render function.
   */
  render(): React.ReactNode {
    return (
      <PaneWrapper
        onBack={this.props.onBack}
        onCont={this.onCont.bind(this)}
        canCont={this.verified}
        error={this.state.error || false}
      >
        <Grid stackable verticalAlign='top' columns='1' style={{ height: '100%' }}>
          <Grid.Column textAlign='center'>{this.search}</Grid.Column>
          {this.agencyList}
        </Grid>
      </PaneWrapper>
    );
    // style={{ height: '100%', minHeight: '400px' }} For map column
  }
}

export default withContext(Select, 'i18n', 'api');
