/** @module components.Agencies.Join.Submit */

import * as React from 'react';
import { SyntheticEvent } from 'react';
import { Grid, Button, Popup } from 'semantic-ui-react';

import { ApiResult } from '@bryxinc/lunch/models';
import * as style from '@bryxinc/style/main.module.css';
import * as color from '@bryxinc/style/color';

import PaneWrapper from '../PaneWrapper';
import { withContext, WithTranslation, WithApi, WithLocal } from '@bryxinc/lunch/context';
import { JoinRequestInfo } from '../JoinRequest';
import { SignUpInfo } from '../SignUp';
import JoinRequest from './JoinRequest';
import SignUp from './SignUp';
import BryxApi from '@bryxinc/lunch/utils/AccountApi';

interface SubmitProps extends WithTranslation, WithApi<BryxApi>, WithLocal {
  signUp?: boolean;
  onBack: () => void;
  onCancel: () => void;
  onCont: () => void;
  updateVerified: (v: boolean) => void;
  data: JoinRequestInfo | SignUpInfo;
}

interface SubmitState {
  error: { message: string, data?: any } | { i18nKey: string, data?: any } | false;
}

/**
 * Submit tab react component for Join Agency page.
 */
export class Submit extends React.Component<SubmitProps, SubmitState> {
  static readonly defaultProps: Partial<SubmitProps> = {
    signUp: false,
  };

  readonly state: SubmitState = {
    error: false,
  };

  /**
   * Callback function for submit request.
   * @param r Result
   */
  private submitCallback(r: ApiResult<null>): void {
    console.log(r);
    this.props.updateVerified(r.success);
    if (r.success) {
      this.props.onCont();
      this.setState({
        error: false,
      });
    } else {
      this.setState({
        error: {
          i18nKey: 'agencies.join.submitError', // Should be join, not manage, mange is for testing
          data: { message: r.message, debugMessage: r.debugMessage },
        },
      });
    }
  }

  /**
   * Callback function for multiple submit requests.
   * @param results Results
   */
  private createRequestsCB(results: Array<ApiResult<null>>): void {
    // console.log(results);
    for (const r of results) {
      console.log(r);
    }
    this.props.updateVerified(true);
    this.props.onCont();
  }

  /**
   * Callback function for submit request.
   * @param r Result
   */
  private createRequestCB(r: ApiResult<null>): void {
    console.log(r);
    this.props.updateVerified(r.success);
    if (r.success) {
      this.props.onCont();
      this.setState({
        error: false,
      });
    } else {
      this.setState({
        error: {
          i18nKey: 'agencies.join.submitError', // Should be join, not manage, mange is for testing
          data: { message: r.message, debugMessage: r.debugMessage },
        },
      });
    }
  }

  /**
   * Handler for reason change.
   */
  private onCancel(e: SyntheticEvent<HTMLInputElement>): void {
    console.log('Cancelling');
    this.props.onCancel();
  }

  /**
   * Handler for reason change.
   */
  private onSubmit(e: SyntheticEvent<HTMLInputElement>): void {
    console.log('Submitting');
    const { api, data } = this.props;
    if (!this.props.signUp) {
      // const createRequests = Object.values((data as JoinRequestProps).agencies).map(
      //   (a: any) => api.callAsync(
      //     'createJoinRequest',
      //     a.agency.id,
      //     a.groups.map((g: any) => g.id),
      //     (data as JoinRequestProps).reason,
      //   ),
      // );
      // Promise.all(createRequests).then(this.createRequestsCB.bind(this));

      const a = (data as JoinRequestInfo).agency;
      if (a) {
        api.createJoinRequest(
          a.agency.id as string,
          a.groups.map((g: any) => g.id),
          (data as JoinRequestInfo).reason,
          this.createRequestCB.bind(this),
        );
      }
    } else {
      const signUpData = (this.props.data as SignUpInfo);
      api.createSignUp(
        signUpData.agencyName,
        signUpData.agencyType,
        signUpData.country as any,
        signUpData.region || null,
        signUpData.zipcode || null,
        signUpData.products,
        signUpData.jobTitle || null,
        this.submitCallback.bind(this),
      );
    }

    // createJoinRequest(cb: (r: ApiResult<null>) => void)
    //
    // createLead(cb: (r: ApiResult<null>) => void)
  }

  /**
   * Title string.
   */
  private get title(): string {
    if (this.props.signUp) {
      return this.props.t('agencies.join.submitSignUp');
    }
    return this.props.t('agencies.join.submitJoinRequest');
  }

  // /**
  //  * Error to display, if any.
  //  */
  // private get error(): any {
  //   if (this.state.error) {
  //     return {
  //       i18nKey: 'agencies.join.submitError',
  //       data: { message: this.state.errMsg },
  //     };
  //   }
  //   return false;
  // }

  /**
   * Preview element for submission.
   */
  private get preview(): React.ReactNode {
    if (this.props.signUp) {
      return <SignUp {...(this.props.data as SignUpInfo)} />;
    }
    return <JoinRequest {...(this.props.data as JoinRequestInfo)} />;
  }

  /**
   * Render function.
   */
  render(): React.ReactNode {
    return (
      <PaneWrapper
        onBack={this.props.onBack}
        onCancel={this.onCancel.bind(this)}
        onCont={this.onSubmit.bind(this)}
        title={this.title}
        error={this.state.error}
        contText={this.props.t('general.submit')}
      >
        {this.preview}
      </PaneWrapper>
    );
  }
}

export default withContext(Submit, 'i18n', 'api', 'local');
