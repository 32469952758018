/** @module components.Account.SMS */

/** @module components */

import * as React from "react";
import { SyntheticEvent } from "react";
import { RouteComponentProps } from "react-router";
import {
  Button,
  Grid,
  Icon,
  Popup,
  Segment,
  Table,
  TableHeader,
  TableHeaderCell,
  TableBody,
  TableRow,
  TableCell,
} from "semantic-ui-react";
import classnames from "classnames";

import * as style from "@bryxinc/style/main.module.css";
import * as headerStyle from "@bryxinc/style/header.module.css";
import * as color from "@bryxinc/style/color";

import { ApiResult, EmailRegistration } from "@bryxinc/lunch/models";

import Page from "../../Page";
import {
  withContext,
  WithTranslation,
  WithLocal,
  WithApi,
} from "@bryxinc/lunch/context";
import NewDeviceForm from "./NewDeviceForm";
import BryxApi from "@bryxinc/lunch/utils/AccountApi";

interface EmailProps
  extends RouteComponentProps,
    WithTranslation,
    WithLocal,
    WithApi<BryxApi> {}

interface EmailState {
  devices: EmailRegistration[];
  newDevice: string;
  newDeviceValid: boolean | null;
  // toAdd: [string, string, string];
  // toAddValid: [boolean, boolean, boolean];
}

/**
 * Account SMS device management react component.
 * Relevant API endpoints:
 * - https://gitlab.bryx.com/bryx911/bryx911-spec/blob/master/Actions/CreateClientSMSRegistration.md
 * - https://gitlab.bryx.com/bryx911/bryx911-spec/blob/master/Actions/GetClientSMSRegistrations.md
 * - https://gitlab.bryx.com/bryx911/bryx911-spec/blob/master/Actions/RemoveClientSMSRegistration.md
 */
export class EmailAlerts extends React.Component<EmailProps, EmailState> {
  readonly state: EmailState = {
    devices: [],
    newDevice: "",
    newDeviceValid: null,
  };

  /**
   * Executes on component mounting.
   */
  componentDidMount(): void {
    const { api, local } = this.props;
    api.getEmailRegistrations(this.emailRegisCB.bind(this));
  }

  /**
   * Callback for getting SMS registrations.
   */
  private emailRegisCB(r: ApiResult<EmailRegistration[]>): void {
    // If success, else, display error.
    if (r.success) {
      this.setState({ devices: r.value });
    }
  }

  /**
   * Callback for creating SMS registration.
   */
  private createEmailRegisCB(r: ApiResult<EmailRegistration>): void {
    // CreateClientSMSRegistration.md
    if (r.success) {
      const { devices } = this.state;
      this.setState({
        devices: [...devices, r.value],
        newDevice: "", // { areaCode: '', prefix: '', lineNumber: '' },
        newDeviceValid: null, // { areaCode: null, prefix: null, lineNumber: null },
      });
    }
    // Should display error on failure.
  }

  /**
   * Get function to handle registration delete.
   * @param i Index of registration.
   * @return Function to handle delete.
   */
  private getOnDeleteReg(i: number): () => void {
    return () => {
      const { api, local } = this.props;
      const { id } = this.state.devices[i];
      const cb = (r: ApiResult<null>) => {
        // If error, display
        if (r.success) {
          const { devices } = this.state;
          devices.splice(i, 1);
          this.setState({ devices });
        }
      };
      // Do something to toggle registration in state and call API
      // Can't right now because model and api both have to be changed.
      // Display error if any.
      api.removeEmailRegistration(id, cb);
      return;
    };
  }

  /**
   * Handle new device change.
   */
  private onNewDeviceChange(
    e: React.SyntheticEvent,
    { value, valid }: { value: string; valid: boolean | null }
  ): void {
    this.setState({
      newDevice: value,
      newDeviceValid: valid,
    });
  }

  /**
   * Handle add new device click.
   */
  private onAddNewDevice(e: React.SyntheticEvent): void {
    const { api, local } = this.props;
    const { newDevice, newDeviceValid } = this.state;
    if (newDeviceValid == true) {
      api.createEmailRegistration(
        newDevice,
        this.createEmailRegisCB.bind(this)
      );
    }
  }

  /**
   * Icon with popup/popover for invalid input.
   * Null if valid.
   */
  private get invalidInputErr(): React.ReactNode {
    const { t } = this.props;
    const { newDeviceValid } = this.state;
    const icon = <Icon name="warning circle" color="red" circular inverted />;
    if (newDeviceValid === false) {
      return (
        <Popup
          basic
          header={t("account.emailAlerts.newDeviceInvalid.header")}
          content={t("account.emailAlerts.newDeviceInvalid.text")}
          trigger={icon}
        />
      );
    }
    return null;
  }

  /**
   * Table displaying devices.
   */
  private get deviceTable(): React.ReactNode {
    const { devices, newDevice, newDeviceValid } = this.state;
    const { t } = this.props;
    const rows = devices.map((d: EmailRegistration, i: number) => (
      <Table.Row key={i} collapsing>
        <Table.Cell collapsing>{d.email}</Table.Cell>
        <Table.Cell collapsing>
          <Button
            negative
            icon="trash alternate outline"
            onClick={this.getOnDeleteReg(i).bind(this)}
          />
        </Table.Cell>
      </Table.Row>
    ));

    return (
      <Table compact unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell collapsing>{t("general.email")}</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {...rows}
          <Table.Row>
            <Table.Cell collapsing>
              <NewDeviceForm
                value={newDevice}
                valid={newDeviceValid}
                onChange={this.onNewDeviceChange.bind(this)}
              />
              {this.invalidInputErr}
            </Table.Cell>
            <Table.Cell>
              <Button
                positive
                icon="add"
                onClick={this.onAddNewDevice.bind(this)}
              />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }

  /**
   * Render function.
   */
  render(): React.ReactNode {
    // width='14'
    // textAlign='left' style={{ maxWidth: '500px', minWidth: '450px', width: '25%' }}
    return (
      <Page
        title={this.props.t("account.emailAlerts.header")}
        descr={this.props.t("account.emailAlerts.text")}
      >
        <Segment compact style={{ marginRight: "auto", marginLeft: "auto" }}>
          {this.deviceTable}
        </Segment>
        <Segment compact style={{ marginRight: "auto", marginLeft: "auto" }}>
          <Table>
            <TableHeader>
              <TableHeaderCell>Provider</TableHeaderCell>
              <TableHeaderCell>Email-to-SMS Address Format</TableHeaderCell>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableCell>AT&T</TableCell>
                <TableCell>number@txt.att.net</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Boot Mobile</TableCell>
                <TableCell>number@smsmyboostmobile.com</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Cricket</TableCell>
                <TableCell>number@sms.cricketwireless.net</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>FirstNet by AT&T</TableCell>
                <TableCell>number@sms.firstnet.com</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Spectrum Mobile</TableCell>
                <TableCell>number@vtext.com</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Sprint</TableCell>
                <TableCell>number@messaging.sprintpcs.com</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>T-Mobile</TableCell>
                <TableCell>number@tmomail.net</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>U.S. Cellular</TableCell>
                <TableCell>number@email.uscc.net</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Verizon</TableCell>
                <TableCell>number@vtext.com</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Segment>
      </Page>
    );
  }
}

export default withContext(EmailAlerts, "i18n", "local", "api");
