/** @module components.input */

import * as React from 'react';
import { FormEvent, SyntheticEvent } from 'react';
import { Form, Input } from 'semantic-ui-react';

// import * as style from '@bryxinc/style/main.module.css';

import { withContext, WithTranslation } from '@bryxinc/lunch/context';

interface ClientJobTitleProps extends WithTranslation {
  value: string;
  onChange?: (n: string, v?: boolean | null) => void;
  labels?: boolean;
  placeholders?: boolean;
  required?: boolean;
  disabled?: boolean;
  inline?: boolean;
  style?: object;
  className?: string;
}

interface ClientJobTitleState {
  focus: boolean | null;
}

/**
 * Agency name input component.
 */
export class ClientJobTitle extends React.Component<ClientJobTitleProps, ClientJobTitleState> {
  static defaultProps: Partial<ClientJobTitleProps> = {
    value: '',
    onChange: (n: string, v?: boolean | null) => { return; },
    labels: false,
    placeholders: false,
    required: false,
    disabled: false,
    inline: false,
    style: {},
    className: '',
  };
  readonly state: ClientJobTitleState = { focus: null };

  /**
   * Handler for email change.
   */
  private onChange(
    e: SyntheticEvent<HTMLElement>,
    { value }: { value: string },
  ): void {
    const { onChange, required } = this.props as Required<ClientJobTitleProps>;
    onChange(
      value,
      required || value.length > 0,
    );
  }

  /**
   * Handle focus.
   */
  private onFocus(e: SyntheticEvent): void {
    this.setState({
      focus: !this.state.focus,
    });
  }

  /**
   * Get input props.
   */
  get inputProps(): any {
    const {
      value, t, tReady,
      required, disabled, labels, inline, placeholders,
      style: compStyle, className,
    } = this.props;
    const { focus } = this.state;
    const p: any = {
      key: 'job-title-input',
      loading: !tReady,
      value: value || '',
      onChange: this.onChange.bind(this),
      onFocus: this.onFocus.bind(this),
      onBlur: this.onFocus.bind(this),
      inline,
      required,
      disabled,
      style: compStyle,
      className,
    };
    if (!value && focus === false && required) {
      p.error = { content: t('form.fieldRequired') };
    }
    if (labels) {
      p.label = t('form.label.jobTitle');
    }
    if (placeholders) {
      p.placeholder = t('form.label.jobTitle');
    }
    return p;
  }

  /**
   * Render function.
   */
  render(): React.ReactNode {
    return <Form.Input {...this.inputProps} />;
  }
}

export default withContext(ClientJobTitle, 'i18n');
