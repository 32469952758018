/** @module components.Agencies.Join.SignUp */

import * as React from 'react';
import { FormEvent, SyntheticEvent } from 'react';
import { Dropdown, Form, Grid, Input, Popup, Select } from 'semantic-ui-react';

import CountryRegion, { CRState } from '../../../input/CountryRegion';

import {
  AgencyTypeKind,
  CountryInfo,
  LeadInfo,
  ProductTypeKind,
  RegionInfo,
} from '@bryxinc/lunch/models';
import * as style from '@bryxinc/style/main.module.css';
import * as color from '@bryxinc/style/color';

import PaneWrapper from '../PaneWrapper';
import { withContext, WithTranslation } from '@bryxinc/lunch/context';

export type SignUpInfo = Omit<LeadInfo, 'country' | 'region'> & CRState; // { country: CountryInfo | string | null, region: RegionInfo | string | null };

interface InfoProps extends WithTranslation {
  updateLead: (d: Partial<SignUpInfo>, v?: boolean) => void;
  onBack: () => void;
  onCont: () => void;
  updateVerified: (v: boolean) => void;
}

interface InfoState extends CRState {
  name: string;
  jobTitle: string;
  type: AgencyTypeKind[];
  // country: CountryInfo | string | null;
  // region: RegionInfo | string | null;
  zipcode: string;
  products: ProductTypeKind[];
  error?: { message: string, data?: any } | { i18nKey: string, data?: any } | false;
}

/**
 * Agency sign up info form react component for Join Agency page.
 */
export class Info extends React.Component<InfoProps, InfoState> {
  static readonly displayName: string = 'SignUp';
  readonly state: InfoState = {
    name: '',
    jobTitle: '',
    type: [],
    zipcode: '',
    products: [],
  };

  /**
   * If tab is verified and able to continue.
   */
  private get verified(): boolean {
    const { name, country, noRegions, region, zipcode, products } = this.state;
    if (!name || !country || (!region && !noRegions) || (zipcode && !zipcode.match(/^\d{5}$/)) || products.length === 0) {
      return false;
    }

    return true;
  }

  /**
   * Handles on continue event.
   */
  private onCont(): void {
    // Validate, display error if invalid.
    // Update parent state
    const valid = this.verified;
    this.props.updateVerified(valid);

    if (valid) {
      // Call continue
      this.props.onCont();
    }
  }

  /**
   * Getter for product options.
   */
  private get productOpts(): object[] {
    const { tReady, t } = this.props;
    if (!tReady) {
      return [];
    }
    return [
      { key: ProductTypeKind.bryx911, value: ProductTypeKind.bryx911, text: t('branding.product.bryx911') },
      { key: ProductTypeKind.bryxStation, value: ProductTypeKind.bryxStation, text: t('branding.product.bryxStation') },
      { key: ProductTypeKind.bryxHospital, value: ProductTypeKind.bryxHospital, text: t('branding.product.bryxHospital') },
    ];
  }

  /**
   * Getter for product options.
   */
  private get agencyTypeOpts(): object[] {
    const { tReady, t } = this.props;
    if (!tReady) {
      return [];
    }
    return [
      { key: AgencyTypeKind.fire, value: AgencyTypeKind.fire, text: t('agencies.signUp.agencyType.fire') },
      { key: AgencyTypeKind.ems, value: AgencyTypeKind.ems, text: t('agencies.signUp.agencyType.ems') },
      { key: AgencyTypeKind.police, value: AgencyTypeKind.police, text: t('agencies.signUp.agencyType.police') },
      { key: AgencyTypeKind.psap, value: AgencyTypeKind.psap, text: t('agencies.signUp.agencyType.psap') },
      { key: AgencyTypeKind.security, value: AgencyTypeKind.security, text: t('agencies.signUp.agencyType.security') },
      { key: AgencyTypeKind.military, value: AgencyTypeKind.military, text: t('agencies.signUp.agencyType.military') },
      { key: AgencyTypeKind.publicWorks, value: AgencyTypeKind.publicWorks, text: t('agencies.signUp.agencyType.publicWorks') },
      { key: AgencyTypeKind.other, value: AgencyTypeKind.other, text: t('agencies.signUp.agencyType.other') },
    ];
  }

  /**
   * Handler for name change.
   */
  private onNameChange(e: SyntheticEvent<HTMLInputElement>): void {
    this.setState({ name: e.currentTarget.value });
    this.props.updateLead({ agencyName: e.currentTarget.value }, this.verified);
  }

  /**
   * Handler for zipcode change.
   */
  private onZipcodeChange(e: SyntheticEvent<HTMLInputElement>): void {
    this.setState({ zipcode: e.currentTarget.value });
    this.props.updateLead({ zipcode: e.currentTarget.value });
  }

  /**
   * Handler for product change.
   */
  private onProductChange(
    e: SyntheticEvent<HTMLElement>,
    { value }: { value: ProductTypeKind[] },
  ): void {
    this.setState({ products: value });
    this.props.updateLead({ products: value }, this.verified);
    // this.props.updateLead({ state: value });
  }

  /**
   * Handler for agency type change.
   */
  private onAgencyTypeChange(
    e: SyntheticEvent<HTMLElement>,
    { value }: { value: AgencyTypeKind[] },
  ): void {
    this.setState({ type: value });
    this.props.updateLead({ agencyType: value });
    // this.props.updateLead({ state: value });
  }

  /**
   * Handler for job title change.
   */
  private onJobTitleChange(e: SyntheticEvent<HTMLInputElement>): void {
    this.setState({ jobTitle: e.currentTarget.value });
    this.props.updateLead({ jobTitle: e.currentTarget.value });
  }

  /**
   * Handler for country/region change.
   */
  private onCountryRegionChange(v: Partial<CRState>): void {
    this.setState(v as CRState);
    this.props.updateLead(v, this.verified);
  }

  /**
   * Handler for error from country/region API.
   */
  private onAPIError(e: { message: string, data?: any } | { i18nKey: string, data?: any } | false): void {
    this.setState({ error: e});
  }

  /**
   * Render function.
   */
  render(): React.ReactNode {
    return (
      <PaneWrapper
        onBack={this.props.onBack}
        onCont={this.onCont.bind(this)}
        canCont={this.verified}
        error={this.state.error || false}
      >
        <Grid>
          <Grid.Row centered>
            <Grid.Column textAlign='center' style={{ width: 'fit-content' }}>
              <Form
                style={{ textAlign: 'right', width: 'fit-content' }}
                autocomplete='off'
              >
                <Form.Input
                  inline
                  required
                  label={this.props.t('agencies.signUp.name')}
                  value={this.state.name}
                  onChange={this.onNameChange.bind(this)}
                  style={{ width: '14em' }}
                />
                <Form.Select
                  inline
                  required
                  multiple
                  label={this.props.t('agencies.signUp.agencyType.label')}
                  options={this.agencyTypeOpts}
                  value={this.state.type}
                  onChange={this.onAgencyTypeChange.bind(this)}
                  style={{ width: '14em' }}
                />
                <CountryRegion
                  onChange={this.onCountryRegionChange.bind(this)}
                  onError={this.onAPIError.bind(this)}
                  country={this.state.country}
                  region={this.state.region}
                  noRegions={this.state.noRegions}
                  required
                  labels
                  inline
                  style={{ width: '14em' }}
                />
                <Form.Input
                  inline
                  label={this.props.t('agencies.signUp.zipcode')}
                  value={this.state.zipcode}
                  onChange={this.onZipcodeChange.bind(this)}
                  style={{ width: '14em' }}
                  autoComplete='no'
                />
                <Form.Select
                  inline
                  required
                  multiple
                  label={this.props.t('agencies.signUp.product')}
                  options={this.productOpts}
                  value={this.state.products}
                  onChange={this.onProductChange.bind(this)}
                  style={{ width: '14em' }}
                />
                <Form.Input
                  inline
                  label={this.props.t('agencies.signUp.jobTitle')}
                  value={this.state.jobTitle}
                  onChange={this.onJobTitleChange.bind(this)}
                  style={{ width: '14em' }}
                />
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </PaneWrapper>
    );
  }
}

export default withContext(Info, 'i18n');
