/** @module components */

import * as React from 'react';

import {
  Card,
  Icon,
} from 'semantic-ui-react';

import classnames from 'classnames';

import * as style from '@bryxinc/style/main.module.css';
import * as cardStyle from '@bryxinc/style/card.module.css';
import * as headerStyle from '@bryxinc/style/header.module.css';
import * as color from '@bryxinc/style/color';

import { withContext, WithTranslation } from '@bryxinc/lunch/context';

interface IconOptions {
  name: string;
  corner?: 'top left' | 'top right' | 'bottom left' | 'bottom right';
  size?: string;
  style?: any;
}
interface BryxCardProps extends WithTranslation {
  onClick?: (e: React.SyntheticEvent) => void;
  header: string;
  description?: string;
  href?: string;
  icon: React.ReactNode | IconOptions;

  headerKey?: string;
  descrKey?: string;
}

/**
 * Recursive helper function to create icon react element.
 * @param opt Options
 * @param key Key
 * @return React node
 */
function makeIcon(
  opt: IconOptions | IconOptions[],
  key: string = 'iconroot',
): React.ReactNode {
  if (Array.isArray(opt)) {
    const icons: any = opt.map(
      (o: IconOptions, i: number) => makeIcon(o, `subicon${i}`),
    );
    return (
      <Icon.Group
        className={classnames(cardStyle.cardIcon)}
        size={key === 'iconroot' ? 'massive' : undefined}
        key={key}
      >
        {...icons}
      </Icon.Group>
    );
  }
  return (
    <Icon
      className={classnames(cardStyle.cardIcon)}
      size={key === 'iconroot' ? 'massive' : undefined}
      key={key}
      {...(opt as any)}
    />
  );
}

/**
 * Bryx card component for dashboards.
 */
export class BryxCard extends React.Component<BryxCardProps> {
  static Group: typeof Card.Group = Card.Group;
  /**
   * Header string
   */
  private get header(): string {
    const { header: text, headerKey: key, t } = this.props;
    if (key) {
      return t(key);
    }
    return text as string;
  }

  /**
   * Description string
   */
  private get descr(): string {
    const { description: text, descrKey: key, t } = this.props;
    if (key) {
      return t(key);
    }
    return text as string;
  }

  /**
   * Icon react node.
   */
  private get icon(): React.ReactNode {
    const { icon } = this.props;
    if (React.isValidElement(icon)) {
      return icon;
    }
    return makeIcon(icon as IconOptions);
  }

  /**
   * Description react node.
   */
  private get description(): React.ReactNode {
    return (
      <React.Fragment>
        <p style={{ textAlign: 'center' }}>
          {this.icon}
        </p>
        <p>{this.descr}</p>
      </React.Fragment>
    );
  }

  /**
   * Card props.
   */
  private get cardProps(): object {
    const cardProps: any = {
      className: classnames(cardStyle.bryxCard),
      description: this.description,
      header: {
        content: this.header,
        className: classnames(headerStyle.bryxHeader, cardStyle.cardHeader),
      },
    };
    const { href, onClick } = this.props;
    if ( href ) {
      cardProps.href = href;
    }
    if ( onClick ) {
      cardProps.onClick = onClick;
    }
    return cardProps;
  }

  /**
   * Render function.
   */
  render(): React.ReactNode {
    return <Card {...this.cardProps} />;
  }
}

const TranslatedCard: any = withContext(BryxCard, 'i18n');
TranslatedCard.Group = Card.Group;
export default TranslatedCard;
