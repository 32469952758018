/** @module components.SignIn */

import * as React from 'react';
import SVG from 'react-inlinesvg';
import logoSvg from '@bryxinc/style/Bryx-Logo.svg';
import * as style from '@bryxinc/style/signin.module.css';

/**
 * Loading spinner component.
 */
export default class Logo extends React.Component<{}> {

  /**
   * Static function to preprocess inline SVGs.
   */
  static svgPreProcessor(code: string): string {
    return code.replace(/<!--(((?!-->)|\n).)*-->/gm, '')
        .replace(/<defs>(((?!<\/defs>)|\n).)*<\/defs>/gm, '')
        .replace(
      /(class|id)="([a-zA-Z]*?)"/g,
      (m: string, p1: string, p2: string) => {
        const sty = (style as { [k: string]: any })[p2];
        if (!sty) {
          return '';
        }
        const next = `${p1}="${sty}"`;
        return next;
      },
    );
  }

  /**
   * Render function.
   */
  render(): React.ReactNode {
    return (
      <SVG
        src={logoSvg}
        preProcessor={Logo.svgPreProcessor}
        id={style.SigninLogo}
      />
    );
  }
}
