/** @module components.SignIn */

import * as React from 'react';
import { SyntheticEvent, KeyboardEvent } from 'react';
import { RouteComponentProps } from 'react-router';
import { Form, Input } from 'semantic-ui-react';
import classnames from 'classnames';

import * as style from '@bryxinc/style/main.module.css';
import * as headerStyle from '@bryxinc/style/header.module.css';
import * as color from '@bryxinc/style/color';

import { withContext, WithTranslation } from '@bryxinc/lunch/context';

export interface PhoneInputValue {
  areaCode: string;
  prefix: string;
  lineNumber: string;
}

export interface PhoneInputValid {
  areaCode: boolean | null;
  prefix: boolean | null;
  lineNumber: boolean | null;
}

interface PhoneInputProps extends WithTranslation {
  value: PhoneInputValue;
  valid: PhoneInputValid;
  onChange: (
    e: React.SyntheticEvent,
    d: { value: Partial<PhoneInputValue>, valid: Partial<PhoneInputValid> },
  ) => void;
  transparent?: boolean;
  disabled?: boolean;
}

interface PhoneInputState {
  areaCodeFocus: boolean;
  prefixFocus: boolean;
  lineNumberFocus: boolean;
}

/**
 * Collection of input fields to collect phone numbers.
 * State is ultimately handled by parent component.
 */
export class PhoneInput extends React.Component<PhoneInputProps> {
  static readonly defaultProps: Partial<PhoneInputProps> = {
    transparent: false,
    disabled: false,
  };
  areaCodeRef: any = React.createRef();
  prefixRef: any = React.createRef();
  lineNumberRef: any = React.createRef();
  readonly state: PhoneInputState = {
    areaCodeFocus: false,
    prefixFocus: false,
    lineNumberFocus: false,
  };

  /**
   * Handle area code focus.
   */
  private onAreaCodeFocus(e: SyntheticEvent): void {
    this.setState({
      areaCodeFocus: !this.state.areaCodeFocus,
    });
  }

  /**
   * Handle area code change.
   */
  private onAreaCodeChange(e: SyntheticEvent, { value }: { value: string }): void {
    if (value.length <= 3) {
      const { onChange } = this.props;
      onChange(
        e,
        {
          value: {
            areaCode: value,
          },
          valid: {
            areaCode: !!value.match(/\d{3}/),
          },
        },
      );
    }
  }

  /**
   * Handle prefix key down.
   */
  private onAreaCodeKeyDown(e: KeyboardEvent): void {
    const selStart = (e.target as any).selectionStart;
    const selEnd = (e.target as any).selectionEnd;
    const key = e.key;
    const keyCode = e.keyCode;

    if (selStart == selEnd) {
      if (selStart == 3) {
        if (key.length == 1) { // If key generates text
          this.onPrefixChange(
            e,
            { value: key },
          );
        }
        if (keyCode == 39 || key.length == 1) { // ArrowRight or generates text
          this.prefixRef.current.focus();
          e.preventDefault();
        }
      }
    }
  }

  /**
   * Handle prefix focus.
   */
  private onPrefixFocus(e: SyntheticEvent): void {
    this.setState({
      prefixFocus: !this.state.prefixFocus,
    });
  }

  /**
   * Handle prefix change.
   */
  private onPrefixChange(e: SyntheticEvent, { value }: { value: string }): void {
    if (value.length <= 3) {
      const { onChange } = this.props;
      onChange(
        e,
        {
          value: {
            prefix: value,
          },
          valid: {
            prefix: !!value.match(/\d{3}/),
          },
        },
      );
    }
  }

  /**
   * Handle prefix key down.
   */
  private onPrefixKeyDown(e: KeyboardEvent): void {
    const selStart = (e.target as any).selectionStart;
    const selEnd = (e.target as any).selectionEnd;
    const key = e.key;
    const keyCode = e.keyCode;

    if (selStart == selEnd) {
      if (selStart == 0) {
        console.log(keyCode);
        switch (keyCode) {
          case 8: // Backspace
            this.onAreaCodeChange(
              e,
              { value: this.props.value.areaCode.substring(0, 2) },
            );
          case 37: // ArrowLeft
            this.areaCodeRef.current.focus();
            e.preventDefault();
        }
      }
      if (selStart == 3) {
        if (key.length == 1) { // If key generates text
          this.onLineNumberChange(
            e,
            { value: key },
          );
        }
        if (keyCode == 39 || key.length == 1) { // ArrowRight or generates text
          this.lineNumberRef.current.focus();
          e.preventDefault();
        }
      }
    }
  }

  /**
   * Handle line number focus.
   */
  private onLineNumberFocus(e: SyntheticEvent): void {
    this.setState({
      lineNumberFocus: !this.state.lineNumberFocus,
    });
  }

  /**
   * Handle line number change.
   */
  private onLineNumberChange(e: SyntheticEvent, { value }: { value: string }): void {
    if (value.length <= 4) {
      const { onChange } = this.props;
      onChange(
        e,
        {
          value: {
            lineNumber: value,
          },
          valid: {
            lineNumber: !!value.match(/\d{4}/),
          },
        },
      );
    }
  }

  /**
   * Handle prefix key down.
   */
  private onLineNumberKeyDown(e: KeyboardEvent): void {
    const selStart = (e.target as any).selectionStart;
    const selEnd = (e.target as any).selectionEnd;
    const keyCode = e.keyCode;

    if (selStart == selEnd) {
      if (selStart == 0) {
        switch (keyCode) {
          case 8: // Backspace
            this.onPrefixChange(
              e,
              { value: this.props.value.areaCode.substring(0, 2) },
            );
          case 37: // ArrowLeft
            this.prefixRef.current.focus();
            e.preventDefault();
        }
      }
    }
  }

  /**
   * Area code input
   */
  private get areaCodeInput(): React.ReactNode {
    const { t, value, valid, transparent, disabled } = this.props;
    // const hasError = valid.areaCode == false && !this.state.areaCodeFocus;
    return (
      <Input
        style={{ width: '30%', paddingRight: '0.5em' }}
        transparent={transparent}
        disabled={disabled}
        ref={this.areaCodeRef}
        placeholder={t('placeholder.phoneNumber.areaCode')}
        value={value.areaCode}
        error={valid.areaCode == false}
        onChange={this.onAreaCodeChange.bind(this)}
        onKeyDown={this.onAreaCodeKeyDown.bind(this)}
        onFocus={this.onAreaCodeFocus.bind(this)}
        onBlur={this.onAreaCodeFocus.bind(this)}
      />
    );
  }

  /**
   * Area code input
   */
  private get prefixInput(): React.ReactNode {
    const { t, value, valid, transparent, disabled } = this.props;
    return (
      <Input
        style={{ width: '30%', paddingRight: '0.5em' }}
        transparent={transparent}
        disabled={disabled}
        ref={this.prefixRef}
        placeholder={t('placeholder.phoneNumber.prefix')}
        value={value.prefix}
        error={valid.prefix == false}
        onChange={this.onPrefixChange.bind(this)}
        onKeyDown={this.onPrefixKeyDown.bind(this)}
        onFocus={this.onPrefixFocus.bind(this)}
        onBlur={this.onPrefixFocus.bind(this)}
      />
    );
  }

  /**
   * Area code input
   */
  private get lineNumberInput(): React.ReactNode {
    const { t, value, valid, transparent, disabled } = this.props;
    return (
      <Input
        style={{ width: '40%', paddingRight: '0' }}
        transparent={transparent}
        disabled={disabled}
        ref={this.lineNumberRef}
        placeholder={t('placeholder.phoneNumber.lineNumber')}
        value={value.lineNumber}
        error={valid.lineNumber == false}
        onChange={this.onLineNumberChange.bind(this)}
        onKeyDown={this.onLineNumberKeyDown.bind(this)}
        onFocus={this.onLineNumberFocus.bind(this)}
        onBlur={this.onLineNumberFocus.bind(this)}
      />
    );
  }

  /**
   * Render function.
   */
  render(): React.ReactNode {
    return (
      <React.Fragment>
        {this.areaCodeInput}
        {this.prefixInput}
        {this.lineNumberInput}
      </React.Fragment>
    );
  }
}

export default withContext(PhoneInput, 'i18n');
