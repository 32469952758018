/** @module components.input */

import * as React from 'react';
import { FormEvent, SyntheticEvent } from 'react';
import { Dropdown, Form, Grid, Input, Popup, Select } from 'semantic-ui-react';

import {
  ApiResult,
  CountryInfo,
  LeadInfo,
  ProductTypeKind,
  RegionInfo,
  USStateKind,
} from '@bryxinc/lunch/models';
import {WithTranslation} from 'react-i18next';
import {WithApi, withContext} from '@bryxinc/lunch/context';
import BryxApi from '@bryxinc/lunch/utils/AccountApi';

export interface CRState {
  // country: string; // CountryInfo | { code: '', name?: string };
  // region: string;
  country?: CountryInfo;
  region?: RegionInfo | null;
  noRegions?: boolean;
}
interface CountryRegionProps extends WithTranslation, WithApi<BryxApi>, CRState {
  onChange: (n: Partial<CRState>, v?: boolean | null) => void;
  onError: (e: { message: string, data?: any } | { i18nKey: string, data?: any } | false) => void;
  labels?: boolean;
  placeholders?: boolean;
  required?: boolean;
  disabled?: boolean;
  inline?: boolean;
  unstackable?: boolean;
  style?: object;
  className?: string;
}
interface CountryRegionState {
  countriesReady: boolean;
  regionsReady: boolean;
  countries: CountryInfo[];
  regions: RegionInfo[];
  countryOpts: any[];
  regionOpts: any[];
  error?: { message: string, data?: any } | { i18nKey: string, data?: any } | false;
}

/**
 * Agency sign up basic info form react component for Join Agency page.
 * @TODO Handle and display errors better.
 */
export class CountryRegion extends React.Component<CountryRegionProps, CountryRegionState> {
  static defaultProps: Partial<CountryRegionProps> = {
    onChange: (n: Partial<CRState>, v?: boolean | null) => { return; },
    onError: (e: { message: string, data?: any } | { i18nKey: string, data?: any } | false) => { return; },
    labels: false,
    placeholders: false,
    required: false,
    disabled: false,
    inline: false,
    unstackable: false,
    style: {},
    className: '',
  };

  readonly state: CountryRegionState = {
    countriesReady: false,
    regionsReady: false,
    countries: [],
    regions: [],
    countryOpts: [],
    regionOpts: [],
  };

  /**
   * Should compoent update.
   * For optimization.
   */
  shouldComponentUpdate(
    nextProps: CountryRegionProps,
    nextState: CountryRegionState,
  ): boolean {
    // Check props
    const { country = null, region = null, noRegions = true } = this.props;
    const { country: nextCountry = null, region: nextRegion = null, noRegions: nextNoRegions = true } = nextProps;
    if (
      noRegions !== nextNoRegions ||
      (country === null && nextCountry !== null) ||
      (region === null && nextRegion !== null) ||
      (region !== null && nextRegion === null) ||
      (country !== null && nextCountry !== null && (country as CountryInfo).code !== (nextCountry as CountryInfo).code) ||
      (region !== null && nextRegion !== null && (region as RegionInfo).code !== (nextRegion as RegionInfo).code)
    ) {
      return true;
    }

    // Check state
    const { countriesReady, regionsReady } = this.state;
    const { countriesReady: nextCountriesReady, regionsReady: nextRegionsReady } = nextState;
    if (
      countriesReady !== nextCountriesReady ||
      regionsReady !== nextRegionsReady
    ) {
      return true;
    }

    return false;
  }

  /**
   * If input is valid.
   */
  private get valid(): boolean {
    const { country, region, noRegions, required } = this.props;
    if (required && (!country || (!region && !noRegions))) {
      return false;
    }
    return true;
  }

  /**
   * Handles result from API call.
   */
  private getCountriesCB(r: ApiResult<CountryInfo[]>): void {
    const { onError, t } = this.props as Required<CountryRegionProps>;
    if (!r.success) {
      onError({
        i18nKey: 'general.error',
        data: { message: r.message, debugMessage: r.debugMessage },
      });
      return;
    }
    onError(false);
    this.setState({
      countries: r.value,
      countriesReady: true,
      countryOpts: r.value.map((c: any) => ({
        key: c.code,
        value: c.code,
        text: t('location.country', { country: c }),
      })),
    });
  }

  /**
   * Handles result from API call.
   */
  private getRegionsCB(r: ApiResult<RegionInfo[]>): void {
    const { onError, onChange, t } = this.props as Required<CountryRegionProps>;
    if (!r.success) {
      onError({
        i18nKey: 'general.error', // Should be join, not manage, manage is for testing
        data: { message: r.message, debugMessage: r.debugMessage },
      });
      return;
    }
    onError(false);
    this.setState({
      regions: r.value,
      regionsReady: true,
      regionOpts: r.value.map((v: any) => ({
        key: v.code,
        value: v.code,
        text: t('location.region', { country: this.props.country, region: v }),
      })),
    });
    if (r.value.length < 1) {
      onChange({ noRegions: true }, false);
    } else {
      onChange({ noRegions: false }, true);
    }
  }

  /**
   * Handler for country change.
   */
  private onCountryChange(
    e: SyntheticEvent<HTMLElement>,
    { value }: { value: string },
  ): void {
    this.setState({
      regionsReady: false,
      regions: [],
    });
    const { api, onChange, required } = this.props as Required<CountryRegionProps>;
    const { countries } = this.state;
    const country = countries.find((c: CountryInfo) => c.code === value);
    if (country) {
      api.getCountryRegions(country.code, this.getRegionsCB.bind(this));
      // console.log(countries, regions, regions[value]);
      onChange({ country: country, region: null }, true);
    } else {
      onChange({ country: undefined, region: null }, !required);
    }
  }

  /**
   * Handler for region change.
   */
  private onRegionChange(
    e: SyntheticEvent<HTMLElement>,
    { value }: { value: string },
  ): void {
    const { onChange, country, required } = this.props as Required<CountryRegionProps>;
    const { regions } = this.state;
    const region = regions.find((r: RegionInfo) => r.code === value);
    if (region) {
      onChange({ country: country, region: region }, true);
    } else {
      onChange({ country: country, region: null }, null);
    }
  }

  /**
   * Get region options.
   */
  get regionOpts(): object[] {
    const { api, country, tReady, t } = this.props;
    const { regionsReady, regions } = this.state;
    if ( !tReady || !country ) {
      return [];
    }
    if (!regionsReady) {
      api.getCountryRegions(country.code, this.getRegionsCB.bind(this));
      return [];
    }
    return this.state.regionOpts;
  }

  /**
   * Get country options.
   */
  get countryOpts(): object[] {
    const { api, tReady, t } = this.props;
    const { countriesReady, countries } = this.state;
    if (!tReady) {
      return [];
    }
    if (!countriesReady) {
      api.getCountries(this.getCountriesCB.bind(this));
      return [];
    }
    return this.state.countryOpts;
  }

  /**
   * Get if region enabled.
   */
  get regionEnabled(): boolean {
    const { country, noRegions } = this.props;
    const { regionsReady, regions } = this.state;
    return (!!country && regionsReady && !noRegions); // && this.regionOpts.length > 1);
  }

  /**
   * Get country select props.
   */
  get countrySelectProps(): any {
    const {
      country, t, tReady,
      required, disabled, inline, labels, placeholders,
      style: compStyle, className,
    } = this.props;
    const p: any = {
      key: 'country-select',
      loading: !tReady,
      search: true,
      inline,
      value: country ? country.code : '',
      options: this.countryOpts,
      onChange: this.onCountryChange.bind(this),
      required,
      disabled,
      style: compStyle,
      className,
    };
    if (labels) {
      p.label = t('form.label.country');
    }
    if (placeholders) {
      p.placeholder = t('form.label.country');
    }
    return p;
  }

  /**
   * Get region select props.
   */
  get regionSelectProps(): any {
    const {
      country, region, t, tReady,
      required, disabled, inline, labels, placeholders,
      style: compStyle, className,
    } = this.props;
    const p: any = {
      key: 'region-select',
      loading: !tReady,
      search: true,
      inline,
      value: region ? region.code : '',
      options: this.regionOpts,
      onChange: this.onRegionChange.bind(this),
      required,
      disabled,
      style: compStyle,
      className,
    };
    if (labels) {
      p.label = t('form.label.region');
    }
    if (placeholders) {
      p.placeholder = t('form.label.region');
    }
    return p;
  }

  /**
   * Form group props.
   */
  get groupProps(): any {
    const {
      tReady, unstackable,
      disabled, inline,
      style: compStyle, className,
    } = this.props;
    const p: any = {
      key: 'location-group',
      widths: 'equal',
      loading: !tReady,
      inline,
      unstackable,
      disabled,
      style: compStyle,
      className,
    };
    return p;
  }

  /**
   * Render function.
   */
  render(): React.ReactNode {
    const elements = [
      (
        <Form.Select {...this.countrySelectProps} />
      ),
    ];
    if (this.regionEnabled) {
      elements.push(
        (
          <Form.Select {...this.regionSelectProps} />
        ),
      );
    }
    if (this.props.unstackable) {
      return (
        <Form.Group {...this.groupProps}>
          {...elements}
        </Form.Group>
      );
    }
    return elements;
  }
}

export default withContext(CountryRegion, 'i18n', 'api');
