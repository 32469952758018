/** @module components */

import * as React from 'react';
import { Icon, Message } from 'semantic-ui-react';
import { withContext, WithTranslation } from '@bryxinc/lunch/context';
import LoadingSpinner from './LoadingSpinner';

export type AlertType = 'success' | 'info' | 'warn' | 'error' | 'default';

interface AlertDataObj {
  [key: string]: string;
}

interface AlertProps extends WithTranslation {
  type?: AlertType | null;
  /**
   * Message or i18n localization key.
   * Ex: `'I have an option: {{option}}'`
   */
  message?: string;
  i18nKey?: string;
  data?: AlertDataObj;
}

interface AlertState {
  visible: boolean;
}

/**
 * Component to display errors.
 */
export class Alert extends React.Component<AlertProps, AlertState> {
  /**
   * Constructor
   * @param props
   */
  constructor(props: AlertProps) {
    super(props);
    this.state = {
      visible: true,
    };
  }

  /**
   * Handle dismiss.
   * Set visible state to false.
   */
  handleDismiss(): void {
    this.setState({ visible: false });
  }

  /**
   * Get header for alert.
   * @TODO Get header with i18n localization.
   * @TODO Add option for message to be from i18n key..?
   * @return Header for alert.
   */
  private get header(): string | null {
    if (!this.props.tReady) {
      return null;
    }
    switch (this.props.type) {
      case 'info':
        return this.props.t('alert.info');
      case 'warn':
        return this.props.t('alert.warn');
      case 'error':
        return this.props.t('alert.error');
      case 'success':
        return this.props.t('alert.success');
      default:
        return null;
    }
  }

  /**
   * Render icon for alert.
   * @TODO Make sure to change success icon to something appropriate.
   * @TODO Specify proper return type.
   * @return Icon node or null.
   */
  private get icon(): any {
    /*
    ICONS:
    info circle
    warning sign
    warning circle
    thumbs up outline
    meh
    frown
    life ring
    lightbulb
    */
    switch (this.props.type) {
      case 'info':
        return <Icon name='info circle' />;
      case 'warn':
        return <Icon name='warning sign' />;
      case 'error':
        return <Icon name='warning circle' />;
      case 'success':
        return <Icon name='thumbs up outline' />;
      default:
        return null;
    }
  }

  /**
   * Get message for alert using props.
   * @TODO Do this using i18n formatting options. `t(key, options)` `{this.props.t('Hello {{foo}}', {foo: 'bar'})}`
   * @return message for alert.
   */
  private get message(): React.ReactNode { // string | undefined {
    const { t, tReady, message, i18nKey, data } = this.props;
    if (i18nKey && !tReady) {
      return null;
    }
    const msg = t(
      (i18nKey || `:${message}`) || '',
      data || {},
    );
    return msg.split('\n').map((s: string, i: number) => <p key={i}>{s}</p>);
    // return msg;
  }

  /**
   * Renders error message.
   */
  render(): React.ReactNode {

    /*
    Floating
    attached
    info
    error
    warning
    success
    */

    const messageProps = {
      info: this.props.type == 'info',
      warning: this.props.type == 'warn',
      success: this.props.type == 'success',
      error: this.props.type == 'error',
      icon: true,
    };

    // Wait for translation to be ready.
    if (!this.props.tReady) {
      return (
        <Message {...messageProps}>
          {this.icon}
          <Message.Content>
            <LoadingSpinner />
          </Message.Content>
        </Message>
      );
    }
    // const message = this.props.i18nKey ? this.props.t(this.props.i18nKey) : this.props.message;
    return (
      <Message {...messageProps}>
        {this.icon}
        <Message.Content>
          <Message.Header>{this.header}</Message.Header>
          <span data-testid='alert-message'>
            {this.message}
          </span>
        </Message.Content>
      </Message>
    );
  }
}

export default withContext(Alert, 'i18n');
