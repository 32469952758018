/** @module components.SignIn */

import * as React from 'react';
import { SyntheticEvent } from 'react';
import {
  Button,
  Form,
  Header,
  Input,
  InputProps,
  Message,
  Modal,
} from 'semantic-ui-react';
import { ApiResult } from '@bryxinc/lunch/models';
import { nullIfBlank } from '@bryxinc/lunch/utils/functions/helper';
import * as headerStyle from '@bryxinc/style/header.module.css';

import LoadingSpinner from '../LoadingSpinner';
import { withContext, WithTranslation, WithLocal, WithApi } from '@bryxinc/lunch/context';
import Captcha from './Captcha';
import BryxApi from '@bryxinc/lunch/utils/AccountApi';

interface ForgotPasswordModalProps extends WithTranslation, WithLocal, WithApi<BryxApi> {
  open: boolean;
  email?: string | null;
  onChangeEmail: (e: SyntheticEvent<HTMLInputElement>) => void;
  onCancel: () => void;
  onSuccess: () => void;
}

interface ForgotPasswordModalState {
  status: { key: 'ready' } | { key: 'loading' } | { key: 'error', message: string };
  captchaResponse: string | null;
}

/**
 * Forgot password modal react component.
 */
export class ForgotPasswordModal extends React.Component<ForgotPasswordModalProps, ForgotPasswordModalState> {
  private captchaInstance: Captcha | null = null;

  /**
   * Constructor for ForgotPasswordModal.
   */
  constructor(props: ForgotPasswordModalProps, context: any) {
    super(props, context);

    this.state = {
      status: { key: 'ready' },
      captchaResponse: null,
    };
  }

  /**
   * Handle recaptcha verify event.
   * @param response
   */
  private onRecaptchaVerify(response: any): void {
    this.setState({
      captchaResponse: response,
    });
  }

  /**
   * Handle captcha change event.
   * @param d Captcha verification
   */
  private onCaptchaChange(d: string | null): void {
    this.setState({
      captchaResponse: d,
    });
  }

  /**
   * Send forgot passowrd.
   */
  private sendForgotPassword(): void {
    if (this.props.email == null || this.state.captchaResponse == null) {
      return;
    }

    this.setState({
      status: { key: 'loading' },
    });

    this.props.api.forgotPassword(this.props.email, this.state.captchaResponse, (result: ApiResult<null>) => {
      if (result.success == true) {
        this.props.local.logInfo('User successfully sent forgot password email');
        this.setState({
          status: { key: 'ready' },
        });
        this.props.onSuccess();
      } else {
        this.props.local.logWarn(`Failed to send forgot password email: ${result.debugMessage}`);
        if (this.captchaInstance != null) {
          this.captchaInstance.reset();
        }
        this.setState({
          status: {
            key: 'error',
            message: result.message,
          },
        });
      }
    });
  }

  /**
   * Handle form submit.
   * @param e Event object
   */
  private onFormSubmit(e: React.FormEvent<HTMLFormElement>): void {
    e.preventDefault();
    this.sendForgotPassword();
  }

  /**
   * Render forgot password modal.
   */
  render(): React.ReactNode {
    if (!this.props.tReady) {
      return (
        <Modal open={this.props.open}
          size='tiny'
          onClose={this.props.onCancel}>
          <LoadingSpinner />
        </Modal>
      );
    }

    return (
      <Modal size='tiny'
        open={this.props.open}
        onClose={this.props.onCancel}>
        <Header
          className={headerStyle.bryxHeader}
          icon='question circle'
          content={this.props.t('login.forgotPassword')}
        />

        <Modal.Content>
          <Form onSubmit={this.onFormSubmit.bind(this)}>
            <Form.Field>
              <Input autoFocus
                type='email'
                placeholder={this.props.t('login.email')}
                disabled={this.state.status.key == 'loading'}
                value={this.props.email}
                onChange={this.props.onChangeEmail} />
            </Form.Field>
          </Form>
          <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
            <Captcha
              ref={e => this.captchaInstance = e}
              onChange={this.onCaptchaChange.bind(this)}
            />
          </div>
          {this.state.status.key == 'error' && (
            <Message error>
              {this.state.status.message}
            </Message>
          )}
        </Modal.Content>

        <Modal.Actions>
          <Button disabled={this.state.status.key == 'loading'}
            type='button'
            onClick={this.props.onCancel}>
            {this.props.t('general.cancel')}
          </Button>
          <Button primary
            type='button'
            onClick={this.sendForgotPassword.bind(this)}
            disabled={nullIfBlank(this.props.email) == null || this.state.captchaResponse == null}
            loading={this.state.status.key == 'loading'}>
            {this.props.t('general.submit')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default withContext(ForgotPasswordModal, 'i18n', 'local', 'api');
