/** @module components.Agencies.Join.Submit */

import * as React from 'react';
import { Grid } from 'semantic-ui-react';

import {
  AgencyTypeKind,
  CallAlertKind,
  DataFeedKind,
  LeadInfo,
  ProductTypeKind,
} from '@bryxinc/lunch/models';
import * as style from '@bryxinc/style/main.module.css';
import * as color from '@bryxinc/style/color';

import { SignUpInfo } from '../SignUp';
import { withContext, WithTranslation, WithApi, WithLocal } from '@bryxinc/lunch/context';

export interface SignUpProps extends SignUpInfo, WithTranslation {}

/**
 * Submit agency sign up preview react component for Join Agency page.
 */
export class SignUp extends React.Component<SignUpProps> {
  static readonly displayName: string = 'SubmitSignUp';

  /**
   * Header row.
   */
  private get headerRow(): React.ReactNode {
    return (
      <Grid.Row centered>
        <Grid.Column width='16' textAlign='center'>
          <h2>{this.props.t('agencies.join.submitSignUp')}</h2>
        </Grid.Column>
      </Grid.Row>
    );
  }

  /**
   * Name row.
   */
  private get nameRow(): React.ReactNode {
    return (
      <Grid.Row centered>
        <Grid.Column width='2' textAlign='right'>
          <h4>{this.props.t('agencies.signUp.name')}</h4>
        </Grid.Column>
        <Grid.Column width='14'>{this.props.agencyName}</Grid.Column>
      </Grid.Row>
    );
  }

  /**
   * Agency type row.
   */
  private get typeRow(): React.ReactNode {
    // {this.props.t('agencies.signUp.agencyType.format', {type: this.props.agencyType})}
    return (
      <Grid.Row centered>
        <Grid.Column width='2' textAlign='right'>
          <h4>{this.props.t('agencies.signUp.agencyType.label')}</h4>
        </Grid.Column>
        <Grid.Column width='14'>
          {(this.props.agencyType || []).map((t: AgencyTypeKind) => this.props.t('agencies.signUp.agencyType.format', {type: t})).join(', ')}
        </Grid.Column>
      </Grid.Row>
    );
  }

  /**
   * Location row.
   * Includes country, region, and zipcode, if provided.
   */
  private get locationRow(): React.ReactNode {
    const { t, country, region, zipcode } = this.props;
    // t(`country:${country}.name`)
    // t(`country:${country}.region.${r}.name`)
    const overloadTranslationOptionHandler = (args: any) => {
      console.log(args);
      return 'ERROR';
    };
    return (
      <Grid.Row centered>
        <Grid.Column width='2' textAlign='right'>
          <h4>{this.props.t('agencies.signUp.location')}</h4>
        </Grid.Column>
        <Grid.Column width='14'>{
          zipcode ? t(
            'location.countryRegionZip',
            { country, region, zipcode },
          ) : t(
            'location.countryRegion',
            { country, region },
          )
        }</Grid.Column>
      </Grid.Row>
    );
  }

  /**
   * Product(s) row.
   */
  private get productRow(): React.ReactNode {
    return (
      <Grid.Row centered>
        <Grid.Column width='2' textAlign='right'>
          <h4>{this.props.t('agencies.signUp.product')}</h4>
        </Grid.Column>
        <Grid.Column width='14'>
          {(this.props.products || []).map((p: ProductTypeKind) => this.props.t(`branding.product.${p}`)). join(', ')}
        </Grid.Column>
      </Grid.Row>
    );
  }

  /**
   * Title row.
   */
  private get titleRow(): React.ReactNode {
    return (
      <Grid.Row centered>
        <Grid.Column width='2' textAlign='right'>
          <h4>{this.props.t('agencies.signUp.jobTitle')}</h4>
        </Grid.Column>
        <Grid.Column width='14'>
          {this.props.jobTitle}
        </Grid.Column>
      </Grid.Row>
    );
  }

  /**
   * Render function.
   */
  render(): React.ReactNode {
    return (
      <Grid columns='14'>
        {this.nameRow}
        {this.typeRow}
        {this.locationRow}
        {this.productRow}
        {this.titleRow}
      </Grid>
    );
  }
}
export default withContext(SignUp, 'i18n');
