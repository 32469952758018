/** @module components.Agencies */
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Route, Switch } from 'react-router-dom';

import NotFound from '../NotFound';
import Dashboard from './Dashboard';
import Join from './Join';
import View from './View';

/**
 * Agencies page component.
 * Manages switch for all sub pages.
 */
export default class Agencies extends React.Component<RouteComponentProps> {
  /**
   * Render function.
   */
  render(): React.ReactNode {
    return (
      <Switch>
        <Route path='/agencies' exact component={Dashboard} />
        <Route
          path={['/agencies/view', '/agencies/view/:collection']}
          exact
          component={View}
        />
        <Route path='/agencies/join' exact component={Join} />
        <Route component={NotFound} />
      </Switch>
    );
  }
}
