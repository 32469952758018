/** @module components.input */

import * as React from 'react';
import { FormEvent, SyntheticEvent } from 'react';
import { Dropdown, Form, Grid, Input, Popup, Select } from 'semantic-ui-react';

import { AgencyTypeKind } from '@bryxinc/lunch/models';
// import * as style from '@bryxinc/style/main.module.css';

import { withContext, WithTranslation } from '@bryxinc/lunch/context';

interface AgencyTypeProps extends WithTranslation {
  type?: AgencyTypeKind[];
  onChange?: (n: AgencyTypeKind[], v?: boolean | null) => void;
  labels?: boolean;
  placeholders?: boolean;
  required?: boolean;
  disabled?: boolean;
  inline?: boolean;
  style?: object;
  className?: string;
}
interface AgencyTypeState {
  focus: boolean | null;
}

/**
 * Agency type input component.
 */
export class AgencyType extends React.Component<AgencyTypeProps, AgencyTypeState> {
  static defaultProps: Partial<AgencyTypeProps> = {
    type: [],
    onChange: (n: AgencyTypeKind[], v?: boolean | null) => { return; },
    labels: false,
    placeholders: false,
    required: false,
    disabled: false,
    inline: false,
    style: {},
    className: '',
  };
  readonly state: AgencyTypeState = { focus: null };

  /**
   * Handler for change.
   */
  private onChange(
    e: SyntheticEvent<HTMLElement>,
    { value }: { value: AgencyTypeKind[] },
  ): void {
    const { onChange, required } = this.props as Required<AgencyTypeProps>;
    onChange(value, required ? value.length > 0 : true);
  }

  /**
   * Handle focus.
   */
  private onFocus(e: SyntheticEvent): void {
    this.setState({
      focus: !this.state.focus,
    });
  }

  /**
   * Getter for product options.
   */
  private get agencyTypeOpts(): object[] {
    const { tReady, t } = this.props;
    if (!tReady) {
      return [];
    }
    return [
      { key: AgencyTypeKind.fire, value: AgencyTypeKind.fire, text: t('agencies.signUp.agencyType.fire') },
      { key: AgencyTypeKind.ems, value: AgencyTypeKind.ems, text: t('agencies.signUp.agencyType.ems') },
      { key: AgencyTypeKind.police, value: AgencyTypeKind.police, text: t('agencies.signUp.agencyType.police') },
      { key: AgencyTypeKind.psap, value: AgencyTypeKind.psap, text: t('agencies.signUp.agencyType.psap') },
      { key: AgencyTypeKind.security, value: AgencyTypeKind.security, text: t('agencies.signUp.agencyType.security') },
      { key: AgencyTypeKind.military, value: AgencyTypeKind.military, text: t('agencies.signUp.agencyType.military') },
      { key: AgencyTypeKind.publicWorks, value: AgencyTypeKind.publicWorks, text: t('agencies.signUp.agencyType.publicWorks') },
      { key: AgencyTypeKind.other, value: AgencyTypeKind.other, text: t('agencies.signUp.agencyType.other') },
    ];
  }

  /**
   * Get select props.
   */
  get selectProps(): any {
    const {
      type, t, tReady, onChange,
      required, disabled, labels, inline, placeholders,
      style: compStyle, className,
    } = this.props;
    const { focus } = this.state;
    const p: any = {
      key: 'agency-type-select',
      loading: !tReady,
      inline,
      multiple: true,
      value: type || [],
      options: this.agencyTypeOpts,
      onChange: this.onChange.bind(this),
      onFocus: this.onFocus.bind(this),
      onBlur: this.onFocus.bind(this),
      required,
      disabled,
      style: compStyle,
      className,
    };
    if (
      required && focus === false &&
      (type === undefined || type.length == 0)
    ) {
      p.error = { content: t('form.fieldRequired') };
    }
    if (labels) {
      p.label = t('form.label.agencyType');
    }
    if (placeholders) {
      p.placeholder = t('form.label.agencyType');
    }
    return p;
  }

  /**
   * Render function.
   */
  render(): React.ReactNode {
    return <Form.Select {...this.selectProps} />;
  }
}

export default withContext(AgencyType, 'i18n');
