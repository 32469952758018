/**
 * @module components.SignIn
 */

import * as React from 'react';
import { FormEvent, SyntheticEvent } from 'react';
import {
  Button,
  Divider,
  Form,
  Grid,
  Input,
  Message,
  Popup,
} from 'semantic-ui-react';
import { withContext, WithTranslation, WithApi } from '@bryxinc/lunch/context';
import LoadingSpinner from '../LoadingSpinner';
import { withErrorBoundary } from '../ErrorBoundary';

import { ApiResult } from '@bryxinc/lunch/models';

import * as signinStyle from '@bryxinc/style/signin.module.css';
import * as style from '@bryxinc/style/main.module.css';
import * as color from '@bryxinc/style/color';
import BryxApi from '@bryxinc/lunch/utils/AccountApi';

interface PasswordResetFormProps extends WithTranslation, WithApi<BryxApi> {
  status: any;
  onStatusChange: (nextStatus?: any) => void;
  passwordResetCallback: (r: ApiResult<null>) => void;
  token: string;
}

interface PasswordResetFormState {
  newPassword: string;
  confirmPassword: string;
}

/**
 * PasswordResetForm component.
 */
export class PasswordResetForm extends React.Component<PasswordResetFormProps, PasswordResetFormState> {
  readonly state: PasswordResetFormState = {
    newPassword: '',
    confirmPassword: '',
  };

  /**
   * Function for handling form submission event.
   * @param e Event
   */
  private onSubmit(e: FormEvent<any>): void {
    e.preventDefault();
    this.submitCredentials();
  }

  /**
   * Submits credentials.
   */
  private submitCredentials(): void {
    const { api, token, onStatusChange, passwordResetCallback } = this.props;
    const { newPassword, confirmPassword } = this.state;
    onStatusChange({ key: 'loading', type: 'manual' });

    if (newPassword == '' || confirmPassword == '') {
      onStatusChange({
        key: 'error',
        alertMessage: this.props.t('login.blankResetPassword'),
      });
      return;
    }

    api.resetPassword(
      token,
      newPassword,
      confirmPassword,
      passwordResetCallback,
    );
  }

  /**
   * Function for handling change password event.
   * @param e Event
   */
  private onChangeNewPassword(e: SyntheticEvent<HTMLInputElement>): void {
    this.setState({
      newPassword: e.currentTarget.value,
    });
  }

  /**
   * Function for handling change confirm password event.
   * @param e Event
   */
  private onChangeConfirmPassword(e: SyntheticEvent<HTMLInputElement>): void {
    this.setState({
      confirmPassword: e.currentTarget.value,
    });
  }

  /**
   * Render component.
   */
  render(): React.ReactNode {
    // Use tReady prop to check if translations ready.
    if (!this.props.tReady) {
      return <LoadingSpinner />;
    }

    return (
      <Form key='pw-reset-form' onSubmit={this.onSubmit.bind(this)}>
        <Form.Field>
          <Input required
            type='password'
            placeholder={this.props.t('login.newPassword')}
            value={this.state.newPassword || ''}
            disabled={this.props.status.key == 'loading'}
            onChange={this.onChangeNewPassword.bind(this)} />
        </Form.Field>
        <Form.Field>
          <Input required
            type='password'
            placeholder={this.props.t('login.passwordConfirm')}
            value={this.state.confirmPassword || ''}
            disabled={this.props.status.key == 'loading'}
            onChange={this.onChangeConfirmPassword.bind(this)} />
        </Form.Field>
        <Form.Field>
          <Grid verticalAlign='middle' style={{ height: '100%' }}>
            <Grid.Row centered className={signinStyle.signinBtnContainer}>
              <Grid.Column style={{ paddingLeft: '25%', paddingRight: '25%' }}>
                <Button id='signinButton'
                  className={style.btn}
                  type='submit'
                  color='grey'
                  loading={this.props.status.key == 'loading'}
                  style={{ letterSpacing: '1px' }}
                >
                  {(this.props.t('login.resetPassword') as string || '').toUpperCase()}
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form.Field>
      </Form>
    );
  }
}

export default withErrorBoundary(withContext(PasswordResetForm, 'api', 'i18n'));
