/** @module components.Account */

/** @module components */

import React, { FormEvent, SyntheticEvent } from 'react';
import { RouteComponentProps } from 'react-router';
import {
  Button,
  Divider,
  Form,
  Grid,
  Input,
  Message,
  Popup,
  Header,
  Segment,
} from 'semantic-ui-react';
import classnames from 'classnames';
import { ApiResult } from '@bryxinc/lunch/models';

import * as style from '@bryxinc/style/main.module.css';
import * as headerStyle from '@bryxinc/style/header.module.css';
import * as color from '@bryxinc/style/color';

import { withErrorBoundary } from '../ErrorBoundary';
import { withContext, WithTranslation, WithLocal, WithApi } from '@bryxinc/lunch/context';
import Page from '../Page';
import Alert from '../Alert';
import BryxApi from '@bryxinc/lunch/utils/AccountApi';

interface PasswordProps extends RouteComponentProps, WithTranslation, WithLocal, WithApi<BryxApi> { }

interface PasswordAlert {
  type: 'success' | 'error';
  message: string;
}

interface PasswordState {
  password: string;
  newPassword: string;
  newPasswordConfirm: string;
  alert: PasswordAlert | null;
}

/**
 * Account password change react component.
 * To be displayed on account landing page.
 */
export class Password extends React.Component<PasswordProps, PasswordState> {

  constructor(props: PasswordProps) {
    super(props);
    this.state = {
      password: '',
      newPassword: '',
      newPasswordConfirm: '',
      alert: null,
    };
  }
  /**
   * Function for handling change password event.
   * @param e Event
   */
  private onChangePassword(e: SyntheticEvent<HTMLInputElement>): void {
    this.setState({
      password: e.currentTarget.value,
    });
  }

  /**
   * Function for handling change new password event.
   * @param e Event
   */
  private onChangeNewPassword(e: SyntheticEvent<HTMLInputElement>): void {
    this.setState({
      newPassword: e.currentTarget.value,
    });
  }

  /**
   * Function for handling change confirm new password event.
   * @param e Event
   */
  private onChangeNewPasswordConfirm(e: SyntheticEvent<HTMLInputElement>): void {
    this.setState({
      newPasswordConfirm: e.currentTarget.value,
    });
  }

  /**
   * Function for handling form submission event.
   * @param e Event
   */
  private onFormSubmit(e: FormEvent<HTMLFormElement>): void {
    e.preventDefault();
    const { password, newPassword, newPasswordConfirm } = this.state;
    // Check that newPassword and confrimNewPassword match.
    // Show error if not.
    if (!password || !newPassword || !newPasswordConfirm) {
      this.setState({
        alert: {
          type: 'error',
          message: this.props.t('account.password.blank'),
        },
      });
      return;
    }
    if (newPassword != newPasswordConfirm) {
      this.setState({
        alert: {
          type: 'error',
          message: this.props.t('account.password.notMatch'),
        },
      });
      return;
    }

    this.setState({
      alert: null,
    });

    // Send stuff to API and handle response
    this.props.api.changePassword(
      password,
      newPassword,
      this.handleChangePasswordResponse.bind(this),
    );
  }

  /**
   * Handle change password api response
   * @param r Response
   */
  private handleChangePasswordResponse(r: ApiResult<any>): void {
    if (r.success) {
      this.setState({
        alert: {
          type: 'success',
          message: this.props.t('account.password.success'),
        },
        password: '',
        newPassword: '',
        newPasswordConfirm: '',
      });
    } else {
      this.setState({
        alert: {
          type: 'error',
          message: r.message,
        },
      });
      this.props.local.logWarn(`Change password failed: ${r.debugMessage}`);
    }
  }

  /**
   * Get alert for change password.
   */
  private get alert(): React.ReactNode | null {
    return this.state.alert ? (
      <Alert
        key='change-pw-alert'
        type={this.state.alert.type}
        message={this.state.alert.message} />
    ) : null;
  }

  /**
   * Render function.
   */
  render(): React.ReactNode {
    const { t } = this.props;
    const form = (
      <Form
        onSubmit={this.onFormSubmit.bind(this)}
        style={{maxWidth: '500px', minWidth: '450px', width: '25%', marginLeft: 'auto', marginRight: 'auto' }}
      >
        <Form.Field>
          <Input required
            type='password'
            placeholder={this.props.t('account.password.password')}
            value={this.state.password || ''}
            disabled={!this.props.tReady}
            onChange={this.onChangePassword.bind(this)} />
        </Form.Field>
        <Form.Field>
          <Input required
            type='password'
            placeholder={this.props.t('account.password.newPassword')}
            value={this.state.newPassword || ''}
            disabled={!this.props.tReady}
            onChange={this.onChangeNewPassword.bind(this)} />
        </Form.Field>
        <Form.Field>
          <Input required
            type='password'
            placeholder={this.props.t('account.password.confirmNewPassword')}
            value={this.state.newPasswordConfirm || ''}
            disabled={!this.props.tReady}
            onChange={this.onChangeNewPasswordConfirm.bind(this)} />
        </Form.Field>
        <Form.Field>
          <Grid verticalAlign='middle' style={{ height: '100%' }}>
            <Grid.Row centered>
              <Grid.Column style={{ paddingLeft: '25%', paddingRight: '25%' }}>
                <Button
                  className={style.btn}
                  type='submit'
                  loading={!this.props.tReady}
                  style={{ color: color.red, letterSpacing: '1px' }}>
                  {(this.props.t('account.password.submit') as string || '').toUpperCase()}
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form.Field>
      </Form>
    );
    return (
      <Page
        title={t('account.password.header')}
      >
        {this.alert}
        <Segment compact style={{ marginRight: 'auto', marginLeft: 'auto' }}>
          {form}
        </Segment>
      </Page>
    );
  }
}

export default withErrorBoundary(withContext(Password, 'i18n', 'local', 'api'));
