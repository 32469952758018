/** @module components */

import * as React from 'react';
import SVG from 'react-inlinesvg';
import classnames from 'classnames';
import logoSvg from '@bryxinc/style/Bryx-Logo.svg';
import * as spinnerStyle from '@bryxinc/style/spinner.module.css';

/**
 * Static function to preprocess inline SVGs.
 */
function svgPreProcessor(code: string): string {
  console.log('preprocess SVG');
  return code.replace(/fill=".*?"/g, 'fill="currentColor"');
}
/*
<SVG
  src={logoSvg}
  preProcessor={Branding.svgPreProcessor}
/>
*/

interface LoadingSpinnerProps {
  white?: boolean;
  transparent?: boolean;
}

/**
 * Loading spinner component.
 */
export default class LoadingSpinner extends React.Component<LoadingSpinnerProps> {
  static defaultProps: LoadingSpinnerProps = {
    white: false,
    transparent: false,
  };

  /**
   * Render function.
   */
  render(): React.ReactNode {
    return (
      <div className={classnames(
        spinnerStyle.spinnerContainer,
        {
          [spinnerStyle.white]: this.props.white,
          [spinnerStyle.noBG]: this.props.transparent,
        },
      )}>
        <SVG
          src={logoSvg}
          preProcessor={svgPreProcessor}
          className={spinnerStyle.spinnerIcon}
        />
      </div>
    );
  }
}
