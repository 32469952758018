/** @module components.Agencies.Join */

import * as React from 'react';
import { FormEvent, SyntheticEvent } from 'react';
import { Grid, Button } from 'semantic-ui-react';

import * as style from '@bryxinc/style/main.module.css';
import * as color from '@bryxinc/style/color';

import Alert from '../../Alert';
import { withContext, WithTranslation } from '@bryxinc/lunch/context';

interface PaneWrapperProps extends WithTranslation {
  onBack?: () => void;
  onCancel?: () => void;
  onCont?: () => void;
  canCont?: boolean;
  contText?: string;
  title?: string;
  error?: { message: string, data?: any } | { i18nKey: string, data?: any } | false;
  // contContent?: React.ReactNode;
}

interface ReasonState {
  reason: string;
}

/**
 * Generic tab content wrapper react component for Join Agency page.
 */
export class PaneWrapper extends React.Component<PaneWrapperProps> {
  static readonly defaultProps: Partial<PaneWrapperProps> = { canCont: true };

  /**
   * Back button, if onBack given in props.
   */
  private get backBtn(): React.ReactNode | null {
    if (!this.props.onBack) {
      return null;
    }
    return (
      <Button
        style={{ letterSpacing: '1px' }}
        onClick={this.props.onBack}
      >
        {this.props.t('general.back').toUpperCase()}
      </Button>
    );
  }

  /**
   * Cancel button, if onCancel given in props.
   */
  private get cancelBtn(): React.ReactNode | null {
    if (!this.props.onCancel) {
      return null;
    }
    return (
      <Button
        style={{ letterSpacing: '1px' }}
        onClick={this.props.onCancel}
      >
        {this.props.t('general.cancel').toUpperCase()}
      </Button>
    );
  }

  /**
   * Continue button, if onCont given in props.
   */
  private get contBtn(): React.ReactNode | null {
    if (!this.props.onCont) {
      return null;
    }
    const contText = this.props.contText ? this.props.contText : this.props.t('general.continue');
    return (
      <Button
        style={{ letterSpacing: '1px' }}
        onClick={this.props.onCont}
        disabled={!this.props.canCont}
      >
        {contText.toUpperCase()}
      </Button>
    );
  }

  /**
   * Title row, if title given in props.
   */
  private get title(): React.ReactNode | null {
    if (!this.props.title) {
      return null;
    }
    return (
      <Grid.Column>
        <h2>{this.props.title}</h2>
      </Grid.Column>
    );
  }

  /**
   * Error row, if error object given in props.
   */
  private get error(): React.ReactNode | null {
    if (!this.props.error) {
      return null;
    }
    return (
      <Grid.Column>
        <Alert
          type='error'
          {...this.props.error}
        />
      </Grid.Column>
    );
  }

  /**
   * Render function.
   */
  render(): React.ReactNode {
    return (
      <Grid centered container columns='1'>
        {this.title}
        {this.error}
        <Grid.Column>
          {this.props.children}
        </Grid.Column>
        <Grid.Column textAlign='center'>
          {this.contBtn}
        </Grid.Column>
        <Grid.Column textAlign='center'>
          {this.backBtn}
        </Grid.Column>
        <Grid.Column textAlign='center'>
          {this.cancelBtn}
        </Grid.Column>
      </Grid>
    );
  }
}

export default withContext(PaneWrapper, 'i18n');
