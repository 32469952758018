/**
 * @module components.SignIn
 */

import * as React from 'react';
import { FormEvent, SyntheticEvent } from 'react';
import {
  Button,
  Divider,
  Form,
  Grid,
  Input,
  Message,
  Popup,
} from 'semantic-ui-react';
import { withContext, WithTranslation, WithApi } from '@bryxinc/lunch/context';
import LoadingSpinner from '../LoadingSpinner';
import Alert from '../Alert';
import ForgotPasswordModal from './ForgotPasswordModal';
import { withErrorBoundary } from '../ErrorBoundary';

import { AccountAuth, ApiResult } from '@bryxinc/lunch/models';

import * as signinStyle from '@bryxinc/style/signin.module.css';
import * as style from '@bryxinc/style/main.module.css';
import * as color from '@bryxinc/style/color';
import BryxApi from '@bryxinc/lunch/utils/AccountApi';

interface SignInFormProps extends WithTranslation, WithApi<BryxApi> {
  status: any;
  onStatusChange: (nextStatus?: any) => void;
  signInCallback: (r: ApiResult<AccountAuth>) => void;
}

interface SignInFormState {
  email: string;
  password: string;
  overlayContent: string;
  initializing: boolean;
  showForgotPw: boolean;
}

/**
 * SignInForm component.
 * @NOTE Made custom css classes.
 * @TODO Set state correctly in constructor.
 */
export class SignInForm extends React.Component<SignInFormProps, SignInFormState> {
  readonly state: SignInFormState = {
    email: '',
    password: '',
    overlayContent: 'none',
    initializing: true,
    showForgotPw: false,
  };

  /**
   * Function for handling form submission event.
   * @param e Event
   */
  private onSubmit(e: FormEvent<any>): void {
    e.preventDefault();
    this.submitCredentials();
  }

  /**
   * Submits credentials.
   */
  private submitCredentials(): void {
    const { api, onStatusChange, signInCallback } = this.props;
    const { email, password } = this.state;
    onStatusChange({ key: 'loading', type: 'manual' });

    if (email == null || email == '' || password == null || password == '') {
      onStatusChange({
        key: 'error',
        alertMessage: this.props.t('login.blankEmailOrPassword'),
      });
      return;
    }

    api.signIn(
      email,
      password,
      null,
      signInCallback,
    );
  }

  /**
   * Toggle show forgot password modal.
   */
  private toggleForgotPw(): void {
    this.setState({
      showForgotPw: !this.state.showForgotPw,
    });
  }

  /**
   * Handle forgot password success.
   */
  private onForgotPwSuccess(): void {
    this.setState({
      overlayContent: 'none',
      showForgotPw: false,
    });
    this.props.onStatusChange({
      key: 'ready',
      messageContent: 'forgotPasswordSent',
    });
  }

  /**
   * Function for handling change email event.
   * @param e Event or string
   */
  private onChangeEmail(e: SyntheticEvent<HTMLInputElement>): void {
    this.setState({
      email: e.currentTarget.value,
    });
  }

  /**
   * Function for handling change password event.
   * @param e Event
   */
  private onChangePassword(e: SyntheticEvent<HTMLInputElement>): void {
    this.setState({
      password: e.currentTarget.value,
    });
  }

  /**
   * Handle close forgot password success message.
   */
  private onCloseForgotPWSuccess(): void {
    this.props.onStatusChange({
        key: 'ready',
        messageContent: 'none',
    });
  }

  /**
   * Render component.
   */
  render(): React.ReactNode {
    // Use tReady prop to check if translations ready.
    if (!this.props.tReady) {
      return <LoadingSpinner />;
    }

    const form = (
      <Form key='sign-in-form' onSubmit={this.onSubmit.bind(this)}>
        <Form.Field>
          <Input required
            autoFocus
            autoComplete='off'
            autoCorrect='off'
            autoCapitalize='off'
            spellCheck={false}
            type='username'
            placeholder={this.props.t('login.email')}
            value={this.state.email || ''}
            disabled={this.props.status.key == 'loading'}
            onChange={this.onChangeEmail.bind(this)} />
        </Form.Field>
        <Form.Field>
          <Input required
            type='password'
            placeholder={this.props.t('login.password')}
            value={this.state.password || ''}
            disabled={this.props.status.key == 'loading'}
            onChange={this.onChangePassword.bind(this)} />
        </Form.Field>
        <Form.Field>
          <Grid columns={2} className={signinStyle.signinBtnGrid} centered>
            <Grid.Row className={signinStyle.signinBtnRow}>
              <Grid.Column style={{ paddingTop: '0rem !important' }} className={signinStyle.signinBtnCol}>
                <Popup position='left center'
                  on='click'
                  style={{ textAlign: 'center' }}
                  content={this.props.t('login.forgotPasswordSuccess')}
                  open={this.props.status.key == 'ready' && this.props.status.messageContent == 'forgotPasswordSent'}
                  onClose={this.onCloseForgotPWSuccess.bind(this)}
                  trigger={(
                    <Button
                      className={style.btn}
                      inverted
                      color='grey'
                      type='button'
                      onClick={this.toggleForgotPw.bind(this)}>
                      {this.props.t('login.forgotPassword').toUpperCase()}
                    </Button>
                  )} />
              </Grid.Column>
              <Grid.Column style={{ paddingTop: '0rem !important' }} className={signinStyle.signinBtnCol}>
                <Button id={style.signinButton}
                  className={style.btn}
                  type='submit'
                  color='grey'
                  loading={this.props.status.key == 'loading'}>
                  {this.props.t('login.signIn').toUpperCase()}
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form.Field>
      </Form>
    );
    const pwModal = (
      <ForgotPasswordModal
        key='forgot-pw-modal'
        open={this.state.showForgotPw}
        email={this.state.email}
        onChangeEmail={this.onChangeEmail.bind(this)}
        onCancel={this.toggleForgotPw.bind(this)}
        onSuccess={this.onForgotPwSuccess.bind(this)} />
    );
    return [form, pwModal];
  }
}

export default withErrorBoundary(withContext(SignInForm, 'api', 'i18n'));
