/** @module components.SignIn */
import * as React from 'react';
import Recaptcha from 'react-recaptcha';

import * as style from '@bryxinc/style/main.module.css';

// ENV variables from Webpack
declare const PRODUCTION: boolean;
declare const RECAPTCHA_SITE_KEY: string;

interface CaptchaProps {
  onChange: (d: string | null) => void;
}

// /**
//  * Captcha component that passes ref.
//  * @param props
//  * @param ref
//  */
// export function Captcha(props: CaptchaProps, ref: any): any {
//   // On component mount, artificially set as verified if not in production mode.
//   const onMount = () => {
//     if (!PRODUCTION) {
//       console.log('Not in production: Artificially setting captcha as verified.');
//       props.onChange('dummy-response');
//     }
//   };
//   const onVerify = (r: string) => props.onChange(r);
//   const onExpired = () => props.onChange(null);
//
//   React.useEffect(onMount);
//
//   return (
//     <Recaptcha
//       ref={ref}
//       elementID={style.recaptcha}
//       sitekey={RECAPTCHA_SITE_KEY}
//       verifyCallback={onVerify}
//       expiredCallback={onExpired}
//     />
//   );
// }
//
// export default React.forwardRef(Captcha);

/**
 * Captcha component.
 */
export default class Captcha extends React.Component<CaptchaProps> {
  private captchaInstance: Recaptcha | null = null;

  /**
   * Constructor
   */
  constructor(props: CaptchaProps) {
    super(props);
    this.reset = this.reset.bind(this);
  }

  /**
   * On component did mount.
   * Artificially set as verified if not in production mode.
   */
  componentDidMount(): void {
    if (!PRODUCTION) {
      console.log('Not in production: Artificially setting captcha as verified.');
      this.props.onChange('dummy-response');
    }
  }

  /**
   * Handle recaptcha verify event.
   * @param r Response
   */
  private onVerify(r: string): void {
    this.props.onChange(r);
  }

  /**
   * Handle recaptcha expired event.
   * @param r Response
   */
  private onExpired(): void {
    this.props.onChange(null);
  }

  /**
   * Reset captcha
   */
  reset(): void {
    if (this.captchaInstance != null) {
      this.captchaInstance.reset();
    }
    this.props.onChange(null);
    if (!PRODUCTION) {
      console.log('Not in production: Artificially setting captcha as verified.');
      this.props.onChange('dummy-response');
    }
  }

  /**
   * Render component.
   */
  render(): React.ReactNode {
    return (
      <Recaptcha
        ref={e => this.captchaInstance = e}
        elementID={style.recaptcha}
        sitekey={RECAPTCHA_SITE_KEY}
        verifyCallback={this.onVerify.bind(this)}
        expiredCallback={this.onExpired.bind(this)}
      />
    );
  }
}
