/** @module components.Agencies.Join.JoinRequest.Agency */

/** @module components.Agencies.Join.JoinRequest */

import * as React from 'react';
import {
  Accordion,
  Icon,
  Menu,
  Popup,
} from 'semantic-ui-react';

import {
  SearchAgency,
  AgencyGroup,
  AgencyGroupTypeEnum,
  MinimalDispatchGroup,
} from '@bryxinc/lunch/models';
import * as style from '@bryxinc/style/main.module.css';
import * as color from '@bryxinc/style/color';

import { withContext, WithTranslation } from '@bryxinc/lunch/context';

interface GroupProps extends WithTranslation {
  group: MinimalDispatchGroup;
  selected: boolean;
  onGroupClick: (e: React.SyntheticEvent, d: any) => void;
}

/**
 * Agency group menu item react component for Join Agency page.
 */
export class Group extends React.Component<GroupProps> {
  /**
   * Should compoent update.
   * For optimization.
   */
  shouldComponentUpdate(
    nextProps: GroupProps,
  ): boolean {
    const { selected, group } = this.props;
    const { selected: nextSelected, group: nextGroup } = nextProps;
    if ( selected != nextSelected || group !== nextGroup) {
      return true;
    }
    return false;
  }

  /**
   * Get node indicating if member.
   * @return Member icon node.
   */
  private get isMemberIcon(): React.ReactNode {
    const { selected, group: { isMember } } = this.props;
    if ( selected && isMember ) {
      return (
         <Icon name='exclamation circle' color='red' style={{ float: 'right', margin: '0em 0.5em 0em 1em' }} />
      );
    }
    if ( isMember ) {
      return (
         <Icon name='check circle' color='green' style={{ float: 'right', margin: '0em 0.5em 0em 1em' }} />
      );
    }
    return null;
  }

  /**
   * Get node indicating if member.
   */
  private get isMember(): React.ReactNode {
    const { t, group: { isMember } } = this.props;
    if ( isMember ) {
      return (
        <Popup
          trigger={this.isMemberIcon}
          content={t('agencies.alreadyGroupMember')}
          size='mini'
        />
      );
    }
    return null;
  }

  /**
   * Render function.
   */
  render(): React.ReactNode {
    const { selected, group, onGroupClick } = this.props;
    return (
      <Menu.Item
        key={group.id}
        id={group.id}
        name={group.id}
        active={selected}
        color={group.isMember as boolean && selected ? 'red' : undefined}
        disabled={group.isMember as boolean && !selected}
        onClick={onGroupClick}
      >
        {group.name}
        {this.isMember}
      </Menu.Item>
    );
  }
}

export default withContext(Group, 'i18n');
