/** @module components.Agencies.Join.JoinRequest.Agency */

/** @module components.Agencies.Join.JoinRequest */

import * as React from 'react';
import {
  Accordion,
  Icon,
  Menu,
  Popup,
} from 'semantic-ui-react';

import {
  SearchAgency,
  AgencyGroup,
  AgencyGroupTypeEnum,
  MinimalDispatchGroup,
} from '@bryxinc/lunch/models';
import * as style from '@bryxinc/style/main.module.css';
import * as color from '@bryxinc/style/color';

import Group from './Group';
import { withContext, WithTranslation } from '@bryxinc/lunch/context';

interface AgencyProps extends WithTranslation {
  agency: SearchAgency;
  selected: boolean;
  selectedGroups: Array<Partial<MinimalDispatchGroup>>;
  onAgencyClick: (e: React.SyntheticEvent, d: any) => void;
  onGroupClick: Array<(e: React.SyntheticEvent, d: any) => void>;
}

interface AgencyState {
  location?: string;
}

/**
 * Agency menu item react component for Join Agency page.
 */
export class Agency extends React.Component<AgencyProps, AgencyState> {
  readonly state: AgencyState = {};

  /**
   * Should compoent update.
   * For optimization.
   */
  shouldComponentUpdate(
    nextProps: AgencyProps,
    nextState: AgencyState,
  ): boolean {
    const { tReady, agency, selected, selectedGroups } = this.props;
    const {
      tReady: nextTReady,
      agency: nextAgency,
      selected: nextSelected,
      selectedGroups: nextSelectedGroups,
    } = nextProps;
    if (
      agency !== nextAgency ||
      selected != nextSelected ||
      tReady != nextTReady ||
      selectedGroups.length != nextSelectedGroups.length
    ) {
      return true;
    }
    return false;
  }

  /**
   * Get translated location for agency.
   */
  private get location(): string {
    const { t, tReady, agency: { country, district, region } } = this.props;
    if (!tReady) {
      return '';
    }
    if ( this.state.location ) {
      return this.state.location;
    }
    const location = t('location.countryRegionDistrict', { country, district, region });
    this.setState({ location });
    return location;
  }

  /**
   * Get node indicating if member.
   * @return Member icon node.
   */
  private get isMemberIcon(): React.ReactNode {
    const { selected, agency: { isMember, memberOfAllGroups } } = this.props;
    if ( selected && isMember && memberOfAllGroups ) {
      return (
         <Icon name='exclamation circle' color='red' style={{ float: 'right', margin: '0.21425em -1em 0em 1em' }} />
      );
    }
    if ( isMember ) {
      return (
         <Icon name='check circle' color='green' style={{ float: 'right', margin: '0.21425em -1em 0em 1em' }} />
      );
    }
    return null;
  }

  /**
   * Get node indicating if member.
   * @return Member icon popup node.
   */
  private get isMember(): React.ReactNode {
    const { t, agency: { isMember, memberOfAllGroups } } = this.props;
    if ( isMember ) {
      return (
        <Popup
          trigger={this.isMemberIcon}
          content={t('agencies.alreadyMember')}
          size='mini'
        />
      );
    }
    return null;
  }

  /**
   * Get node indicating if needing to slect groups.
   * @return Select groups icon popup node.
   */
  private get selectGroups(): React.ReactNode {
    const { t, agency: { isMember, memberOfAllGroups }, selected, selectedGroups = [] } = this.props;
    if ( !memberOfAllGroups && selected && selectedGroups.length == 0 ) {
      const icon = (
        <Icon name='warning sign' color='yellow' style={{ float: 'right', margin: '0.21425em -1em 0em 1em' }} />
      );
      return (
        <Popup
          trigger={icon}
          content={t('agencies.selectGroups')}
          size='mini'
        />
      );
    }
    return null;
  }

  /**
   * Get agency menu item content.
   * @return Content node.
   */
  private get content(): React.ReactNode {
    const { agency, t } = this.props;
    return (
      <React.Fragment>
        {this.isMember}
        {this.selectGroups}
        <h4
          style={{ marginTop: '-0.14285714em', marginBottom: '0', color: 'currentColor' }}>
          {agency.name}
        </h4>
        <p style={{ fontSize: '0.8em', paddingLeft: '0.5em' }}>
          {this.location}
        </p>
      </React.Fragment>
    );
  }

  /**
   * Get group menu item node.
   * @return Group menu node.
   */
  private get groups(): React.ReactNode {
    const { agency: { dispatchGroups = [] }, selectedGroups, onGroupClick } = this.props;
    const groups = dispatchGroups.map(
      (g: MinimalDispatchGroup, i: number) => {
        const gSelectedI = selectedGroups.findIndex(
          (h: MinimalDispatchGroup) => g.id === h.id,
        );
        const gSelected = gSelectedI > -1;
        return (
          <Group
            key={i}
            group={g}
            selected={gSelected}
            onGroupClick={onGroupClick[i]}
          />
        );
      },
    );
    return <Menu.Menu>{groups}</Menu.Menu>;
  }

  /**
   * Render function.
   */
  render(): React.ReactNode {
    const { selected, agency, onAgencyClick } = this.props;
    if (!agency.hasGroups) { // If no groups, disabled
      return (
        <Menu.Item
          key={agency.id}
          name={agency.id}
          active={selected}
          onClick={onAgencyClick}
          disabled
        >
          {this.content}
        </Menu.Item>
      );
    }

    return (
      <Menu.Item
        key={agency.id}
        name={agency.id}
        active={selected}
        disabled={agency.memberOfAllGroups && !selected}
        color={agency.memberOfAllGroups && selected ? 'red' : undefined}
      >
        <Accordion.Title
          style={{ color: 'currentColor' }}
          active={selected || agency.memberOfAllGroups}
          content={this.content}
          index={agency.id}
          onClick={onAgencyClick}
          disabled={agency.memberOfAllGroups && !selected}
        />
        <Accordion.Content
          active={selected || agency.memberOfAllGroups}
          content={this.groups}
        />
      </Menu.Item>
    );
    // style={{ height: '100%', minHeight: '400px' }} For map column
  }
}

export default withContext(Agency, 'i18n');
