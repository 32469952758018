/**
 * @module components.SignIn
 */

import * as React from 'react';
import { FormEvent, SyntheticEvent } from 'react';
import {
  Segment,
  Button,
  Divider,
  Form,
  Grid,
  Input,
  Message,
  Popup,
} from 'semantic-ui-react';
import { withContext, WithTranslation, WithApi, WithLocal } from '@bryxinc/lunch/context';
import LoadingSpinner from '../LoadingSpinner';
import Alert from '../Alert';
import ForgotPasswordModal from './ForgotPasswordModal';
import { withErrorBoundary } from '../ErrorBoundary';

import ClientName from '../input/ClientName';
import PhoneNumber from '../input/PhoneNumber';
import Email from '../input/Email';
import ClientJobTitle from '../input/ClientJobTitle';
import CountryRegion, { CRState } from '../input/CountryRegion';
import AgencyName from '../input/AgencyName';
import AgencyType from '../input/AgencyType';
import ProductInterest from '../input/ProductInterest';
import Notes from '../input/Notes';

import {
  AgencyTypeKind,
  ApiResult,
  CountryInfo,
  LeadInfo,
  ProductTypeKind,
  RegionInfo,
} from '@bryxinc/lunch/models';

import * as signinStyle from '@bryxinc/style/signin.module.css';
import * as style from '@bryxinc/style/main.module.css';
import * as color from '@bryxinc/style/color';
import BryxApi from '@bryxinc/lunch/utils/AccountApi';

interface ContactFormProps extends WithTranslation, WithApi<BryxApi>, WithLocal {
  status: any;
  onStatusChange: (nextStatus?: any) => void;
}

interface ContactFormValues extends CRState {
  clientName: string;
  clientEmail: string;
  clientPhone: string;
  clientJobTitle: string;
  agencyName: string;
  type: AgencyTypeKind[];
  products: ProductTypeKind[];
  notes?: string;
}
interface ContactFormValuesValid {
  // [key: keyof NonNullable<ContactFormValues>]: boolean | null;
  clientName: boolean | null;
  clientEmail: boolean | null;
  clientPhone: boolean | null;
  clientJobTitle: boolean | null;
  agencyName: boolean | null;
  type: boolean | null;
  products: boolean | null;
  location: boolean | null;
}

interface ContactFormState extends ContactFormValues {
  valid: ContactFormValuesValid;
  success: boolean;
  error?: { message: string, data?: any } | { i18nKey: string, data?: any } | false;
}

interface ContactFormStateOld extends CRState {
  clientName: string;
  clientNameValid: boolean | null;
  clientSurname: string;
  clientJobTitle: string;
  clientEmail: string;
  clientPhone: string;
  clientPhoneValid: boolean | null;
  agencyName: string;
  type: AgencyTypeKind[];
  zipcode: string;
  products: ProductTypeKind[];
  notes?: string;
  error?: { message: string, data?: any } | { i18nKey: string, data?: any } | false;
}

/**
 * ContactForm component.
 */
export class ContactForm extends React.Component<ContactFormProps, ContactFormState> {
  // readonly state: ContactFormState = {
  //   clientName: '',
  //   clientNameValid: null,
  //   clientJobTitle: '',
  //   clientEmail: '',
  //   clientPhone: '',
  //   clientPhoneValid: null,
  //   agencyName: '',
  //   type: [],
  //   zipcode: '',
  //   products: [],
  //   notes: '',
  // };
  readonly state: ContactFormState = {
    clientName: '',
    clientEmail: '',
    clientPhone: '',
    clientJobTitle: '',
    agencyName: '',
    type: [],
    products: [],
    notes: '',
    valid: {
      clientName: null,
      clientEmail: null,
      clientPhone: true,
      clientJobTitle: true,
      agencyName: null,
      type: null,
      products: null,
      location: null,
    },
    success: false,
  };

  /**
   * Reset the form.
   */
  private resetForm(): void {
    this.setState({
      clientName: '',
      clientEmail: '',
      clientPhone: '',
      clientJobTitle: '',
      agencyName: '',
      type: [],
      products: [],
      notes: '',
      country: undefined,
      region: undefined,
      noRegions: undefined,
      valid: {
        clientName: null,
        clientEmail: null,
        clientPhone: true,
        clientJobTitle: true,
        agencyName: null,
        type: null,
        products: null,
        location: null,
      },
      success: false,
    });
  }

  /**
   * Callback fro create signup/lead.
   */
  private leadCB(r: ApiResult<null>): void {
    const { local, onStatusChange } = this.props;
    if (r.success == true) {
      local.logInfo('Contact successful.');
      // onStatusChange({
      //   key: 'success',
      //   redirectLocation: '/',
      // });
      this.setState({
        success: true,
      });

    } else {
      onStatusChange({
        key: 'error',
        alertMessage: r.message,
      });
      local.logWarn(`Contact failed: ${r.debugMessage}`);
    }
  }

  /**
   * Handler for error from country/region API.
   */
  private onAPIError(e: { message: string, data?: any } | { i18nKey: string, data?: any } | false): void {
    const { onStatusChange, t } = this.props;
    this.setState({ error: e});
    if (e) {
      onStatusChange({
        key: 'error',
        alertMessage: (e as any).data.message || '',
      });
    } else {
      onStatusChange({
        key: 'ready',
        messageContent: 'none',
      });
    }
  }

  /**
   * Function for handling form submission event.
   * @param e Event
   */
  private onSubmit(e: FormEvent<any>): void {
    e.preventDefault();
    this.submit();
  }

  /**
   * Submits form.
   */
  private submit(): void {
    console.log('Submitting', this.state);
    const { api, onStatusChange } = this.props;
    const {
      valid,
      clientName, clientEmail, clientPhone,
      clientJobTitle,
      agencyName, type, products, notes,
      country, region,
    } = this.state;
    if (Object.values(valid).some((v: boolean | null) => !v)) {
      onStatusChange({
        key: 'error',
        alertMessage: this.props.t('form.invalidField'),
      });
      return;
    }
    const clientInfo: any = { name: clientName, email: clientEmail };
    if (clientPhone) {
      clientInfo.phone = clientPhone;
    }
    api.createSignUp(
      agencyName,
      type,
      country as Partial<CountryInfo>,
      region || null,
      null,
      products,
      clientJobTitle || null,
      this.leadCB.bind(this),
      notes || null,
      clientInfo,
    );
  }

  // /**
  //  * Get handler for value change.
  //  */
  // private valueChangeHandler<T, U>(
  //   key: string,
  //   transform: (o: T) => U = (o: U) => o,
  // ): ( n: T, v: boolean | null) => void {
  //   return ((o: T, v: boolean | null) => {
  //     this.setState({
  //       [key]: value,
  //       valid: { ...this.state.valid, [key]: valid },
  //     });
  //   }).bind(this);
  // }

  /**
   * Handler for country/region change.
   */
  private onCountryRegionChange(
    value: Partial<CRState>,
    valid: boolean | null,
  ): void {
    this.setState({
      ...(value as CRState),
      valid: { ...this.state.valid, location: valid },
    });
    // this.setState(n as CRState);
    // this.props.updateLead(v, this.verified);
  }

  /**
   * Handle client name change.
   */
  private onChangeClientName(
    { fullName }: { fullName: string },
    valid: boolean | null,
  ): void {
    this.setState({
      clientName: fullName,
      valid: { ...this.state.valid, clientName: valid },
    });
  }

  /**
   * Handle email change.
   */
  private onChangeEmail(
    value: string,
    valid: boolean | null,
  ): void {
    this.setState({
      clientEmail: value,
      valid: { ...this.state.valid, clientEmail: valid },
    });
  }

  /**
   * Handle phone number change.
   */
  private onChangePhone(
    value: string,
    valid: boolean | null,
  ): void {
    this.setState({
      clientPhone: value,
      valid: { ...this.state.valid, clientPhone: valid },
    });
  }

  /**
   * Handle client job title change.
   */
  private onChangeClientJobTitle(
    value: string,
    valid: boolean | null,
  ): void {
    this.setState({
      clientJobTitle: value,
      valid: { ...this.state.valid, clientJobTitle: valid },
    });
  }

  /**
   * Handle agency name change.
   */
  private onChangeAgencyName(
    value: string,
    valid: boolean | null,
  ): void {
    this.setState({
      agencyName: value,
      valid: { ...this.state.valid, agencyName: valid },
    });
  }

  /**
   * Handle agency type change.
   */
  private onChangeAgencyType(
    value: AgencyTypeKind[],
    valid: boolean | null,
  ): void {
    this.setState({
      type: value,
      valid: { ...this.state.valid, type: valid },
    });
  }

  /**
   * Handle product interest change.
   */
  private onChangeProducts(
    value: ProductTypeKind[],
    valid: boolean | null,
  ): void {
    this.setState({
      products: value,
      valid: { ...this.state.valid, products: valid },
    });
  }

  /**
   * Handle notes change.
   */
  private onChangeNotes(
    value: string,
    valid: boolean | null,
  ): void {
    this.setState({ notes: value });
  }

  /**
   * Render component.
   */
  render(): React.ReactNode {
    const { t, tReady } = this.props;
    // Use tReady prop to check if translations ready.
    if (!tReady) {
      return <LoadingSpinner />;
    }
    if (this.state.success) {
      return (
        <React.Fragment>
          <Alert type='success' i18nKey='login.contactUsSuccess'/>
          <Button id={signinStyle.SigninButton}
            href={this.props.api.brandUrl}
            color='grey'
            loading={this.props.status.key == 'loading'}>
            {t('login.returnToMain').toUpperCase()}
          </Button>
        </React.Fragment>
      );
    }

    return (
      <Segment>
      <Form key='agency-sign-up-form' onSubmit={this.onSubmit.bind(this)}>
        <ClientName required labels
          onChange={this.onChangeClientName.bind(this)}
        />
        <Email required labels
          value={this.state.clientEmail}
          onChange={this.onChangeEmail.bind(this)}
        />
        <PhoneNumber labels
          onChange={this.onChangePhone.bind(this)}
        />
        <ClientJobTitle labels
          value={this.state.clientJobTitle}
          onChange={this.onChangeClientJobTitle.bind(this)}
        />
        <AgencyName labels required
          value={this.state.agencyName}
          onChange={this.onChangeAgencyName.bind(this)}
        />
        <AgencyType labels required
          type={this.state.type}
          onChange={this.onChangeAgencyType.bind(this)}
        />
        <ProductInterest labels required
          products={this.state.products}
          onChange={this.onChangeProducts.bind(this)}
        />
        <CountryRegion
          onChange={this.onCountryRegionChange.bind(this)}
          onError={this.onAPIError.bind(this)}
          country={this.state.country}
          region={this.state.region}
          noRegions={this.state.noRegions}
          labels
          required
        />
        <Notes labels
          value={this.state.notes || ''}
          onChange={this.onChangeNotes.bind(this)}
        />
        <Form.Field>
          <Grid verticalAlign='middle' className={signinStyle.signinBtnGrid}>
            <Grid.Row centered className={signinStyle.signinBtnRow}>
              <Grid.Column className={signinStyle.signinBtnCol}>
                <Button id={signinStyle.SigninButton}
                  type='submit'
                  loading={this.props.status.key == 'loading'}
                >
                  {(this.props.t('login.contactUs') as string || '').toUpperCase()}
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form.Field>
      </Form>
      </Segment>
    );
  }
}

export default withErrorBoundary(withContext(ContactForm, 'api', 'i18n', 'local'));
