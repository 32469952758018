/** @module components */

import * as React from 'react';
import { Button, Form, Message, Modal } from 'semantic-ui-react';
import { nullIfBlank } from '@bryxinc/lunch/utils/functions/helper';
import { SupportType } from '@bryxinc/lunch/utils/functions/support';
import { ApiResult } from '@bryxinc/lunch/models';
// import BryxModal, { Header as BryxModalHeader, Content as BryxModalContent, Actions as BryxModalActions } from './Modal';

import classnames from 'classnames';
import * as headerStyle from '@bryxinc/style/header.module.css';

import LoadingSpinner from './LoadingSpinner';
import { withContext, WithTranslation, WithLocal, WithApi } from '@bryxinc/lunch/context';
import BryxApi from '@bryxinc/lunch/utils/AccountApi';

export enum SupportModalSkin {
  support, sales,
}

interface SupportModalProps extends WithTranslation, WithLocal, WithApi<BryxApi> {
  open: boolean;
  onClose: () => void;
}

interface SupportModalState {
  open: boolean;
  skin: SupportModalSkin;
  status: { key: 'ready' } | { key: 'loading' } | { key: 'error', description: string };
  from: string | null;
  subject: string | null;
  type: SupportType;
  image: File | null;
  body: string | null;
}

/**
 * Support modal react component.
 * @TDOD Rework support modal and how it interacts with other components.
 */
export class SupportModal extends React.Component<SupportModalProps, SupportModalState> {
  private static readonly ticketTypes: SupportType[] = ['feedback', 'question', 'bug', 'featureRequest'];
  static defaultProps: object = {
    open: false,
    onClose: () => {
      console.log('Close support');
    },
  };
  /**
   * Support modal constructor.
   */
  constructor(props: SupportModalProps) {
    super(props);

    this.state = this.initialState;
  }

  /**
   * Execute on component having props or state updated.
   * @TODO Somehow this works. But I still probably want to redo this workflow.
   */
  componentDidUpdate(
    prevProps: SupportModalProps,
    prevState: SupportModalState,
  ): void {
    // console.log(this.state);
    if (this.props.open && !this.state.open && !prevState.open) {
      this.setState({ open: true });
    }
    return;
  }

  /**
   * Email from local storage.
   */
  private get localStorageEmail(): string | null {
    return this.props.local.email;
  }

  /**
   * Initial state for component.
   */
  private get initialState(): SupportModalState {
    return {
      open: false,
      skin: SupportModalSkin.support,
      status: {
        key: 'ready',
      },
      from: this.localStorageEmail,
      subject: null,
      type: 'feedback',
      image: null,
      body: null,
    };
  }

  /**
   * Handle fromfield change.
   * @param e Event
   * @param d Data
   */
  private onFromChange(
    e: React.FormEvent<HTMLFormElement>,
    d: any,
  ): void {
    e.preventDefault();
    this.setState({ from: nullIfBlank(d.value as string | null) });
  }

  /**
   * Handle subject field change.
   * @param e Event
   * @param d Data
   */
  private onSubjectChange(
    e: React.FormEvent<HTMLFormElement>,
    d: any,
  ): void {
    e.preventDefault();
    this.setState({ subject: nullIfBlank(d.value as string | null) });
  }

  /**
   * Handle type field change.
   * @param e Event
   * @param d Data
   */
  private onTypeChange(
    e: React.FormEvent<HTMLFormElement>,
    d: any,
  ): void {
    e.preventDefault();
    this.setState({ type: d.value as SupportType });
  }

  /**
   * Handle image field change.
   * @param e Event
   */
  private onImageChange(e: React.FormEvent<HTMLFormElement>): void {
    e.preventDefault();
    const target = e.target as HTMLInputElement;
    this.setState({ image: target.files != null ? target.files[0] : null });
  }

  /**
   * Handle body field change.
   * @param e Event
   * @param d Data
   */
  private onBodyChange(
    e: React.FormEvent<HTMLFormElement>,
    d: any,
  ): void {
    e.preventDefault();
    this.setState({ body: nullIfBlank(d.value as string | null) });
  }

  /**
   * Callback function for sending a support ticket.
   * Handles API result.
   * @param result
   */
  private submitTicketCallback(result: ApiResult<null>): void {
    if (result.success == true) {
      this.props.local.logInfo('User successfully sent support ticket');
      this.setState({
        status: { key: 'ready' },
        from: null,
        body: null,
      }, () => this.resetAndClose());
    } else {
      this.props.local.logWarn(`User failed to send support ticket: ${result.debugMessage}`);
      this.setState({
        status: { key: 'error', description: result.message },
      });
    }
  }

  /**
   * Handle support ticket submission event.
   */
  private onSubmitTicket(): void {
    this.setState({
      status: { key: 'loading' },
    }, () => {
      if (this.state.from == null || this.state.body == null) {
        this.setState({
          status: {
            key: 'error',
            description: this.props.t('general.genericError'),
          },
        });
        return;
      }

      const subject = this.state.skin == SupportModalSkin.support ? this.state.subject : `Sales: ${this.state.subject}`;

      this.props.api.sendSupportTicket(
        this.state.from,
        subject,
        this.state.type,
        this.state.body,
        this.state.image,
        this.props.local,
        this.submitTicketCallback.bind(this),
      );
    });
  }

  /**
   * Reset and close modal.
   */
  private resetAndClose(): void {
    console.log('Support modal reseting');
    this.setState(this.initialState);
    this.props.onClose();
  }

  /**
   * Get header text.
   * @return Header text for modal.
   */
  private get headerText(): string {
    if (this.state.skin == SupportModalSkin.support) {
      return this.props.t('contactSupport.headerSupport');
    }
    return this.props.t('contactSupport.headerSales');
  }

  /**
   * Get form elements.
   * @return array of form elements.
   */
  private get formElements(): React.ReactNode[] {
    const elements = [];
    elements.push(
      (
        <Form.Input
          key='fromInput'
          label={this.props.t('contactSupport.fromLabel')}
          placeholder={this.props.t('contactSupport.fromPlaceholder')}
          autoComplete={false}
          autoCorrect={false}
          autoCapitalize={false}
          spellCheck={false}
          value={this.state.from || ''}
          onChange={this.onFromChange.bind(this)} />
      ),
    );
    elements.push(
      (
        <Form.Input
          key='subjectInput'
          label={this.props.t('contactSupport.subjectLabel')}
          placeholder={this.props.t('contactSupport.subjectPlaceholder')}
          value={this.state.subject || ''}
          onChange={this.onSubjectChange.bind(this)} />
      ),
    );
    if (this.state.skin == SupportModalSkin.support) {
      const ticketTypes = SupportModal.ticketTypes.map((option) => ({
        key: option,
        text: this.props.t(`contactSupport.supportTypes.${option}`),
        value: option,
      }));
      elements.push(
        (
          <Form.Select
            key='typeSelect'
            label={this.props.t('contactSupport.ticketTypeLabel')}
            options={ticketTypes}
            value={this.state.type}
            onChange={this.onTypeChange.bind(this)} />
        ),
      );
      elements.push(
        (
          <Form.Input
            key='imageInput'
            label={this.props.t('contactSupport.imageLabel')}
            type='file'
            control='input'
            accept='.png,.jpg'
            onChange={this.onImageChange.bind(this)} />
        ),
      );
    }
    elements.push(
      (
        <Form.TextArea
          key='bodyTextArea'
          label={this.props.t('contactSupport.bodyLabel')}
          value={this.state.body || ''}
          placeholder={this.props.t('contactSupport.bodyPlaceholder')}
          onChange={this.onBodyChange.bind(this)} />
      ),
    );
    return elements;
  }

  /**
   * Render support modal.
   */
  render(): React.ReactNode {
    const ticketTypes = SupportModal.ticketTypes.map((option) => ({
      key: option,
      text: this.props.t(`contactSupport.supportTypes.${option}`),
      value: option,
    }));

    const errorMessage = this.state.status.key == 'error' ? (
      <Message negative content={this.state.status.description} />
    ) : null;

    if (!this.props.tReady) {
      return (
        <Modal open={this.state.open}
          size='small'
          onClose={this.resetAndClose.bind(this)}>
          <LoadingSpinner />
        </Modal>
      );
    }

    /* tslint:disable */
    /*
    return (
      <BryxModal open={this.state.open}
        size='small'
        onClose={this.resetAndClose.bind(this)}>
        <BryxModalHeader>
          <h3 className={classnames(headerStyle.bryxHeader)}>
            {this.headerText}
          </h3>
        </BryxModalHeader>
        <BryxModalContent>
          <Form>
            {...this.formElements}
          </Form>
          {errorMessage}
        </BryxModalContent>
        <BryxModalActions>
          <Button content={this.props.t('general.cancel')}
            onClick={this.resetAndClose.bind(this)} />
          <Button primary
            loading={this.state.status.key == 'loading'}
            disabled={this.state.from == null || this.state.body == null || this.state.status.key == 'loading'}
            content={this.props.t('general.send')}
            onClick={this.onSubmitTicket.bind(this)} />
        </BryxModalActions>
      </BryxModal>
    ); // */

    return (
      <Modal open={this.state.open}
        size='small'
        onClose={this.resetAndClose.bind(this)}>
        <h3 className={classnames(headerStyle.bryxHeader, headerStyle.inModal)}>
          {this.headerText}
        </h3>
        {/*<Modal.Header className={headerStyle.bryxHeader}>
          {this.state.skin == SupportModalSkin.support ? this.props.t('contactSupport.headerSupport') : this.props.t('contactSupport.headerSales')}
        </Modal.Header>*/}
        <Modal.Content>
          <Form>
            {...this.formElements}
          </Form>
          {errorMessage}
        </Modal.Content>
        <Modal.Actions>
          <Button content={this.props.t('general.cancel')}
            onClick={this.resetAndClose.bind(this)} />
          <Button primary
            loading={this.state.status.key == 'loading'}
            disabled={this.state.from == null || this.state.body == null || this.state.status.key == 'loading'}
            content={this.props.t('general.send')}
            onClick={this.onSubmitTicket.bind(this)} />
        </Modal.Actions>
      </Modal>
    );
    /* tslint:ensable */
  }
}

export default withContext(SupportModal, 'i18n', 'local', 'api');
