/** @module components.Nav */

import React, { SyntheticEvent, useRef } from 'react';
import * as ReactDOM from 'react-dom';
import { RouteComponentProps, withRouter, useRouteMatch } from 'react-router';
import { Link, NavLink, Redirect } from 'react-router-dom';
import {
  isBrowser,
  isMobile,
} from 'react-device-detect';
import classnames from 'classnames';
import * as style from '@bryxinc/style/nav.module.css';

import { withContext, WithTranslation, WithLocal, WithApi } from '@bryxinc/lunch/context';
import { withErrorBoundary } from '../ErrorBoundary';
import Branding from './Branding';
import NavMenuItem, { NavMenuItemConfig } from './NavMenuItem';
import BryxApi from '@bryxinc/lunch/utils/AccountApi';

// Want these to be passed in as props in the future.
declare const PRODUCTION: boolean;
declare const VERSION: string;
declare const STYLE_VERSION: string;
declare const BREAKFAST_VERSION: string;

export { NavMenuItemConfig } from './NavMenuItem';

interface NavProps extends WithTranslation, WithLocal, WithApi<BryxApi> {
  red?: boolean;
  navOptions: NavMenuItemConfig[];
}

interface NavState {
  collapsed: boolean;
  hover: boolean;
  menuOpen: boolean;
  versionsOpen: boolean;
  openChild?: number | null;
  redirecting?: boolean;
  redirectLocation?: string;
}

/**
 * Nav react component. Meant to be generic and sharable.
 * @TODO Clean up how dropdowns work.
 * @TODO Pass in ENV and VERSION variables as props
 */
export class Nav extends React.Component<NavProps, NavState> {
  static defaultProps: object = {
    red: false,
    navOptions: [],
  };
  readonly state: NavState = {
    collapsed: false,
    hover: false,
    menuOpen: false,
    versionsOpen: false,
  };

  // constructor(props: NavProps) {
  //   super(props);
  //   this.state = {
  //     collapsed: false,
  //     menuOpen: false,
  //     versionsOpen: false,
  //   };
  // }

  /**
   * Handle window scroll.
   * Intended to be able to modify the class of top scrollbar element once
   * a certain amount has been scrolled.
   * @param e Scroll event.
   */
  private handleWindowScroll(e: any): void {
    // console.log(e.srcElement.scrollingElement.scrollTop);
    this.setState({
      collapsed: (e.srcElement.scrollingElement.scrollTop > 70),
    });
  }

  /**
   * Toggles if menu is open, for mobile.
   */
  private toggleMenuOpen(e: SyntheticEvent): void {
    console.log('Toggling menu.', e, e.target, this.state);
    this.setState({
      menuOpen: !this.state.menuOpen,
      versionsOpen: false,
    });
  }

  /**
   * Closes menu, for mobile.
   */
  private closeMenu(e: SyntheticEvent): void {
    console.log('Closing menu.', e, e.target, this.state);
    this.setState({
      menuOpen: false,
    });
  }

  /**
   * Toggles if versions display is open, for mobile.
   */
  private toggleVersionsOpen(e: SyntheticEvent): void {
    this.setState({
      menuOpen: false,
      versionsOpen: !this.state.versionsOpen,
    });
  }

  /**
   * Handle component mount.
   */
  componentDidMount(): void {
    window.addEventListener('scroll', this.handleWindowScroll.bind(this));
  }

  /**
   * Handle component unmount.
   */
  componentWillUnmount(): void {
    window.removeEventListener('scroll', this.handleWindowScroll.bind(this));
  }

  /**
   * Get version display for development.
   * @return Version display div
   */
  private get versionDisplay(): React.ReactNode | null {
    if (PRODUCTION) {
      return null;
    }
    const versionLabel = 'Version';
    const styleLabel = 'Style';
    const breakfastLabel = 'Breakfast';
    return (
      <div>
        <table
          id={style.VersionDisplay}
          className={classnames({
            [style.open]: this.state.versionsOpen,
          })}
        >
          <tbody>
            <tr>
              <th>{versionLabel}</th>
              <td>{VERSION}</td>
            </tr>
            <tr>
              <th>{styleLabel}</th>
              <td>{STYLE_VERSION}</td>
            </tr>
            <tr>
              <th>{breakfastLabel}</th>
              <td>{BREAKFAST_VERSION}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  /**
   * Get version display toggle butotn for development.
   * @return Version display div
   */
  private get versionToggle(): React.ReactNode | null {
    if (PRODUCTION || !isMobile) {
      return null;
    }
    return (
      <div
        id={style.VersionDisplayToggle}
        className={classnames({
          [style.active]: this.state.versionsOpen,
        })}
      >
        <a onClick={this.toggleVersionsOpen.bind(this)} />
      </div>
    );
  }

  /**
   * Get account info node for nav bar.
   * @return Accoutn info list item
   */
  private get accountInfo(): React.ReactNode {
    const { local } = this.props;
    if (!local.isSignedIn()) {
      return null;
    }
    return (
      <li className={style.info}>
        <div className={classnames(style.text, style.name)}>{local.fullName}</div>
        <div className={classnames(style.text, style.email)}>{local.email}</div>
      </li>
    );
  }

  /**
   * Render function.
   */
  render(): React.ReactNode {
    if (this.state.redirecting) {
      this.setState({
        redirecting: false,
      });
      return <Redirect to={(this.state.redirectLocation as string)} push />;
    }
    const navItems = this.props.navOptions.map(
      (n: NavMenuItemConfig, i: number) => {
        if (isMobile) {
          return (
            <NavMenuItem
              key={i}
              toggleMenu={this.toggleMenuOpen.bind(this)}
              {...n}
            />
          );
        }
        return <NavMenuItem key={i} {...n} />;
      },
    );
    /*
    <div id={style.NavVersionDisplay}>
      {this.versionDisplay}
    </div>
    */

    // if (isBrowser) {
    return (
      <div
        id={style.NavBar}
        className={classnames({
          [style.mobile]: isMobile,
          [style.small]: this.state.collapsed,
          [style.red]: this.props.red,
        })}
      >
        <div className={style.navBarSection}>
          <Branding />
          {this.versionDisplay}
        </div>
        <div
          id={style.NavMenu}
          className={classnames({
            [style.open]: this.state.menuOpen,
          })}
        >
          <ul>
            {this.accountInfo}
            {navItems}
          </ul>
        </div>
        {this.versionToggle}
        <div
          id={style.NavMenuToggle}
          className={classnames({
            [style.active]: this.state.menuOpen,
          })}
        >
          <a onClick={this.toggleMenuOpen.bind(this)} />
        </div>
      </div>
    );
    // }
    return (
      <div
        id={style.NavBar}
        className={this.state.collapsed ? style.small : ''}
      >
        {this.props.t('nav.notSupportedErr')}
      </div>
    );
  }
}

// export default withRouter(Nav);
// export default withRouter(withErrorBoundary(Nav));
export default withErrorBoundary(withContext(Nav, 'api', 'local', 'i18n'));
