/** @module components.Agencies.Join.Submit */

import * as React from 'react';
import { Grid, Menu, Table } from 'semantic-ui-react';

import { MinimalDispatchGroup } from '@bryxinc/lunch/models';
import * as style from '@bryxinc/style/main.module.css';
import * as color from '@bryxinc/style/color';

import { withContext, WithTranslation, WithApi, WithLocal } from '@bryxinc/lunch/context';

import { SelectedAgency } from '../JoinRequest/Select';
import { JoinRequestInfo } from '../JoinRequest';

export interface RequestedAgency extends SelectedAgency {
  success?: boolean;
  error?: { message: string, data?: any } | { i18nKey: string, data?: any } | false;
}

export interface JoinRequestProps extends JoinRequestInfo, WithTranslation {}

/**
 * Submit join request preview react component for Join Agency page.
 */
export class JoinRequest extends React.Component<JoinRequestProps> {
  static readonly displayName: string = 'SubmitJoinRequest';

  /**
   * Get agency menu item node.
   * @return Agency menu item node.
   */
  private getAgencyMenuItem(a: SelectedAgency): React.ReactNode {
    if (!a.agency.name) { // Return if not full agency
      return null;
    }
    const content = (
      <React.Fragment>
        <h5
          style={{ marginTop: '-0.14285714em', marginBottom: '0', color: 'currentColor' }}
        >
          {a.agency.name}
        </h5>
        <p
          style={{ fontSize: '0.8em', paddingLeft: '0.5em' }}
        >
          {this.props.t('location.countryRegionDistrict', { ...a.agency })}
        </p>
      </React.Fragment>
    );

    const groupMenuItems = a.groups.map(
      (g: MinimalDispatchGroup) => (
        <Menu.Item
          key={g.id}
          id={g.id}
          name={g.id}
        >
          {g.name}
        </Menu.Item>
      ),
    );
    const groupMenu = <Menu.Menu>{groupMenuItems}</Menu.Menu>;

    return (
      <Menu.Item
        key={a.agency.id}
        name={a.agency.id}
      >
        {content}
        {groupMenu}
      </Menu.Item>
    );
  }

  /**
   * Agency menu.
   */
  private get agencyMenu(): React.ReactNode {
    const { agencies } = this.props;
    return (
      <Menu
        vertical
        secondary
        style={{
          margin: '0',
          minWidth: '15rem',
          width: '40%',
        }}
      >
        {agencies.map(this.getAgencyMenuItem.bind(this))}
      </Menu>
    );
  }

  /**
   * Agency grid rows.
   */
  private get agencies(): React.ReactNode[] {
    const { agencies } = this.props;
    const agencyRows: React.ReactNode[] = agencies.map(
      (a: SelectedAgency, aI: number) => {
        let groups = null;
        if (!!a.groups.length) { // (a.hasGroups) {
          const groupRows: React.ReactNode[] = a.groups.map(
            (g: MinimalDispatchGroup, gI: number) => {
              return (
                <Grid.Row key={gI}>
                  <Grid.Column>
                    {g.name}
                  </Grid.Column>
                </Grid.Row>
              );
            },
          );
          groups = (
            <Grid.Column width='14'>
              <Grid>{...groupRows}</Grid>
            </Grid.Column>
          );
        }
        return (
          <Grid.Row key={aI} columns='16'>
            <Grid.Column width='2'>
              {a.agency.name}
            </Grid.Column>
            {groups}
          </Grid.Row>
        );
      },
    );
    return agencyRows;
  }

  /**
   * Render function.
   */
  render(): React.ReactNode {
    /*<Grid>
      {this.agencies}
    </Grid>
    */
    return (
      <Grid>
        <Grid.Row centered>
          <Grid.Column width='2'>
            <h4>{this.props.t('agencies.join.agenciesLabel')}</h4>
          </Grid.Column>
          <Grid.Column width='14'>
            {this.agencyMenu}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column width='2'>
            <h4>{this.props.t('agencies.join.reason')}</h4>
          </Grid.Column>
          <Grid.Column width='14'>
            {this.props.reason}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
export default withContext(JoinRequest, 'i18n');
