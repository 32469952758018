/** @module components.input */

import * as React from 'react';
import { FormEvent, SyntheticEvent } from 'react';
import { Form, Input, Select } from 'semantic-ui-react';

// import * as style from '@bryxinc/style/main.module.css';

import { withContext, WithTranslation } from '@bryxinc/lunch/context';

export interface NameState {
  givenName?: string;
  surname?: string;
  fullName?: string;
}

interface ClientNameProps extends WithTranslation {
  onChange?: (n: Partial<NameState>, v?: boolean | null) => void;
  labels?: boolean;
  placeholders?: boolean;
  required?: boolean;
  disabled?: boolean;
  inline?: boolean;
  unstackable?: boolean;
  style?: object;
  className?: string;
}
interface ClientNameState {
  givenName?: string;
  surname?: string;
  givenNameFocus: boolean | null;
  surnameFocus: boolean | null;
}

/**
 * Agency type input component.
 */
export class ClientName extends React.Component<ClientNameProps, ClientNameState> {
  static defaultProps: Partial<ClientNameProps> = {
    onChange: (n: Partial<NameState>, v?: boolean | null) => { return; },
    labels: false,
    placeholders: false,
    required: false,
    disabled: false,
    inline: false,
    unstackable: true,
    style: {},
    className: '',
  };
  readonly state: ClientNameState = {
    givenName: '',
    surname: '',
    givenNameFocus: null,
    surnameFocus: null,
  };

  /**
   * Function for handling change email event.
   * @param e Event
   */
  private onGivenNameChange(e: SyntheticEvent<HTMLInputElement>): void {
    e.preventDefault();
    const givenName = e.currentTarget.value;
    const { surname } = this.state;
    this.setState({ givenName });
    const { onChange, required } = this.props as Required<ClientNameProps>;
    onChange(
      { givenName, surname, fullName: `${givenName} ${surname}` },
      required ? !!(givenName && surname) : true,
    );
  }

  /**
   * Function for handling change email event.
   * @param e Event
   */
  private onSurnameChange(e: SyntheticEvent<HTMLInputElement>): void {
    e.preventDefault();
    const surname = e.currentTarget.value;
    const { givenName } = this.state;
    this.setState({ surname });
    const { onChange, required } = this.props as Required<ClientNameProps>;
    onChange(
      { givenName, surname, fullName: `${givenName} ${surname}` },
      required ? !!(givenName && surname) : true,
    );
  }

  /**
   * Handle given name focus.
   */
  private onGivenNameFocus(e: SyntheticEvent): void {
    this.setState({
      givenNameFocus: !this.state.givenNameFocus,
    });
  }

  /**
   * Handle surname focus.
   */
  private onSurnameFocus(e: SyntheticEvent): void {
    this.setState({
      surnameFocus: !this.state.surnameFocus,
    });
  }

  /**
   * Given name input props.
   */
  get givenNameProps(): any {
    const {
      t, tReady,
      required, disabled, labels, inline, placeholders,
      style: compStyle, className,
    } = this.props;
    const { givenName: value, givenNameFocus: focus } = this.state;
    const p: any = {
      key: 'given-name-input',
      loading: !tReady,
      inline,
      value: value || '',
      onChange: this.onGivenNameChange.bind(this),
      onFocus: this.onGivenNameFocus.bind(this),
      onBlur: this.onGivenNameFocus.bind(this),
      required,
      disabled,
      style: compStyle,
      className,
    };
    if (required && focus === false && !value) {
      p.error = { content: t('form.fieldRequired') };
    }
    if (labels) {
      p.label = t('login.givenName');
    }
    if (placeholders) {
      p.placeholder = t('login.givenName');
    }
    return p;
  }

  /**
   * Surname input props.
   */
  get surnameProps(): any {
    const {
      t, tReady, onChange,
      required, disabled, labels, inline, placeholders,
      style: compStyle, className,
    } = this.props;
    const { surname: value, surnameFocus: focus } = this.state;
    const p: any = {
      key: 'surname-input',
      loading: !tReady,
      inline,
      value: value || '',
      onChange: this.onSurnameChange.bind(this),
      onFocus: this.onSurnameFocus.bind(this),
      onBlur: this.onSurnameFocus.bind(this),
      required,
      disabled,
      style: compStyle,
      className,
    };
    if (required && focus === false && !value) {
      p.error = { content: t('form.fieldRequired') };
    }
    if (labels) {
      p.label = t('login.surname');
    }
    if (placeholders) {
      p.placeholder = t('login.surname');
    }
    return p;
  }

  /**
   * Form group props.
   */
  get groupProps(): any {
    const {
      tReady, unstackable,
      disabled, inline,
      style: compStyle, className,
    } = this.props;
    const p: any = {
      key: 'name-group',
      widths: 'equal',
      loading: !tReady,
      inline,
      unstackable,
      disabled,
      style: compStyle,
      className,
    };
    return p;
  }

  /**
   * Render function.
   */
  render(): React.ReactNode {
    return (
      <Form.Group {...this.groupProps}>
        <Form.Input {...this.givenNameProps}/>
        <Form.Input {...this.surnameProps}/>
      </Form.Group>
    );
  }
}

export default withContext(ClientName, 'i18n');
