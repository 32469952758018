/** @module components */

import * as React from 'react';
import Alert from './Alert';
import { Message } from 'semantic-ui-react';

interface ErrorBoundaryState {
  hasError: boolean;
  errorMessage: string;
}

/**
 * Component to catch and handle errors in other components.
 */
export default class ErrorBoundary extends React.Component<any, ErrorBoundaryState> {
  /**
   * Constructor
   * @param props
   */
  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage: '',
    };
  }

  /**
   * Gets state from error.
   * @props e Error
   * @return Component state
   */
  static getDerivedStateFromError(e: Error): {} {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
      errorMessage: e.message,
    };
  }

  /*componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    logErrorToMyService(error, errorInfo);
  }*/

  /**
   * Renders child component(s) if no error,
   * otherwise renders error message.
   */
  render(): React.ReactNode {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Alert
          type='error'
          i18nKey='alert.errBoundMsg'
          data={{ message: this.state.errorMessage }}
        />
      );
    }

    return this.props.children;
  }
}

/**
 * Wrap component in error boundary.
 * @TODO Seperate wrapped component props from error boundary props, if any.
 * @param c
 */
export function withErrorBoundary(c: React.ComponentType<any>): React.ComponentType<any> {
  const WithErrorBoundary = ({ forwardedRef, ...rest }: any) => {

    const passDownProps = {
      ...rest,
    };
    return React.createElement(
      ErrorBoundary,
      {},
      React.createElement(c, passDownProps),
    );
  };

  WithErrorBoundary.displayName = `withErrorBoundary(${c.displayName || c.name || 'Unknown'})`;
  WithErrorBoundary.WrappedComponent = c;

  return WithErrorBoundary;
}

/*
// From: https://reactjs.org/docs/higher-order-components.html#convention-wrap-the-display-name-for-easy-debugging
function withSubscription(WrappedComponent) {
  class WithSubscription extends React.Component { ... }
  WithSubscription.displayName = `WithSubscription(${getDisplayName(WrappedComponent)})`;
  return WithSubscription;
}
*/
