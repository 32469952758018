/** @module components */

import * as React from 'react';
import { RouteComponentProps, RouteProps } from 'react-router';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useParams,
} from 'react-router-dom';
import Main from './Main';
import SignIn from './SignIn';
import Eula from './Eula';

/**
 * Redirect component for redirecting from old join links
 */
export function OldJoinRedirect(props: any): any {
  const { slug = '' } = useParams();
  const agencyId = slug.slice(0, 24);
  const groupId = slug.slice(24);
  return (
    <Redirect
      to={{
        pathname: '/agencies/join',
        search: `?agency=${agencyId}&groups[]=${groupId}`,
      }}
    />
  );
}

/**
 * Main router for site.
 */
export default class MainRouter extends React.Component<RouteProps> {
  /**
   * Render method.
   */
  render(): React.ReactNode {
    return (
      <Router>
        <Switch>
          <Route path={['/welcome', '/contact', '/signin', '/signup', '/password-reset/:apiKey']} component={SignIn} />
          <Route path='/eula' component={Eula} />
          <Route path='/join/:slug' component={OldJoinRedirect} />
          <Route component={Main} />
        </Switch>
      </Router>
    );
  }
}
