/** @module components */

import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { isChromium, isChrome } from 'react-device-detect';

import classnames from 'classnames';

import * as headerStyle from '@bryxinc/style/header.module.css';
import * as style from '@bryxinc/style/main.module.css';
import * as color from '@bryxinc/style/color';

import Card from './Card';
import Page from './Page';
import { withContext, WithTranslation, WithLocal, WithApi } from '@bryxinc/lunch/context';
import BryxApi from '@bryxinc/lunch/utils/AccountApi';

interface DashboardProps extends RouteComponentProps, WithTranslation, WithLocal, WithApi<BryxApi> { }

/**
 * Dashboard react component.
 * To be displayed on landing page.
 */
export class Dashboard extends React.Component<DashboardProps, any> {

  /**
   * Handle manage click.
   */
  private onClickAccount(e: React.SyntheticEvent): void {
    e.preventDefault();
    const { history } = this.props;
    history.push('/account');
  }

  /**
   * Handle manage click.
   */
  private onClickAgencies(e: React.SyntheticEvent): void {
    e.preventDefault();
    const { history } = this.props;
    history.push('/agencies');
  }

  /**
   * Account card.
   */
  private get accountCard(): React.ReactNode {
    return (
      <Card
        headerKey='dashboard.account.header'
        descrKey='dashboard.account.descr'
        icon={{ name: 'user' }}
        href='/account'
        onClick={this.onClickAccount.bind(this)}
      />
    );
  }

  /**
   * Agencies card.
   */
  private get agenciesCard(): React.ReactNode {
    return (
      <Card
        headerKey='dashboard.agencies.header'
        descrKey='dashboard.agencies.descr'
        icon={[
          { name: 'building', corner: 'top left', style: { width: '1.18em', height: '1em', position: 'absolute' } },
          { name: 'building', corner: 'top right' },
          { name: 'building', corner: 'bottom left' },
          { name: 'building', corner: 'bottom right' },
        ]}
        href='/agencies'
        onClick={this.onClickAgencies.bind(this)}
      />
    );
  }

  /**
   * Management site card.
   */
  private get manageSiteCard(): React.ReactNode {
    const { api, local } = this.props;
    // Return null if not manager
    if (!local.get('manager')) {
      return null;
    }
    return (
      <Card
        headerKey='dashboard.manageSite.header'
        descrKey='dashboard.manageSite.descr'
        icon={{ name: 'key' }}
        href={`${api.manageUrl}/login?apiKey=${local.apiKey}`}
      />
    );
  }

  /**
   * App site card.
   */
  private get downloadCard(): React.ReactNode {
    const { api, local } = this.props;
    return (
      <Card
        headerKey='dashboard.appSite.header'
        descrKey='dashboard.appSite.descr'
        icon={{ name: 'laptop' }}
        href={api.downloadUrl}
      />
    );
  }

  /**
   * App site card.
   */
  private get appSiteCard(): React.ReactNode {
    const { api, local } = this.props;
    return (
      <Card
        headerKey='dashboard.appSite.header'
        descrKey='dashboard.appSite.descr'
        icon={{ name: 'laptop' }}
        href={`${api.appUrl}?apiKey=${local.apiKey}`}
      />
    );
  }

  /**
   * App card.
   */
  private get appCard(): React.ReactNode {
    if (isChromium || isChrome) {
      return this.appSiteCard;
    }
    return this.downloadCard;
  }

  /**
   * Render function.
   */
  render(): React.ReactNode {
    const { t } = this.props;
    return (
      <Page
        title={t('dashboard.header')}
      >
      <Card.Group centered>
        {this.accountCard}
        {this.agenciesCard}
        {this.manageSiteCard}
        {this.appCard}
      </Card.Group>
      </Page>
    );
  }
}

export default withContext(Dashboard, 'i18n', 'local', 'api');
