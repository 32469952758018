/** @module components.SignIn */

import * as QueryParams from "query-string";
import * as React from "react";
import { RouteComponentProps, RouteProps } from "react-router";
import {
  Button,
  Divider,
  Form,
  Grid,
  Icon,
  Input,
  Message,
  Popup,
} from "semantic-ui-react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  Link,
} from "react-router-dom";
import SVG from "react-inlinesvg";
import {
  withContext,
  WithTranslation,
  WithLocal,
  WithApi,
} from "@bryxinc/lunch/context";
import Nav, { NavMenuItemConfig } from "../Nav";
import LoadingSpinner from "../LoadingSpinner";
import Alert, { AlertType } from "../Alert";
import Logo from "./Logo";
import Welcome from "./Welcome";
import ContactForm from "./ContactForm";
import SignInForm from "./SignInForm";
import SignUpForm from "./SignUpForm";
import PasswordResetForm from "./PasswordResetForm";
import ErrorBoundary from "../ErrorBoundary";
import SupportModal from "../SupportModal";

import * as style from "@bryxinc/style/main.module.css";
import * as contentStyle from "@bryxinc/style/content.module.css";
import * as signinStyle from "@bryxinc/style/signin.module.css";
import supportSvg from "@bryxinc/style/support_icon.svg";
import classnames from "classnames";

import { AccountAuth, ApiResult } from "@bryxinc/lunch/models";
import BryxApi from "@bryxinc/lunch/utils/AccountApi";

type SignInStatus =
  | {
      key: "ready";
      messageContent:
        | "none"
        | "passwordChanged"
        | "forgotPasswordSent"
        | "forceSignedOut";
    }
  | { key: "loading"; type: "manual" | "auto" }
  | { key: "success"; redirectLocation: string | object }
  | { key: "error"; alertMessage: string };

interface SignInProps
  extends RouteComponentProps,
    WithTranslation,
    WithLocal,
    WithApi<BryxApi> {}

interface SignInState {
  overlayContent: string;
  initializing: boolean;
  email?: string | null;
  status: SignInStatus;
  supportOpen: boolean;
}

/**
 * Login component.
 */
export class SignIn extends React.Component<SignInProps, SignInState> {
  /**
   * Creates sign in component.
   */
  constructor(props: SignInProps) {
    super(props);

    // this.renderWelcome = this.renderWelcome.bind(this);

    let status: SignInStatus;
    if (
      props.local.isSignedIn() &&
      props.match.path !== "/password-reset/:apiKey"
    ) {
      status = { key: "success", redirectLocation: this.redirectLocation };
    } else {
      if (this.props.location && this.props.location.state) {
        switch (this.props.location.state.type) {
          case "forced":
            status = { key: "ready", messageContent: "forceSignedOut" };
            break;
          case "passwordChanged":
            status = { key: "ready", messageContent: "passwordChanged" };
            break;
          default:
            status = { key: "ready", messageContent: "none" };
        }
      } else {
        status = { key: "ready", messageContent: "none" };
      }
    }

    this.state = {
      overlayContent: "none",
      initializing: true,
      status: status,
      supportOpen: false,
    };
  }

  /**
   * On component did mount.
   */
  componentDidMount(): void {
    if (this.state.status.key != "success") {
      if (this.props.location && this.props.location.search != null) {
        const params = QueryParams.parse(this.props.location.search);
        if (params != null && params["apiKey"] != null) {
          this.setState({
            status: { key: "loading", type: "auto" },
          });

          this.props.api.signInWithApiKey(
            params["apiKey"] as string,
            this.signInWithKeyCallback.bind(this)
          );
        }
      }
      this.setState({ initializing: false });
    } // */
  }

  /**
   * Toggle support modal.
   */
  private toggleSupport(): void {
    this.setState({
      supportOpen: !this.state.supportOpen,
    });
  }

  /**
   * Handle status change.
   * @param nextStatus New status. If not provided, sets status to default ready state.
   */
  private onStatusChange(
    nextStatus: SignInStatus = { key: "ready", messageContent: "none" }
  ): void {
    this.setState({ status: nextStatus });
  }

  /**
   * Gets redirect location from route props.
   * @return Redirect location
   */
  private get redirectLocation(): string {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.from
    ) {
      return (
        this.props.location.state.from.pathname +
        this.props.location.state.from.search
      );
    } else {
      return "/";
    }
  }

  /**
   * Get alert react element.
   * @return Alert element or null.
   * @TODO Edit to be more intuitive.
   * @TODO Edit to use ErrorMessage.
   */
  private get alert(): React.ReactNode {
    const { t } = this.props;
    let alertString = null;
    let alertType: AlertType | null = null;

    if (this.state.status.key == "error") {
      alertString = this.state.status.alertMessage;
      alertType = "error";
    } else if (this.state.status.key == "ready") {
      switch (this.state.status.messageContent) {
        case "forceSignedOut":
          alertString = t("login.forceSignedOut");
          alertType = "error";
          break;
        case "passwordChanged":
          alertString = this.props.t("login.passwordChanged");
          alertType = "success";
          break;
      }
    }
    return alertString && alertType ? (
      <Alert key="sign-in-alert" type={alertType} message={alertString} />
    ) : null;
  }

  /**
   * Callback function for signing in with API key.
   * Initializes user context if successful.
   * @param result Result returned from API.
   */
  private signInWithKeyCallback(result: ApiResult<any>): void {
    if (result.success == true) {
      const redirectLocation = this.redirectLocation;
      this.setState({
        status: {
          key: "success",
          redirectLocation: redirectLocation,
        },
      });
      this.props.local.logInfo("User successfully signed in with apiKey");
    } else {
      this.setState({
        status: {
          key: "error",
          alertMessage: result.message,
        },
      });
      this.props.local.logWarn(
        `User failed to sign in with apiKey: ${result.debugMessage}`
      );
    }
  }

  /**
   * Callback function for signing in.
   * Initializes user context if successful.
   * @param result Result returned from API.
   */
  private signInCallback(result: ApiResult<AccountAuth>): void {
    // private signInCallback(result: bryxApi.ApiResult<models.Auth>): void {
    if (result.success == true) {
      this.props.local.initValuesFromAuthModel(result.value);
      this.props.local.logInfo("User successfully signed in.");
      this.setState({
        status: {
          key: "success",
          redirectLocation: this.redirectLocation,
        },
      });
    } else {
      this.setState({
        status: {
          key: "error",
          alertMessage: result.message,
        },
      });
      this.props.local.logWarn(
        `User failed to sign in: ${result.debugMessage}`
      );
    }
  }

  /**
   * Callback function for signing up.
   * Initializes user context if successful.
   * @param result Result returned from API.
   */
  private signUpCallback(result: ApiResult<AccountAuth>): void {
    // private signInCallback(result: bryxApi.ApiResult<models.Auth>): void {
    if (result.success == true) {
      this.props.local.logInfo("Successful signed up.");
      this.props.local.initValuesFromAuthModel(result.value);
      this.props.local.logInfo("User successfully signed in.");
      this.setState({
        status: {
          key: "success",
          redirectLocation: this.redirectLocation,
        },
      });
    } else {
      this.setState({
        status: {
          key: "error",
          alertMessage: result.message,
        },
      });
      this.props.local.logWarn(
        `User failed to sign up: ${result.debugMessage}`
      );
    }
  }

  /**
   * Callback function for password resest
   * @param result Result returned from API.
   */
  private passwordResetCallback(r: ApiResult<null>): void {
    // private signInCallback(result: bryxApi.ApiResult<models.Auth>): void {
    if (r.success == true) {
      this.props.local.logInfo("Successful password change.");
      this.setState({
        status: {
          key: "success",
          redirectLocation: {
            pathname: this.redirectLocation,
            state: { type: "passwordChanged" },
          },
        },
      });
    } else {
      this.setState({
        status: {
          key: "error",
          alertMessage: r.message,
        },
      });
      this.props.local.logWarn(`Password reset failed: ${r.debugMessage}`);
    }
  }

  /**
   * Handle email change.
   * @param newEmail
   */
  private onEmailChange(newEmail: string): void {
    this.setState({ email: newEmail });
  }

  /**
   * Handle contact support event.
   */
  private onContactSupport(): void {
    this.setState({
      overlayContent: "contactSupport",
    });
  }

  /**
   * Render welcome.
   */
  renderWelcome(): React.ReactNode {
    return <Welcome />;
  }

  /**
   * Render sign in form.
   */
  renderContactForm(): React.ReactNode {
    return (
      <React.Fragment>
        <ContactForm
          status={this.state.status}
          onStatusChange={this.onStatusChange.bind(this)}
        />
        {this.alert}
      </React.Fragment>
    );
  }

  /**
   * Render sign in form.
   */
  renderSignInForm(): React.ReactNode {
    return (
      <React.Fragment>
        <SignInForm
          status={this.state.status}
          onStatusChange={this.onStatusChange.bind(this)}
          signInCallback={this.signInCallback.bind(this)}
        />
        {this.alert}
        <Divider
          className={style.grayDivider}
          style={{ margin: "2rem 0 1.5rem 0" }}
        />
        <Link to="/signup">
          <Button className={style.btn} inverted color="grey" type="button">
            {this.props.t("login.createAccount")}
          </Button>
        </Link>
      </React.Fragment>
    );
  }

  /**
   * Render sign up form.
   */
  renderSignUpForm(): React.ReactNode {
    return (
      <React.Fragment>
        <SignUpForm
          status={this.state.status}
          onStatusChange={this.onStatusChange.bind(this)}
          signUpCallback={this.signUpCallback.bind(this)}
          signInCallback={this.signInCallback.bind(this)}
        />
        {this.alert}
        <Divider
          className={style.grayDivider}
          style={{ margin: "2rem 0 1.5rem 0" }}
        />
        <Link to="/signin">
          <Button className={style.btn} inverted color="grey" type="button">
            {this.props.t("login.haveAccount")}
          </Button>
        </Link>
      </React.Fragment>
    );
  }

  /**
   * Render reset password form.
   * @param match
   */
  renderPasswordResetForm(
    props: RouteComponentProps<{ token: string }>
  ): React.ReactNode {
    return (
      <React.Fragment>
        <PasswordResetForm
          status={this.state.status}
          onStatusChange={this.onStatusChange.bind(this)}
          passwordResetCallback={this.passwordResetCallback.bind(this)}
          token={props.match.params.token}
        />
        {this.alert}
      </React.Fragment>
    );
  }

  /**
   * Get array of nav options based on state.
   * @return Nav options for navigation.
   */
  private get navOptions(): NavMenuItemConfig[] {
    return [
      {
        name: this.props.t("nav.signIn"),
        // icon: <Icon name='sign-in' />,
        path: "/signin",
      },
      {
        name: this.props.t("nav.contactSupport"),
        icon: <Icon name="question" />,
        function: this.toggleSupport.bind(this),
      },
    ];
  }

  /**
   * Render function.
   */
  render(): React.ReactNode {
    if (!this.props.tReady) {
      return <LoadingSpinner />;
    }

    if (this.state.status.key == "success") {
      return <Redirect to={this.state.status.redirectLocation} />;
    }

    const alertMessage = null;

    return (
      <div
        id={contentStyle.ContentWrapper}
        className={signinStyle.signinContent}
      >
        <Nav navOptions={this.navOptions} red external />
        <div id={contentStyle.PageContent} className={signinStyle.signinModal}>
          <Grid verticalAlign="middle" style={{ height: "100%" }}>
            <Grid.Row centered>
              <Grid.Column textAlign="left" id={signinStyle.SigninForm}>
                <Logo />
                <Switch>
                  <Route
                    path="/welcome"
                    render={this.renderWelcome.bind(this)}
                  />
                  <Route
                    path="/contact"
                    render={this.renderContactForm.bind(this)}
                  />
                  <Route
                    path="/signin"
                    render={this.renderSignInForm.bind(this)}
                  />
                  <Route
                    path="/signup"
                    render={this.renderSignUpForm.bind(this)}
                  />
                  <Route
                    path="/password-reset/:token"
                    render={this.renderPasswordResetForm.bind(this)}
                  />
                </Switch>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        <SupportModal
          open={this.state.supportOpen}
          onClose={this.toggleSupport.bind(this)}
        />
      </div>
    );
  }
}

export default withContext(SignIn, "local", "i18n", "api");
