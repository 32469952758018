/** @module components.Account */

/** @module components */

import React, { FormEvent, SyntheticEvent } from 'react';
import { RouteComponentProps } from 'react-router';
import {
  Button,
  Form,
  Grid,
  Input,
  Message,
  Popup,
  Header,
  Modal,
  Segment,
} from 'semantic-ui-react';
import classnames from 'classnames';
import { ApiResult } from '@bryxinc/lunch/models';

import * as style from '@bryxinc/style/main.module.css';
import * as headerStyle from '@bryxinc/style/header.module.css';
import * as color from '@bryxinc/style/color';

import { withErrorBoundary } from '../ErrorBoundary';
import { withContext, WithTranslation, WithLocal, WithApi } from '@bryxinc/lunch/context';
import Page from '../Page';
import Alert from '../Alert';
import BryxApi from '@bryxinc/lunch/utils/AccountApi';

interface DisableProps extends RouteComponentProps, WithTranslation, WithLocal, WithApi<BryxApi> { }

interface DisableAlert {
  type: 'success' | 'error';
  message: string;
}

interface DisableState {
  password: string;
  alert: DisableAlert | null;
  modalOpen: boolean;
}

/**
 * Account password change react component.
 * To be displayed on account landing page.
 */
export class Disable extends React.Component<DisableProps, DisableState> {

  constructor(props: DisableProps) {
    super(props);
    this.state = {
      password: '',
      alert: null,
      modalOpen: false,
    };
  }
  /**
   * Function for handling change password event.
   * @param e Event
   */
  private onChangePassword(e: SyntheticEvent<HTMLInputElement>): void {
    this.setState({
      password: e.currentTarget.value,
    });
  }

  /**
   * Function for toggling modal.
   */
  private toggleModal(): void {
    this.setState({
      password: '',
      alert: null,
      modalOpen: !this.state.modalOpen,
    });
  }

  /**
   * Function for handling form submission event.
   * @param e Event
   */
  private onFormSubmit(e: FormEvent<HTMLFormElement>): void {
    e.preventDefault();
    const { password } = this.state;
    // Check that newPassword and confrimNewPassword match.
    // Show error if not.
    if (!password) {
      this.setState({
        alert: {
          type: 'error',
          message: this.props.t('account.disable.blank'),
        },
      });
      return;
    }

    this.setState({
      alert: null,
    });

    // Send stuff to API and handle response
    this.props.api.disableUser(
      password,
      this.handleDisableResponse.bind(this),
    );
  }

  /**
   * Handle change password api response
   * @param r Response
   */
  private handleDisableResponse(r: ApiResult<any>): void {
    if (r.success) {
      // Actually probably what to display toast and redirect to login,
      // after logging out user.
      this.setState({
        alert: {
          type: 'success',
          message: this.props.t('account.disable.success'),
        },
        password: '',
      });
    } else {
      this.setState({
        alert: {
          type: 'error',
          message: r.message,
        },
      });
      this.props.local.logWarn(`Disable user failed: ${r.debugMessage}`);
    }
  }

  /**
   * Get alert for change password.
   */
  private get alert(): React.ReactNode | null {
    return this.state.alert ? (
      <Alert
        key='change-pw-alert'
        type={this.state.alert.type}
        message={this.state.alert.message} />
    ) : null;
  }

  /**
   * Render function.
   */
  render(): React.ReactNode {
    const { t } = this.props;
    const modal = (
      <Modal open={this.state.modalOpen}
        size='small'
        onClose={this.toggleModal.bind(this)}>
        <h3 className={classnames(headerStyle.bryxHeader, headerStyle.inModal)}>
          {this.props.t('account.disable.header')}
        </h3>
        <Modal.Content>
          {this.alert}
          <Form>
            <Form.Field>
              <Input required
                type='password'
                placeholder={this.props.t('account.disable.password')}
                value={this.state.password || ''}
                disabled={!this.props.tReady}
                onChange={this.onChangePassword.bind(this)} />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            className={style.btn}
            content={this.props.t('general.cancel').toUpperCase()}
            onClick={this.toggleModal.bind(this)}
          />
          <Button
            className={style.btn}
            content={this.props.t('account.disable.submit').toUpperCase()}
            loading={!this.props.tReady}
            style={{ color: color.red, letterSpacing: '1px' }}
            onClick={this.onFormSubmit.bind(this)}
          />
        </Modal.Actions>
      </Modal>
    );
    return (
      <Page
        title={t('account.disable.header')}
        descr={t('account.disable.text')}
      >
      <Segment compact style={{ marginRight: 'auto', marginLeft: 'auto' }}>
        <Button
          className={style.btn}
          content={this.props.t('account.disable.submit').toUpperCase()}
          loading={!this.props.tReady}
          style={{
            color: color.red,
            letterSpacing: '1px',
            maxWidth: '500px',
            minWidth: '450px',
            width: '25%',
          }}
          onClick={this.toggleModal.bind(this)}
        />
        </Segment>
        {modal}
      </Page>
    );
  }
}

export default withErrorBoundary(withContext(Disable, 'i18n', 'local', 'api'));
