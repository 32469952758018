/** @module components.input */

import * as React from 'react';
import { FormEvent, SyntheticEvent } from 'react';
import { Dropdown, Form, Grid, Input, Popup, Select } from 'semantic-ui-react';

import { ProductTypeKind } from '@bryxinc/lunch/models';
// import * as style from '@bryxinc/style/main.module.css';

import { withContext, WithTranslation, WithApi } from '@bryxinc/lunch/context';

interface ProductInterestProps extends WithTranslation {
  products?: ProductTypeKind[];
  onChange?: (n: ProductTypeKind[], v?: boolean | null) => void;
  labels?: boolean;
  placeholders?: boolean;
  required?: boolean;
  disabled?: boolean;
  inline?: boolean;
  style?: object;
  className?: string;
}
interface ProductInterestState {
  focus: boolean | null;
}

/**
 * Product interest input component.
 */
export class ProductInterest extends React.Component<ProductInterestProps, ProductInterestState> {
  static defaultProps: Partial<ProductInterestProps> = {
    products: [],
    onChange: (n: ProductTypeKind[], v?: boolean | null) => { return; },
    labels: false,
    placeholders: false,
    required: false,
    disabled: false,
    inline: false,
    style: {},
    className: '',
  };
  readonly state: ProductInterestState = { focus: null };

  /**
   * Handler for change.
   */
  private onChange(
    e: SyntheticEvent<HTMLElement>,
    { value }: { value: ProductTypeKind[] },
  ): void {
    const { onChange, required } = this.props as Required<ProductInterestProps>;
    onChange(value, required ? value.length > 0 : true);
  }

  /**
   * Handle focus.
   */
  private onFocus(e: SyntheticEvent): void {
    this.setState({
      focus: !this.state.focus,
    });
  }

  /**
   * Getter for product options.
   */
  private get productOpts(): object[] {
    const { tReady, t } = this.props;
    if (!tReady) {
      return [];
    }
    return [
      { key: ProductTypeKind.bryx911, value: ProductTypeKind.bryx911, text: t('branding.product.bryx911') },
      { key: ProductTypeKind.bryxStation, value: ProductTypeKind.bryxStation, text: t('branding.product.bryxStation') },
      { key: ProductTypeKind.bryxHospital, value: ProductTypeKind.bryxHospital, text: t('branding.product.bryxHospital') },
    ];
  }

  /**
   * Get select props.
   */
  get selectProps(): any {
    const {
      products, t, tReady, onChange,
      required, disabled, inline, labels, placeholders,
      style: compStyle, className,
    } = this.props;
    const { focus } = this.state;
    const p: any = {
      key: 'product-select',
      loading: !tReady,
      inline,
      multiple: true,
      value: products || [],
      options: this.productOpts,
      onChange: this.onChange.bind(this),
      onFocus: this.onFocus.bind(this),
      onBlur: this.onFocus.bind(this),
      required,
      disabled,
      style: compStyle,
      className,
    };
    if (
      required && focus === false &&
      (products === undefined || products.length == 0)
    ) {
      p.error = { content: t('form.fieldRequired') };
    }
    if (labels) {
      p.label = t('form.label.productInterest');
    }
    if (placeholders) {
      p.placeholder = t('form.label.productInterest');
    }
    return p;
  }

  /**
   * Render function.
   */
  render(): React.ReactNode {
    return <Form.Select {...this.selectProps} />;
  }
}

export default withContext(ProductInterest, 'i18n');
