/** @module components */

import React, { CSSProperties } from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import { ApiResult /*Eula as EulaModel*/ } from '@bryxinc/lunch/models';
import { Button, Loader, Modal, Icon, SemanticICONS } from 'semantic-ui-react';

import * as style from '@bryxinc/style/main.module.css';
import * as color from '@bryxinc/style/color';

import LoadingSpinner from './LoadingSpinner';
import {WithApi, withContext, WithLocal} from '@bryxinc/lunch/context';
import {WithTranslation} from 'react-i18next';
import BryxApi from '@bryxinc/lunch/utils/AccountApi';

interface EulaModel {
  eulaVersion: number;
  eula: string;
}

interface ZeroStateViewProps {
  header: React.ReactNode;
  subheader?: React.ReactNode;
  icon?: SemanticICONS;
  keyTooltip?: string;
  maxWidth?: string;
  style?: CSSProperties;
}

/**
 * Zero state view component.
 * @WARN This should have its own file.
 */
export class ZeroStateView extends React.Component<ZeroStateViewProps> {
  /**
   * Render function.
   */
  render(): React.ReactNode {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: this.props.maxWidth, ...this.props.style }}>
        {
          this.props.icon != null ? (
            <Icon circular
              size='big'
              name={this.props.icon}
              style={{ color: color.offWhite }} />
          ) : null
        }
        <span style={{
          display: 'block',
          color: color.gray,
          fontSize: '25px',
          margin: '15px 10px 12px',
          textAlign: 'center',
        }}>
          {this.props.header}
        </span>
        {
          this.props.subheader != null ? (
            <span style={{
              display: 'block',
              color: color.offWhite,
              fontSize: '16px',
              margin: '0 15px',
              textAlign: 'center',
            }}>
              {this.props.subheader}
            </span>
          ) : null
        }
      </div>
    );
  }
}

interface EulaProps extends RouteComponentProps, WithTranslation, WithLocal, WithApi<BryxApi> { }

interface EulaPageState {
  status: { key: 'ready', eula: string } |
  { key: 'error', message: string } |
  { key: 'loading' } |
  { key: 'accepted' } |
  { key: 'denied' } |
  { key: 'sending' };
}

/**
 * EULA page component.
 * @TODO Create css styles for EULA
 * @TODO This should probably be a modal that pops up over/instead of main app content instead of routed to via MainRouter
 */
export class Eula extends React.Component<EulaProps, EulaPageState> {
  readonly state: EulaPageState = { status: { key: 'loading' } };

  /**
   * Execute on component mount.
   */
  componentDidMount(): void {
    // this.props.local.set('showEula', true);

    if (this.props.local.showEula == true) {
      this.props.api.getEula((result: ApiResult<EulaModel>) => {
        if (result.success == true) {
          this.setState({ status: { key: 'ready', eula: result.value.eula } });
        } else {
          this.props.local.logError(`Failed to load EULA: ${result.debugMessage}`);
          this.setState({ status: { key: 'error', message: result.message } });
        }
      });
    }
  }

  /**
   * Handle accept EULA.
   */
  private acceptEula(): void {
    this.setState({ status: { key: 'sending' } });

    this.props.api.acceptEula((result: ApiResult<null>) => {
      if (result.success == true) {
        this.setState({ status: { key: 'accepted' } });
        this.props.local.set('showEula', false);
      } else {
        this.props.local.logWarn(`User failed to accept EULA: ${result.debugMessage}`);
        this.setState({ status: { key: 'error', message: result.message } });
      }
    });
  }

  /**
   * Handle deny EULA.
   */
  private denyEula(): void {
    this.setState({ status: { key: 'denied' } });
    this.props.local.clear();
  }

  /**
   * Render function.
   */
  render(): React.ReactNode {
    if (this.props.local.showEula != true) {
      return <Redirect to='/' />;
    }

    let content = null;

    if (this.state.status.key == 'loading' || this.props.tReady == false) {
      content = <LoadingSpinner />;
    } else if (this.state.status.key == 'error') {
      content = (
        <div className={style.flexCenteredContainer} style={{ width: '100%', height: '100%' }}>
          <ZeroStateView header={this.props.t('eula.errorHeader')}
            subheader={this.props.t('eula.error')}
            maxWidth={'50%'} />
        </div>
      );
    } else if (this.state.status.key == 'ready') {
      content = this.state.status.eula;
    } else if (this.state.status.key == 'accepted') {
      if (this.props.location.state && this.props.location.state.from) {
        return <Redirect to={this.props.location.state.from} />;
      } else {
        return <Redirect to='/' />;
      }
    } else if (this.state.status.key == 'denied') {
      return <Redirect to='/login' />;
    }

    return (
      <div style={{ height: '100%', width: '100%', backgroundColor: color.darkRed }}>
        <Modal open style={{ display: 'flex', flexDirection: 'column' }}>
          <Modal.Header>
            {this.props.t('eula.header')}
          </Modal.Header>
          <Modal.Content style={{ flex: 1, display: 'flex', flexDirection: 'column', whiteSpace: 'pre-wrap', height: '500px', padding: 0 }}>
            <div style={{ flex: 1, overflowY: 'auto', padding: '15px 20px' }}>
              {content}
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button positive
              loading={this.state.status.key == 'sending'}
              onClick={this.acceptEula.bind(this)}
              content={this.props.t('eula.accept')} />
            <Button negative
              onClick={this.denyEula.bind(this)}
              disabled={this.state.status.key == 'sending'}
              content={this.props.t('eula.deny')} />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default withContext(Eula, 'i18n', 'local', 'api');
