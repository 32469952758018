/**
 * @module components.SignIn
 */

import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button,  Divider, Grid } from 'semantic-ui-react';
import { withContext, WithTranslation } from '@bryxinc/lunch/context';
import LoadingSpinner from '../LoadingSpinner';
import classnames from 'classnames';

import * as signinStyle from '@bryxinc/style/signin.module.css';
import * as headerStyle from '@bryxinc/style/header.module.css';
import * as style from '@bryxinc/style/main.module.css';
import * as color from '@bryxinc/style/color';

/**
 * Welcome component.
 * Inquires if user's agency is Bryx enabled.
 */
export class Welcome extends React.Component<WithTranslation> {
  /**
   * Render component.
   */
  render(): React.ReactNode {
    // Use tReady prop to check if translations ready.
    if (!this.props.tReady) {
      return <LoadingSpinner />;
    }

    return (
      <Grid verticalAlign='middle' className={signinStyle.signinBtnGrid}>
        <Grid.Row centered>
          <h1 className={classnames(headerStyle.bryxHeader, headerStyle.white)}>
            {this.props.t('login.isUsingBryx')}
          </h1>
        </Grid.Row>
        <Grid.Row columns={2} centered className={signinStyle.signinBtnRow}>
          <Grid.Column className={signinStyle.signinBtnCol}>
            <Link to='/signin'>
              <Button
                className={style.btn}
                inverted
                color='grey'
                type='button'
              >
                {this.props.t('general.yes')}
              </Button>
            </Link>
          </Grid.Column>
          <Grid.Column className={signinStyle.signinBtnCol}>
            <Link to='/contact'>
              <Button
                className={style.btn}
                inverted
                color='grey'
                type='button'
              >
                {this.props.t('general.no')}
              </Button>
            </Link>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default withContext(Welcome, 'i18n');
