/** @module components.Agencies.Manage */

import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import {
  Accordion,
  Button,
  Grid,
  Icon,
  Label,
  Modal,
  Pagination,
  Tab,
  Table,
  Segment,
} from 'semantic-ui-react';

import { ApiResult, PageResult, UserAgency, MinimalDispatchGroup } from '@bryxinc/lunch/models';
import * as style from '@bryxinc/style/main.module.css';
import * as color from '@bryxinc/style/color';

import Alert from '../../Alert';
import { withContext, WithTranslation, WithApi, WithLocal } from '@bryxinc/lunch/context';
import BryxApi from '@bryxinc/lunch/utils/AccountApi';

interface AgenciesProps extends WithTranslation, WithApi<BryxApi>, WithLocal { }

interface AgenciesState {
  activePage: number;
  count: number;
  agencies: UserAgency[];
  open: boolean[];
  loading: boolean;
  error: { message: string, data?: any } | { i18nKey: string, data?: any } | false;
  modalOpen: boolean;
  activeAgency: number;
  activeGroup: number | null;
}

/**
 * Manage agencies react component.
 */
export class Agencies extends React.Component<AgenciesProps, AgenciesState> {
  static readonly PER_PAGE: number = 10;

  readonly state: AgenciesState = {
    activePage: 1,
    count: 0,
    agencies: [],
    open: [],
    loading: true,
    error: false,
    modalOpen: false,
    activeAgency: -1,
    activeGroup: null,
  };

  /**
   * Executes on component mounting.
   */
  componentDidMount(): void {
    const { api } = this.props;
    api.getUserAgencies(
      this.userAgenciesCB.bind(this),
      0,
      Agencies.PER_PAGE,
    );
  }

  /**
   * Callback for getting user agencies.
   */
  private userAgenciesCB(r: ApiResult<PageResult<UserAgency>>): void {
    // If success, else, display error.
    if (r.success) {
      this.setState({
        agencies: r.value.items,
        count: r.value.count,
        open: r.value.items.map(() => false),
        loading: false,
        error: false,
      });
    } else {
      this.setState({
        error: {
          i18nKey: 'agencies.manage.error',
          data: {
            message: r.message,
            debugMessage: r.debugMessage,
          },
        },
        loading: false,
      });
    }
  }

  /**
   * Callback for leaving user agency.
   */
  private leaveUserAgencyCB(r: ApiResult<null>): void {
    // If success, else, display error.
    if (r.success) {
      this.props.api.getUserAgencies(
        this.userAgenciesCB.bind(this),
        this.state.activePage - 1,
        Agencies.PER_PAGE,
      );
    } else {
      this.setState({
        error: {
          i18nKey: 'agencies.manage.error',
          data: {
            message: r.message,
            debugMessage: r.debugMessage,
          },
        },
      });
    }
  }

  /**
   * Handle page change.
   */
  private onPageChange(
    e: React.SyntheticEvent,
    { activePage, totalPages }: { activePage: number, totalPages: number },
  ): void {
    const { api } = this.props;
    this.setState({ activePage });
    api.getUserAgencies(
      this.userAgenciesCB.bind(this),
      activePage - 1,
      Agencies.PER_PAGE,
    );
  }

  /**
   * Open modal.
   * @param i Index of agency to leave.
   * @param j Optional index of group in agency to leave.
   */
  private openModal(i: number, j?: number): () => void {
    if (typeof j === 'number' && j >= 0) {
      return () => {
        this.setState({
          modalOpen: true,
          activeAgency: i,
          activeGroup: j,
        });
      };
    }
    return () => {
      this.setState({
        modalOpen: true,
        activeAgency: i,
        activeGroup: null,
      });
    };
  }

  /**
   * Close modal
   */
  private closeModal(): void {
    this.setState({
      modalOpen: false,
      activeAgency: -1,
      activeGroup: null,
    });
  }

  /**
   * Handle agency click.
   */
  private onAgencyClick(i: number): () => void {
    return () => {
      const { open } = this.state;
      open[i] = !open[i];
      this.setState({ open });
    };
  }

  /**
   * Handle leave agency click.
   */
  private onAgencyLeave(i: number): () => void {
    return () => {
      const agency = this.state.agencies[i];
      this.props.api.leaveUserAgency(
        agency.id,
        this.leaveUserAgencyCB.bind(this),
      );
      this.closeModal();
    };
  }

  /**
   * Handle leave agency group click.
   */
  private onAgencyGroupLeave(i: number, j: number): () => void {
    return () => {
      const agency = this.state.agencies[i];
      const group = (agency.dispatchGroups || [])[j] || {};
      this.props.api.leaveUserAgencyGroup(
        group.id,
        this.leaveUserAgencyCB.bind(this),
      );
      this.closeModal();
    };
  }

  /**
   * Agency leave confirmation modal.
   */
  private get leaveModal(): React.ReactNode {
    const { t } = this.props;
    const { modalOpen, activeAgency, activeGroup, agencies } = this.state;
    if (activeAgency < 0) { return null; }
    const content = activeGroup !== null ? t(
      'agencies.manage.leaveAgencyGroup',
      {
        agency: agencies[activeAgency].name,
        group: ((agencies[activeAgency].dispatchGroups || [])[activeGroup] || {}).name,
      },
    ) : t(
      'agencies.manage.leaveAgency',
      {
        agency: agencies[activeAgency].name,
      },
      );
    const button = activeGroup ? (
      <Button
        positive
        icon='check'
        content={t('general.yes')}
        onClick={this.onAgencyGroupLeave(activeAgency, activeGroup).bind(this)}
      />
    ) : (
        <Button
          positive
          icon='check'
          content={t('general.yes')}
          onClick={this.onAgencyLeave(activeAgency).bind(this)}
        />
      );
    return (
      <Modal size='mini' open={modalOpen} onClose={this.closeModal.bind(this)}>
        <Modal.Header>{t('agencies.manage.confirmLeave')}</Modal.Header>
        <Modal.Content><p>{content}</p></Modal.Content>
        <Modal.Actions>
          <Button
            negative
            content={t('general.no')}
            onClick={this.closeModal.bind(this)}
          />
          {button}
        </Modal.Actions>
      </Modal >
    );
  }

  /**
   * Agency table row react nodes.
   */
  private get agencyRows(): React.ReactNode[] {
    const { t, api, local } = this.props;
    const { agencies, open } = this.state;
    // return [];
    const agenciesMod = agencies.map((a: UserAgency, i: number) => {
      const onClick = this.onAgencyClick(i).bind(this);
      const manager = a.isManager ? (
        <a href={`${api.manageUrl}/login?apiKey=${local.apiKey}`}>
          <Label color='green' horizontal style={{ marginLeft: '5px' }}>
            {t('general.manager')}
          </Label>
        </a>
      ) : null;
      if (!a.dispatchGroups) {
        return [(
          <Table.Row key={i}>
            <Table.Cell collapsing />
            <Table.Cell collapsing colSpan='2'>
              {a.name}{manager}
            </Table.Cell>
            <Table.Cell textAlign='center'>
              <Button
                negative
                icon='sign-out'
                onClick={this.openModal(i).bind(this)}
              />
            </Table.Cell>
          </Table.Row>
        )];
      }
      const groups = a.dispatchGroups.map((g: MinimalDispatchGroup, j: number) => (
        <Accordion.Content
          key={`${i}.${j}`}
          active={open[i]}
          as={Table.Row}
        >
          <Table.Cell collapsing />
          <Table.Cell collapsing colSpan='2'>{g.name}</Table.Cell>
          <Table.Cell textAlign='center'>
            <Button
              negative
              icon='sign-out'
              onClick={this.openModal(i, j).bind(this)}
            />
          </Table.Cell>
        </Accordion.Content>
      ));
      const agencyRow = (
        <Accordion.Title
          key={i}
          active={open[i]}
          as={Table.Row}
        >
          <Table.Cell collapsing onClick={onClick}>
            <Icon name={open[i] ? 'minus' : 'plus'} />
          </Table.Cell>
          <Table.Cell collapsing colSpan='2' onClick={onClick}>
            {a.name}{manager}
          </Table.Cell>
          <Table.Cell textAlign='center'>
            <Button
              negative
              icon='sign-out'
              onClick={this.openModal(i).bind(this)}
            />
          </Table.Cell>
        </Accordion.Title>
      );
      return [agencyRow, ...groups];
    });
    return agenciesMod.reduce((a: React.ReactNode[], e: React.ReactNode[]) => {
      return [...a, ...e];
    }, []);
  }

  /**
   * Agency table react node.
   */
  private get agencyTable(): React.ReactNode {
    // return null;
    const { t } = this.props;
    return (
      <Table selectable key='agencies' unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan='3' collapsing>{t('general.agencyName')}</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Accordion as={Table.Body}>
          {...this.agencyRows}
        </Accordion>
      </Table>
    );
  }

  /**
   * Pagination react node.
   */
  private get pagination(): React.ReactNode {
    const { activePage, count } = this.state;
    const totalPages = Math.max(Math.ceil(count / Agencies.PER_PAGE), 1);
    return (
      <Pagination
        key='pagination'
        activePage={activePage}
        onPageChange={this.onPageChange.bind(this)}
        totalPages={totalPages}
        ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
        firstItem={{ content: <Icon name='angle double left' />, icon: true }}
        lastItem={{ content: <Icon name='angle double right' />, icon: true }}
        prevItem={{ content: <Icon name='angle left' />, icon: true }}
        nextItem={{ content: <Icon name='angle right' />, icon: true }}
      />
    );
  }

  /**
   * Error row, if error.
   */
  private get error(): React.ReactNode | null {
    if (!this.state.error) {
      return null;
    }
    return (
      <Grid.Column>
        <Alert
          type='error'
          {...this.state.error}
        />
      </Grid.Column>
    );
  }

  /**
   * Render function.
   */
  render(): React.ReactNode {
    return (
      <Tab.Pane loading={this.state.loading}>
        <Grid centered container columns='1'>
          {this.error}
          <Grid.Column textAlign='center' width='14'>{this.pagination}</Grid.Column>
          <Grid.Column textAlign='center' width='14'>{this.agencyTable}</Grid.Column>
          <Grid.Column textAlign='center' width='14'>{this.pagination}</Grid.Column>
        </Grid>
        {this.leaveModal}
      </Tab.Pane>
    );
  }
}

export default withContext(Agencies, 'i18n', 'api', 'local');
