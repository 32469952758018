/** @module components.Agencies.View */

import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Grid, Tab } from 'semantic-ui-react';

import classnames from 'classnames';

import * as style from '@bryxinc/style/main.module.css';
import * as headerStyle from '@bryxinc/style/header.module.css';
import * as color from '@bryxinc/style/color';

import Page from '../../Page';
import { withContext, WithTranslation, WithApi } from '@bryxinc/lunch/context';
import Agencies from './Agencies';
import JoinRequests from './JoinRequests';
import BryxApi from '@bryxinc/lunch/utils/AccountApi';

interface ViewProps extends RouteComponentProps, WithTranslation, WithApi<BryxApi> { }

interface ViewState {
  activeIndex: number;
}

/**
 * View agencies react component.
 *
 * Endpoints to be used:
 *  - getUserAgencies
 *  - getUserJoinRequests
 *  - cancelJoinRequest
 *  - leaveAgency (?)
 */
export class View extends React.Component<ViewProps, ViewState> {

  constructor(props: ViewProps) {
    super(props);
    const { match: { params } } = props;
    this.state = {
      activeIndex: (params as any).collection === 'join-requests' ? 1 : 0,
    };
  }

  /**
   * Getter function for tab panes.
   */
  private get panes(): object[] {
    if (!this.props.tReady) {
      return [];
    }
    return [
      {
        menuItem: this.props.t('agencies.manage.agencies.header'),
        render: () => <Agencies />,
      },
      {
        menuItem: this.props.t('agencies.manage.joinRequests.header'),
        render: () => <JoinRequests />,
      },
    ];
  }

  /**
   * Handle tab change
   */
  private onTabChange(
    e: React.SyntheticEvent,
    { activeIndex }: { activeIndex: number },
  ): void {
    const { match, history } = this.props;
    switch (activeIndex) {
      case 0:
        history.push('/agencies/view/agencies');
        break;
      case 1:
        history.push('/agencies/view/join-requests');
        break;
    }
    this.setState({ activeIndex });
    return;
  }

  /**
   * Render function.
   */
  render(): React.ReactNode {
    return (
      <Page
        title={this.props.t('agencies.manage.header')}
      >
        <Tab
          menu={{
            /*secondary: true,*/
            attached: false, tabular: false,
            style: {
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              width: 'fit-content',
              marginRight: 'auto',
              marginLeft: 'auto',
            },
          }}
          panes={this.panes}
          activeIndex={this.state.activeIndex}
          onTabChange={this.onTabChange.bind(this)}
        />
      </Page>
    );
  }
}

export default withContext(View, 'i18n', 'api');
