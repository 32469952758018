/** @module index */
/**
 * Entrypoint
 */

import * as React from 'react';
import * as ReactDom from 'react-dom';
import MainRouter from './components/MainRouter';

import { Config } from '@bryxinc/lunch/models/config';
import BryxApi from '@bryxinc/lunch/utils/AccountApi';
import BryxLocalStorage from '@bryxinc/lunch/utils/BryxLocalStorage';

import testResponses from './testResponses';

import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

import logger from './logger';
import {ApiContext, LocalContext} from '@bryxinc/lunch/context';

// Importing styles
// import * as semanticStyle from '@bryxinc/style/semantic/semantic.css';
// const semanticStyle = require('semantic-ui-css/semantic.css'); // tslint:disable-line
const semanticStyle = require('@bryxinc/style/semantic/semantic.css'); // tslint:disable-line
const leafletStyle = require('leaflet/dist/leaflet.css'); // tslint:disable-line
const baseStyle = require('@bryxinc/style/base.module.css'); // tslint:disable-line

// Creating config from environment
declare const VERSION: string;
declare const STYLE_VERSION: string;
declare const BREAKFAST_VERSION: string;

/**
 * Get config.
 * @param d NODE_ENV
 * @param l LOCALE
 * @return config
 */
function getConfig(d: string | undefined, l: string | undefined, b: string): Config {
  switch (d) {
    case 'dev':
      console.log('Dev config loaded');
      return {
        baseUrl: b,
        serverType: 'dev',
        serverLocale: (l == 'us' || l == 'ca' ? l : 'us'),
        site: 'account',
        version: VERSION,
      };
    case 'k8s':
      console.log('k8s config loaded');
      return {
        baseUrl: b,
        serverType: 'k8s',
        serverLocale: (l == 'us' || l == 'ca' ? l : 'us'),
        site: 'account',
        version: VERSION,
      };
    case 'production':
      console.log('Prod config loaded');
      return {
        baseUrl: b,
        serverType: 'prod',
        serverLocale: (l == 'us' || l == 'ca' ? l : 'us'),
        site: 'account',
        version: VERSION,
      };
    case 'test':
      return {
        baseUrl: 'localhost',
        serverType: 'test',
        serverLocale: (l == 'us' || l == 'ca' ? l : 'us'),
        site: 'account',
        version: VERSION,
      };
    default:
      throw new Error(`Bad deployment: ${d} -- No config for this deployment`);
  }
}
const config = getConfig(process.env.NODE_ENV, process.env.LOCALE, process.env.BASE_URL || '');
console.log(`NODE_ENV = ${process.env.NODE_ENV}`);
console.log(`LOCALE = ${process.env.LOCALE}`);
console.log(`BASE_URL = ${process.env.BASE_URL}`);
console.log(config);

const mainRouter = React.createElement(MainRouter);
const i18nProviderElement = React.createElement(
  I18nextProvider,
  { i18n: i18n },
  mainRouter,
);
const local = new BryxLocalStorage(config);
const localProvider = React.createElement(
  LocalContext.Provider,
  { value: local },
  i18nProviderElement,
);
const apiProvider = React.createElement(
  ApiContext.Provider,
  { value: new BryxApi(config, local) },
  localProvider,
);

ReactDom.render(
  apiProvider,
  document.getElementById('content'),
);
// */
