/** @module components.Nav */

import * as React from 'react';
import { Link } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import brandSvg from '@bryxinc/style/Bryx-Brand.svg';
import * as brandingStyle from '@bryxinc/style/branding.module.css';
import { withContext, WithTranslation, WithApi } from '@bryxinc/lunch/context';
import LoadingSpinner from '../LoadingSpinner';
import BryxApi from '@bryxinc/lunch/utils/AccountApi';

interface BrandingProps extends WithTranslation, WithApi<BryxApi> {}
/**
 * Branding component.
 */
export class Branding extends React.Component<BrandingProps> {

  /**
   * Static function to preprocess inline SVGs.
   */
  static svgPreProcessor(code: string): string {
    return code.replace(/<!--(((?!-->)|\n).)*-->/gm, '')
        .replace(/<defs>(((?!<\/defs>)|\n).)*<\/defs>/gm, '')
        .replace(
      /(class|id)="([a-zA-Z]*?)"/g,
      (m: string, p1: string, p2: string) => {
        const sty = (brandingStyle as { [k: string]: any })[p2];
        if (!sty) {
          return '';
        }
        const next = `${p1}="${sty}"`;
        return next;
      },
    );
  }

  /**
   * Render function.
   */
  render(): React.ReactNode {
    return (
      <a href='/' className={brandingStyle.branding}>
        <SVG
          src={brandSvg}
          preProcessor={Branding.svgPreProcessor}
        />
      </a>
    );
  }
}

export default withContext(Branding, 'i18n', 'api');
