/** @module components */

import * as React from 'react';
import { Grid } from 'semantic-ui-react';
import classnames from 'classnames';

import * as style from '@bryxinc/style/main.module.css';
import * as headerStyle from '@bryxinc/style/header.module.css';
import * as color from '@bryxinc/style/color';

import ErrorBoundary from './ErrorBoundary';

// import { withContext, WithTranslation } from '../context';
// type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never };
// type XOR<T, U> = (T | U) extends object ? (Without<T, U> & U) | (Without<U, T> & T) : T | U;
// type WithTitle = XOR<{ title: string }, { titleKey: string }>;
// type WithDescr = XOR<{ description?: string }, { descrKey?: string }>;
// type NewPageProps = WithTranslation & WithTitle & WithDescr;

interface PageProps {
  title?: string;
  descr?: string;
}

/**
 * Generic page template.
 */
export default class Page extends React.Component<PageProps> {

  private get descr(): React.ReactNode {
    if (this.props.descr) {
      return (
        <span>
          {this.props.descr}
        </span>
      );
    }
    return null;
  }

  private get title(): React.ReactNode {
    if (this.props.title) {
      return (
        <Grid.Row centered>
          <Grid.Column textAlign='center' mobile='16' tablet='15' computer='15' largeScreen='12' widescreen='10'>
            <h1 className={classnames(headerStyle.bryxHeader, headerStyle.pageHeader)}>
              {this.props.title}
            </h1>
            {this.descr}
          </Grid.Column>
        </Grid.Row>
      );
    }
    return null;
  }

  /**
   * Render function.
   */
  render(): React.ReactNode {
    return (
      <Grid
        centered
        verticalAlign='middle'
        style={{ height: '100%', paddingTop: '3em' }}
      >
        {this.title}
        <Grid.Row centered>
          <Grid.Column textAlign='center' mobile='16' tablet='15' computer='15' largeScreen='12' widescreen='10'>
            <ErrorBoundary>
              {this.props.children}
            </ErrorBoundary>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
