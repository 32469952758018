/** @module components.Account */
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Route, Switch } from 'react-router-dom';

import NotFound from '../NotFound';
import Dashboard from './Dashboard';
import Disable from './Disable';
import Password from './Password';
import Settings from './Settings';
import EmailAlerts from './EmailAlerts';

/**
 * Account page component.
 * Manages switch for all sub pages.
 */
export default class Account extends React.Component<RouteComponentProps> {
  /**
   * Render function.
   */
  render(): React.ReactNode {
    return (
      <Switch>
        <Route path='/account' exact component={Dashboard} />
        <Route path='/account/disable' exact component={Disable} />
        <Route path='/account/password' exact component={Password} />
        <Route path='/account/settings' exact component={NotFound} />
        <Route path='/account/emailalerts' exact component={EmailAlerts} />
        <Route path='/account/email' exact component={NotFound} />
        <Route component={NotFound} />
      </Switch>
    );
  }
}
