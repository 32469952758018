/** @module components */
import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import Alert from './Alert';
import Page from './Page';
import LoadingSpinner from './LoadingSpinner';
import { withContext, WithTranslation, WithLocal } from '@bryxinc/lunch/context';

interface NotFoundProps extends RouteComponentProps, WithTranslation, WithLocal { }

/**
 * Not found page component.
 * To be displayed on visiting an undefined route.
 */
export class NotFound extends React.Component<NotFoundProps> {
  /**
   * On component mount, log missing path.
   */
  componentDidMount(): void {
    const { local, location: { pathname } } = this.props;
    local.logError(`404 Page not found: ${pathname}`);
  }

  /**
   * Render function.
   */
  render(): React.ReactNode {
    const { t, tReady } = this.props;
    if ( !tReady ) {
      return <LoadingSpinner transparent />;
    }
    return (
      <Page
        title={t('notFound.header')}
      >
        <Alert
          type='error'
          i18nKey='notFound.message'
          data={{ path: this.props.location.pathname }}
        />
      </Page>
    );
  }
}

export default withContext(NotFound, 'i18n', 'local');
