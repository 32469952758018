/** @module components */

import * as React from 'react';
import { RouteComponentProps, RouteProps } from 'react-router';
import {
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import classnames from 'classnames';
import { Icon } from 'semantic-ui-react';
import SVG from 'react-inlinesvg';
// import ApiContext from './ApiContext';
// import UserContext from './UserContext';
import { withContext, WithTranslation, WithLocal, WithApi } from '@bryxinc/lunch/context';
import LoadingSpinner from './LoadingSpinner';
import SupportModal from './SupportModal';
import Dashboard from './Dashboard';
import Account from './Account';
import Agencies from './Agencies';
import NotFound from './NotFound';

import Nav, { NavMenuItemConfig } from './Nav';

import Map from './Map';

import { ApiResult } from '@bryxinc/lunch/models';
import logo_white from '@bryxinc/style/logo_white.png';
import * as style from '@bryxinc/style/content.module.css';
import * as color from '@bryxinc/style/color';
import signOutSvg from '@bryxinc/style/sign_out_icon.svg';
import supportSvg from '@bryxinc/style/support_icon.svg';
import BryxApi from '@bryxinc/lunch/utils/AccountApi';

declare const VERSION: string;
declare const STYLE_VERSION: string;
declare const BREAKFAST_VERSION: string;

type Status = { key: 'signedIn' | 'signedOut', type?: string } | { key: 'signedIn' } | { key: 'signedOut', type: 'standard' } | { key: 'signedOut', type: 'forced' };

interface MainProps extends RouteComponentProps, WithTranslation, WithLocal, WithApi<BryxApi> { }

interface MainState {
  supportOpen: boolean;
  isLoading: boolean;
  status: Status;
}
/**
 * Main component.
 */
// export class Main extends React.Component<RouteProps, any> {
export class Main extends React.Component<MainProps, any> {
  /**
   * Minimum splash time
   */
  private static readonly minimumSplashTime: number = 2.0 * 1000;

  /**
   * Creates Main component.
   */
  constructor(props: any) {
    super(props);

    const status: Status = { key: props.local.isSignedIn() ? 'signedIn' : 'signedOut' };
    if (props.location && props.location.state && props.location.state.type) {
      status.type = props.location.state.type;
    } else {
      if (!props.local.isSignedIn()) {
        if (props.location && props.location.pathname === '/') {
          status.type = 'newVistor';
        } else {
          status.type = 'standard';
        }
      }
    }

    this.state = {
      isLoading: props.local.isSignedIn(),
      status: status,
      // switchAgencyModalStatus: props.local.getCurrentAgencyId() == null ? 'forced' : 'hidden',
      agencies: [],
      selectedAgencyId: null, // Will be reset by session
      invalidAgencyId: null,
      pendingJoinRequestsBadge: { key: 'loading' }, // BadgeManager.shared.pendingJoinRequestStatus,
      supportOpen: false,
    }; // */
  }

  /**
   * Handle component mount:
   * - Add window scroll listener
   * @TODO Check other actions done on mount.
   */
  componentDidMount(): void {
    this.props.api.setOnUnauthenticated(() => {
      console.log('onUnauthenticated function set by Main component placeholder.');
      this.setState({
        isLoading: false,
        status: { key: 'signedOut', type: 'forced' },
      });
      this.props.local.clear(); // Clear all account data from local storage on successful logout, probably/possibly no necessary
      this.props.local.logInfo('Forced sign out occurred');
      // this.props.local.clear();
      // this.setState({
      //   isLoading: false,
      //   status: { key: 'signedOut', type: 'forced' },
      // });
    });
    this.loadSession();
  }

  /**
   * Load session private function
   */
  private loadSession(): void {
    if (this.props.local.isSignedIn()) {
      const preSessionTime = Date.now();
      this.props.api.session((result: any) => {
        const postSessionTime = Date.now();
        if (result.success == true) {
          this.props.local.logInfo('User successfully updated session');

          // Only need to handle this case because of ^^^
          if (this.props.local.showEula != true) {
            setTimeout(
              () => {
                this.setState({
                  isLoading: false,
                  status: { key: 'signedIn' },
                });
              },
              Math.max(Main.minimumSplashTime - (postSessionTime - preSessionTime), 0),
            );
          } else {
            this.props.history.push('/eula', {
              from: this.props.location,
            });
          }

        } else {
          this.props.local.logWarn(`Failed to update session: ${result.debugMessage}`);
        }
      });
    }
  }

  /**
   * Handle signout.
   * @param result
   */
  private onSignout(result: ApiResult<null>): void {
    this.setState({
      status: { key: 'signedOut', type: 'manual' },
    });
    if (result.success == true) {
      this.props.local.clear(); // Clear all account data from local storage on successful logout, probably/possibly no necessary
      this.props.local.logInfo('User successfully signed out');
    } else {
      this.props.local.logWarn(`Signout failed: ${result.debugMessage || result.message}`);
    }
  }

  /**
   * Toggle support modal.
   */
  private toggleSupport(): void {
    this.setState({
      supportOpen: !this.state.supportOpen,
    });
  }

  /**
   * Get array of nav options based on state.
   * @return Nav options for navigation.
   */
  private get navOptions(): NavMenuItemConfig[] {
    return [
      {
        name: this.props.t('nav.account.menu'),
        icon: <Icon name='user' />,
        parentPath: '/account',
        navOptions: [
          // {
          //   name: this.props.t('nav.account.settings'),
          //   path: '/account/settings',
          // },
          // {
          //   name: this.props.t('nav.account.email'),
          //   path: '/account/email',
          // },
          {
            name: this.props.t('nav.account.emailAlerts'),
            path: '/account/emailalerts',
          },
          {
            name: this.props.t('nav.account.password'),
            path: '/account/password',
          },
          {
            name: this.props.t('nav.account.disable'),
            path: '/account/disable',
          },
        ],
      },
      {
        name: this.props.t('nav.agencies.menu'),
        icon: <Icon name='building' />,
        parentPath: '/agencies',
        navOptions: [
          {
            name: this.props.t('nav.agencies.view'),
            path: '/agencies/view',
          },
          {
            name: this.props.t('nav.agencies.join'),
            path: '/agencies/join',
          },
        ],
      },
      {
        name: this.props.t('nav.contactSupport'),
        icon: <Icon name='question' />, // <SVG src={supportSvg} />,
        function: this.toggleSupport.bind(this),
      },
      {
        name: this.props.t('nav.signOut'),
        icon: <Icon name='log out' />, // <SVG src={signOutSvg} />,
        function: (e: any) => this.props.api.signOut(this.onSignout.bind(this)),
      },
    ];
  }

  /**
   * Get array of routes based on state.
   * @return Routes
   */
  private get routes(): React.ReactNode[] {
    return [
      <Route key='dash' path='/' exact component={Dashboard} />,
      <Route key='acct' path='/account' component={Account} />,
      <Route key='agcy' path='/agencies' component={Agencies} />,
      <Route key='404' component={NotFound} />,
    ];
  }

  /**
   * Get version display for development.
   * @return Version display div
   */
  private get versionDisplay(): React.ReactNode {
    const versionLabel = 'Version';
    const styleLabel = 'Style';
    const breakfastLabel = 'Breakfast';
    return (
      <div>
        <table>
          <tr>
            <th>{versionLabel}</th>
            <td>{VERSION}</td>
          </tr>
          <tr>
            <th>{styleLabel}</th>
            <td>{STYLE_VERSION}</td>
          </tr>
          <tr>
            <th>{breakfastLabel}</th>
            <td>{BREAKFAST_VERSION}</td>
          </tr>
        </table>
      </div>
    );
  }

  /**
   * Render function.
   */
  render(): React.ReactNode {
    if (this.state.isLoading || !this.props.tReady) {
      return <LoadingSpinner />;
    }
    if (this.state.status.key == 'signedOut') {
      if (this.state.status.type == 'newVistor') {
        return (
          <Redirect to={{
            pathname: '/welcome',
            state: {
              type: this.state.status.type,
              from: null,
            },
          }} />
        );
      }
      return (
        <Redirect to={{
          pathname: '/signin',
          state: {
            type: this.state.status.type,
            from: this.state.status.type != 'manual' ? this.props.location : null,
          },
        }} />
      );
    }

    const footer = (
      <footer id={style.Footer}>
        <span>
          {this.props.t('branding.privacyPolicy')}
        </span>
      </footer>
    );

    return (
      <div
        id={style.ContentWrapper}
        className={classnames({ [style.mobile]: isMobile })}
      >
        <Nav key='nav' navOptions={this.navOptions} />
        <div id={style.PageContent}>
          <Switch>
            {this.routes}
          </Switch>
        </div>
        {footer}
        <SupportModal
          open={this.state.supportOpen}
          onClose={this.toggleSupport.bind(this)}
        />
      </div>
    );
  }
}

export default withContext(Main, 'api', 'local', 'i18n');
