/** @module components.Agencies.Join */

import * as React from 'react';
import { SyntheticEvent } from 'react';
import { Grid, Button } from 'semantic-ui-react';

import * as style from '@bryxinc/style/main.module.css';
import * as color from '@bryxinc/style/color';

import PaneWrapper from './PaneWrapper';
import { withContext, WithTranslation } from '@bryxinc/lunch/context';

interface BeginProps extends WithTranslation {
  onSearch: () => void;
  onSignUp: () => void;
}

/**
 * Begin tab react component for Join Agency page.
 */
export class Begin extends React.Component<BeginProps> {

  /**
   * Render function.
   */
  render(): React.ReactNode {
    return (
      <PaneWrapper>
        <Grid>
          <Grid.Row columns={2} centered>
            <Grid.Column textAlign='center' width='14'>
              <h3>{this.props.t('agencies.join.isUsingBryx')}</h3>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered>
            <Grid.Column textAlign='center' width='6'>
              <Button
                style={{ letterSpacing: '1px' }}
                onClick={this.props.onSearch}>
                {this.props.t('general.yes').toUpperCase()}
              </Button>
            </Grid.Column>
            <Grid.Column textAlign='center' width='6'>
              <Button
                style={{ letterSpacing: '1px' }}
                onClick={this.props.onSignUp}>
                {this.props.t('general.no').toUpperCase()}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </PaneWrapper>
    );
  }
}

export default withContext(Begin, 'i18n');
