/** @module components.SignIn */

import * as React from 'react';
import { FormEvent, SyntheticEvent } from 'react';
import {
  Button,
  Divider,
  Form,
  Grid,
  Input,
  Message,
  Popup,
} from 'semantic-ui-react';
import { withContext, WithTranslation, WithApi } from '@bryxinc/lunch/context';
import LoadingSpinner from '../LoadingSpinner';
import Alert from '../Alert';
import { withErrorBoundary } from '../ErrorBoundary';
import Captcha from './Captcha';

// import PhoneInput, { NewDeviceFormValue, NewDeviceFormValid } from './Account/SMSAlerts/NewDeviceForm';
import PhoneInput, { PhoneInputValue, PhoneInputValid } from './PhoneInput';

import { AccountAuth, ApiResult } from '@bryxinc/lunch/models';

import * as signinStyle from '@bryxinc/style/signin.module.css';
import * as color from '@bryxinc/style/color';
import BryxApi from '@bryxinc/lunch/utils/AccountApi';

interface SignUpFormProps extends WithTranslation, WithApi<BryxApi> {
  status: any;
  onStatusChange: (nextStatus?: any) => void;
  signUpCallback: (r: ApiResult<AccountAuth>) => void;
}

interface SignUpFormState {
  givenName: string;
  surname: string;
  email: string;
  password: string;
  passwordConfirm: string;
  overlayContent: string;
  initializing: boolean;
  captchaResponse: string | null;
  phone: { areaCode: string, prefix: string, lineNumber: string };
  phoneValid: { areaCode: boolean | null, prefix: boolean | null, lineNumber: boolean | null };
}

/**
 * SignUpForm component.
 * @TODO Make custom css classes.
 */
export class SignUpForm extends React.Component<SignUpFormProps, SignUpFormState> {
  private captchaInstance: Captcha | null = null;

  readonly state: SignUpFormState = {
    givenName: '',
    surname: '',
    email: '',
    password: '',
    passwordConfirm: '',
    overlayContent: 'none',
    initializing: true,
    captchaResponse: null,
    phone: { areaCode: '', prefix: '', lineNumber: '' },
    phoneValid: { areaCode: null, prefix: null, lineNumber: null },
  };

  /**
   * Function for handling form submission event.
   * @param e Event
   */
  private onFormSubmit(e: FormEvent<HTMLFormElement>): void {
    e.preventDefault();
    this.submitCredentials();
  }

  /**
   * Sign up callback.
   * Resets captcha on failure and calls callback from props.
   * @param r Response
   */
  private signUpCB(r: ApiResult<AccountAuth>): void {
    const { signUpCallback } = this.props;
    if (!r.success && this.captchaInstance != null) {
      this.captchaInstance.reset();
    }
    signUpCallback(r);
  }

  /**
   * Submits credentials.
   */
  private submitCredentials(): void {
    const { api, t, onStatusChange } = this.props;
    const {
      givenName,
      surname,
      email,
      password,
      passwordConfirm,
      phone, phoneValid,
      captchaResponse,
    } = this.state;

    onStatusChange({ key: 'loading', type: 'manual' });

    if (!email || !password || !passwordConfirm) {
      onStatusChange({
        key: 'error',
        alertMessage: t('login.blankEmailOrPassword'),
      });
      return;
    }
    if (password != passwordConfirm) {
      onStatusChange({
        key: 'error',
        alertMessage: t('login.passwordsNotMatch'),
      });
      return;
    }
    if (( phone.areaCode || phone.prefix || phone.lineNumber ) &&
      !( phoneValid.areaCode || phoneValid.prefix || phoneValid.lineNumber )) {
        onStatusChange({
          key: 'error',
          alertMessage: t('login.phoneInvalid'),
        });
      return;
    }
    if (!captchaResponse) {
      onStatusChange({
        key: 'error',
        alertMessage: t('login.captchaNull'),
      });
      return;
    }

    api.signUp(
      email,
      password,
      givenName,
      surname,
      `${phone.areaCode}${phone.prefix}${phone.lineNumber}` || null,
      this.signUpCB.bind(this),
    );
  }

  /**
   * Function for handling change email event.
   * @param e Event
   */
  private onChangeGivenName(e: SyntheticEvent<HTMLInputElement>): void {
    this.setState({
      givenName: e.currentTarget.value,
    });
  }

  /**
   * Function for handling change email event.
   * @param e Event
   */
  private onChangeSurname(e: SyntheticEvent<HTMLInputElement>): void {
    this.setState({
      surname: e.currentTarget.value,
    });
  }

  /**
   * Function for handling change email event.
   * @param e Event
   */
  private onChangeEmail(e: SyntheticEvent<HTMLInputElement>): void {
    this.setState({
      email: e.currentTarget.value,
    });
  }

  /**
   * Function for handling change password event.
   * @param e Event
   */
  private onChangePassword(e: SyntheticEvent<HTMLInputElement>): void {
    this.setState({
      password: e.currentTarget.value,
    });
  }

  /**
   * Function for handling change confirm password event.
   * @param e Event
   */
  private onChangePasswordConfirm(e: SyntheticEvent<HTMLInputElement>): void {
    this.setState({
      passwordConfirm: e.currentTarget.value,
    });
  }

  /**
   * Handle new device change.
   */
  private onChangePhone(
    e: React.SyntheticEvent,
    { value, valid }: { value: Partial<PhoneInputValue>, valid: Partial<PhoneInputValid> },
  ): void {
    this.setState({
      phone: {
        ...this.state.phone,
        ...value,
      },
      phoneValid: {
        ...this.state.phoneValid,
        ...valid,
      },
    });
  }

  /**
   * Handle captcha change event.
   * @param d Captcha verification
   */
  private onCaptchaChange(d: string | null): void {
    this.setState({
      captchaResponse: d,
    });
  }

  /**
   * If form inputs are valid and ready to be submitted.
   */
  private get canSubmit(): boolean {
    const {
      givenName,
      surname,
      email,
      password,
      passwordConfirm,
      phone, phoneValid,
      captchaResponse,
    } = this.state;
    if (!givenName || !surname || !email || !password || !passwordConfirm) {
      return false;
    }
    // if (password != passwordConfirm) {
    //   return false;
    // }
    if (( phone.areaCode || phone.prefix || phone.lineNumber ) &&
      !( phoneValid.areaCode || phoneValid.prefix || phoneValid.lineNumber )) {
      return false;
    }
    if (!captchaResponse) {
      return false;
    }
    return true;
  }

  /**
   * Render component.
   */
  render(): React.ReactNode {
    if (!this.props.tReady) {
      return <LoadingSpinner />;
    }

    return (
      <Form onSubmit={this.onFormSubmit.bind(this)}>
        <Form.Group inline unstackable>
          <Form.Field style={{ marginLeft: 'auto', width: '50%', paddingRight: 0 }}>
            <Input required
              type='text'
              placeholder={this.props.t('login.givenName')}
              value={this.state.givenName}
              disabled={this.props.status.key == 'loading'}
              onChange={this.onChangeGivenName.bind(this)}
              style={{ width: '100%', paddingRight: '0.5em' }}
            />
          </Form.Field>
          <Form.Field style={{ paddingRight: '0', marginRight: 'auto', width: '50%' }}>
            <Input required
              type='text'
              placeholder={this.props.t('login.surname')}
              value={this.state.surname}
              disabled={this.props.status.key == 'loading'}
              onChange={this.onChangeSurname.bind(this)}
              style={{ width: '100%', paddingRight: 0, paddingLeft: '0.5em' }}
            />
          </Form.Field>
        </Form.Group>
        <Form.Field>
          <PhoneInput
            value={this.state.phone}
            valid={this.state.phoneValid}
            onChange={this.onChangePhone.bind(this)}
            disabled={this.props.status.key == 'loading'}
          />
        </Form.Field>
        <Form.Field>
          <Input required
            autoFocus
            autoComplete={false}
            autoCorrect={false}
            autoCapitalize={false}
            spellCheck={false}
            type='username'
            placeholder={this.props.t('login.email')}
            value={this.state.email || ''}
            disabled={this.props.status.key == 'loading'}
            onChange={this.onChangeEmail.bind(this)} />
        </Form.Field>
        <Form.Field>
          <Input required
            type='password'
            placeholder={this.props.t('login.password')}
            value={this.state.password || ''}
            disabled={this.props.status.key == 'loading'}
            onChange={this.onChangePassword.bind(this)} />
        </Form.Field>
        <Form.Field>
          <Input required
            type='password'
            placeholder={this.props.t('login.passwordConfirm')}
            value={this.state.passwordConfirm || ''}
            disabled={this.props.status.key == 'loading'}
            onChange={this.onChangePasswordConfirm.bind(this)} />
        </Form.Field>
        <Form.Field>
          <Captcha
            ref={e => this.captchaInstance = e}
            onChange={this.onCaptchaChange.bind(this)}
          />
        </Form.Field>
        <Form.Field>
          <Grid verticalAlign='middle' className={signinStyle.signinBtnGrid}>
            <Grid.Row centered className={signinStyle.signinBtnRow}>
              <Grid.Column className={signinStyle.signinBtnCol}>
                <Button id={signinStyle.SigninButton}
                  type='submit'
                  color='grey'
                  loading={this.props.status.key == 'loading'}
                >
                  {(this.props.t('login.signUp') as string || '').toUpperCase()}
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form.Field>
      </Form>
    );
  }
}

export default withErrorBoundary(withContext(SignUpForm, 'api', 'i18n'));
